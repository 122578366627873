var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("app-chart", {
        attrs: {
          type: "dough-chart",
          title: "Doughnut Chart",
          labels: _vm.labels,
          "data-sets": _vm.dataSets,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }