var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row",
    },
    [
      _c(
        "div",
        { staticClass: "navbar-menu-wrapper d-flex align-items-stretch" },
        [
          _c(
            "a",
            {
              staticClass: "align-self-center d-lg-none pl-0 navbar-brand",
              attrs: { href: "" },
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "" + (_vm.configData ? _vm.configData : "/images/core.png"),
                  alt: "logo",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "navbar-toggler align-self-center d-none d-lg-block pl-0",
              attrs: { type: "button" },
            },
            [
              _vm.leftMenuType === "normal"
                ? _c(
                    "span",
                    {
                      key: "align-left",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.togglingLeftMenu("active-icon-only-menu")
                        },
                      },
                    },
                    [_c("app-icon", { attrs: { name: "align-left" } })],
                    1
                  )
                : _vm.leftMenuType === "icon-only"
                ? _c(
                    "span",
                    {
                      key: "align-center",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.togglingLeftMenu("active-floating-menu")
                        },
                      },
                    },
                    [_c("app-icon", { attrs: { name: "align-center" } })],
                    1
                  )
                : _vm.leftMenuType === "floating"
                ? _c(
                    "span",
                    {
                      key: "align-justify",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.togglingLeftMenu("active-normal-menu")
                        },
                      },
                    },
                    [_c("app-icon", { attrs: { name: "align-justify" } })],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "navbar-toggler align-self-center d-lg-none pl-0",
              attrs: { type: "button", "data-toggle": "offcanvas" },
              on: { click: _vm.sidebarOffcanvas },
            },
            [_c("app-icon", { attrs: { name: "align-left" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "navbar-nav navbar-nav-right ml-auto" },
            [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "button",
                  {
                    staticClass: "navbar-toggler align-self-center pl-0",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleDarkMode($event)
                      },
                    },
                  },
                  [
                    _vm.darkMode
                      ? _c(
                          "span",
                          { key: "sun" },
                          [_c("app-icon", { attrs: { name: "sun" } })],
                          1
                        )
                      : _c(
                          "span",
                          { key: "moon" },
                          [_c("app-icon", { attrs: { name: "moon" } })],
                          1
                        ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item d-none d-lg-block" }, [
                _c(
                  "a",
                  {
                    staticClass: "d-flex align-items-center nav-link",
                    on: { click: _vm.fullscreen },
                  },
                  [
                    _c("app-icon", {
                      attrs: { id: "fullscreen-button", name: "maximize" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("app-navbar-language-dropdown", {
                attrs: {
                  "selected-language": "EN",
                  data: _vm.languageData,
                  "data-noti": "",
                },
              }),
              _vm._v(" "),
              _c("app-navbar-notification-dropdown", {
                attrs: {
                  allNotificationUrl: _vm.route("notifications.list"),
                  data: _vm.notifications,
                },
                on: { clicked: _vm.selectedNotification },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "topbar-divider d-none d-sm-block" }),
              _vm._v(" "),
              _c("app-navbar-profile-dropdown", {
                attrs: { user: _vm.user, data: _vm.profileData },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }