var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid p-0" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-8" }, [
        _c("div", {
          staticClass: "back-image",
          style:
            "background-image: url(" +
            _vm.urlGenerator(_vm.configData.company_banner) +
            ")",
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-4 pl-0" }, [
        _c("div", { staticClass: "login-form d-flex align-items-center" }, [
          _c(
            "form",
            {
              ref: "form",
              staticClass: "sign-in-sign-up-form w-100",
              attrs: { "data-url": "users/reset-password" },
            },
            [
              _c("div", { staticClass: "text-center mb-4" }, [
                _c("img", {
                  staticClass: "img-fluid logo",
                  attrs: {
                    src:
                      "" +
                      (_vm.configData.company_logo
                        ? _vm.urlGenerator(_vm.configData.company_logo)
                        : _vm.urlGenerator("/images/core.png")),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12" }, [
                  _c("h6", { staticClass: "text-center mb-0" }, [
                    _vm._v(
                      _vm._s(_vm.$t("hi", { object: _vm.$t("there") })) + "!"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "text-center d-block" }, [
                    _vm._v(_vm._s(_vm.$t("login_to_your_dashboard"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("email")))]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        type: "email",
                        placeholder: _vm.$t("your_email"),
                        required: true,
                      },
                      model: {
                        value: _vm.user.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "email", $$v)
                        },
                        expression: "user.email",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("password")))]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        type: "password",
                        placeholder: _vm.$t("your_password"),
                        required: true,
                      },
                      model: {
                        value: _vm.formData.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "password", $$v)
                        },
                        expression: "formData.password",
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.password
                      ? _c("small", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.password[0])),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("confirm_password")))]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        type: "password",
                        placeholder: _vm.$t("confirm_password"),
                        required: true,
                      },
                      model: {
                        value: _vm.formData.password_confirmation,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "password_confirmation", $$v)
                        },
                        expression: "formData.password_confirmation",
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.password_confirmation
                      ? _c("small", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.password_confirmation[0])),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block text-center",
                      attrs: { type: "button" },
                      on: { click: _vm.submitData },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "w-100" },
                        [
                          _vm.loading
                            ? _c("app-submit-button-loader")
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.loading
                        ? [_vm._v(_vm._s(_vm.$t("login")))]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "a",
                    {
                      staticClass: "bluish-text",
                      attrs: { href: _vm.urlGenerator("/") },
                    },
                    [
                      _c("i", {
                        staticClass: "pr-2",
                        attrs: { "data-feather": "home" },
                      }),
                      _vm._v(
                        _vm._s(
                          _vm.$t("back_to", { destination: _vm.$t("home") })
                        ) + "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 text-right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "bluish-text",
                      attrs: { href: "#" },
                      on: { click: _vm.forgetPassword },
                    },
                    [
                      _c("i", {
                        staticClass: "pr-2",
                        attrs: { "data-feather": "lock" },
                      }),
                      _vm._v(
                        _vm._s(_vm.$t("forgot_password")) + "?\n              "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("p", { staticClass: "text-center mt-5 footer-copy" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.copyRightText()) +
                        "\n              "
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }