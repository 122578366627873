var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.modalId,
        "aria-hidden": "true",
        role: "dialog",
        tabindex: "-1",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              {
                staticClass:
                  "modal-body d-flex flex-column justify-content-center modal-alert",
              },
              [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("app-icon", {
                      staticClass: "text-success",
                      attrs: { name: "check-circle" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h5", { staticClass: "text-center font-weight-bold mt-4" }, [
                  _vm._v(_vm._s(_vm.$t("congratulation"))),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "mb-primary text-center font-size-90 p-0" },
                  [_vm._v(_vm._s(_vm.$t("you_won_the_deal_successfully")))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-success",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.confirmDealWon($event)
                        },
                      },
                    },
                    [
                      _vm.loading
                        ? _c(
                            "span",
                            { staticClass: "w-100" },
                            [_c("app-submit-button-loader")],
                            1
                          )
                        : [_vm._v(_vm._s(_vm.$t("thank_you")))],
                    ],
                    2
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }