var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-1" },
    [
      _c("app-input", {
        attrs: {
          type: "single-checkbox",
          id: "checkbox-value-" + _vm.rowData.id,
          disabled: _vm.checkboxValue,
          "list-value-field": _vm.$t(" "),
        },
        on: { input: _vm.setActivityStatusDone },
        model: {
          value: _vm.checkboxValue,
          callback: function ($$v) {
            _vm.checkboxValue = $$v
          },
          expression: "checkboxValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }