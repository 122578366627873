var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-id": "send-proposal",
        "modal-size": "default",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("send_proposal"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              "aria-label": "Close",
              "data-dismiss": "modal",
              type: "button",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.dealData.contact_person.length > 0 &&
          _vm.dealData.contact_person[0].email[0]
            ? [
                _c("h5", { staticClass: "primary-text-color mb-2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("great_exclamation")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "text-muted mb-4" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("you_are_going_to_send_a_proposal")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("app-input", {
                      attrs: {
                        "custom-radio-type": "d-block mb-3",
                        list: _vm.proposalSendingTypeList,
                        type: "radio",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.proposalDealIdStore(_vm.dealData.id)
                        },
                      },
                      model: {
                        value: _vm.proposalSendingType,
                        callback: function ($$v) {
                          _vm.proposalSendingType = $$v
                        },
                        expression: "proposalSendingType",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.proposalSendingType === "send_quick_proposal"
                  ? [
                      _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c("app-input", {
                            attrs: {
                              list: _vm.templateList,
                              placeholder: _vm.$t("choose_one_and_confirm"),
                              required: true,
                              "list-value-field": "subject",
                              type: "select",
                            },
                            model: {
                              value: _vm.proposalChoseType,
                              callback: function ($$v) {
                                _vm.proposalChoseType = $$v
                              },
                              expression: "proposalChoseType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.emailList.length > 1
                        ? _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("choose_an_email"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "mb-3" },
                                  [
                                    _c("app-input", {
                                      attrs: {
                                        type: "select",
                                        "list-value-field": "value",
                                        placeholder: _vm.$t("choose_an_email"),
                                        list: _vm.emailList,
                                        required: true,
                                      },
                                      model: {
                                        value: _vm.proposalFormData.email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.proposalFormData,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression: "proposalFormData.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ]
            : [
                !_vm.dealData.contact_person.length > 0
                  ? [
                      _c(
                        "div",
                        { staticClass: "d-flex h-100 align-items-center" },
                        [
                          _c("app-note", {
                            staticClass: "w-100",
                            attrs: {
                              notes: [
                                _vm.$t(
                                  "the_deal_has_no_contact_person_with_email"
                                ),
                              ],
                              "show-title": false,
                              "padding-class": "p-2",
                              title: "'send'",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        { staticClass: "d-flex h-100 align-items-center" },
                        [
                          _c("app-note", {
                            staticClass: "w-100",
                            attrs: {
                              notes: _vm.noteMessage,
                              "show-title": false,
                              "content-type": "html",
                              "padding-class": "p-2",
                              title: "'send'",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.dealData.contact_person.length > 0 &&
          _vm.dealData.contact_person[0].email[0]
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-2",
                    attrs: { "data-dismiss": "modal", type: "button" },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.proposalSendMail()
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "w-100" },
                      [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.loading
                      ? [_vm._v(" " + _vm._s(_vm.$t("confirm")))]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-lg btn-primary",
                    attrs: { "data-dismiss": "modal", type: "button" },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.$t("ok")) + "\n      ")]
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }