var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper activities-calendar-view" },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            { staticClass: "col-sm-12 col-md-6" },
            [
              _c("app-breadcrumb", {
                attrs: {
                  "page-title": _vm.$t("calendar_view"),
                  directory: [_vm.$t("activities"), _vm.$t("calendar_view")],
                  icon: "activity",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$can("create_activities")
            ? _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "float-md-right mb-3 mb-sm-3 mb-md-0" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-with-shadow",
                        attrs: { type: "button", "data-toggle": "modal" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openModal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("add_activity")) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isActivityModalActive
            ? _c("app-activity-modal", {
                tag: "component",
                attrs: {
                  "table-id": _vm.activityModalId,
                  "selected-url": _vm.editedUrl,
                  "set-date": _vm.getSelectedDate,
                  "previous-data": _vm.isPreSetData,
                  "select-data": _vm.activityData,
                },
                on: { save: _vm.closeModal, "cancel-modal": _vm.cancelModal },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("activity-calendar", {
        attrs: { "data-loaded": _vm.dataLoaded, options: _vm.options },
        on: {
          select: _vm.handleClick,
          eventClick: _vm.selectedEventHandle,
          "trigger-filter": _vm.getActivityData,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeEventModal,
              expression: "closeEventModal",
            },
          ],
        },
        [
          _c(
            "div",
            {
              staticClass: "custom-modal shadow d-none",
              on: { "close-modal": _vm.closeEventModal },
            },
            [
              _vm.customModal
                ? [
                    !_vm.activityView
                      ? _c("div", [
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "radio-buttons",
                                    list: _vm.activityTypeOption,
                                  },
                                  model: {
                                    value: _vm.eventTitle,
                                    callback: function ($$v) {
                                      _vm.eventTitle = $$v
                                    },
                                    expression: "eventTitle",
                                  },
                                }),
                                _vm._v(" "),
                                _c("app-input", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("title"),
                                    required: true,
                                  },
                                  model: {
                                    value: _vm.formData.title,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "title", $$v)
                                    },
                                    expression: "formData.title",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.errors.title
                                  ? _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(_vm.errors.title[0]))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row mt-2" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "select",
                                    "list-value-field": "title",
                                    list: _vm.typeList,
                                    placeholder: _vm.$t(
                                      "choose_your_activity_type"
                                    ),
                                    required: true,
                                  },
                                  model: {
                                    value: _vm.formData.type_of_activity,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "type_of_activity",
                                        $$v
                                      )
                                    },
                                    expression: "formData.type_of_activity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.formData.type_of_activity == 1
                            ? _c("div", { staticClass: "form-row mt-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-sm-12" },
                                  [
                                    _c("app-input", {
                                      attrs: {
                                        type: "search-select",
                                        "list-value-field": "title",
                                        list: _vm.dealList,
                                        placeholder: _vm.$t("choose_a_deal"),
                                        required: true,
                                      },
                                      model: {
                                        value: _vm.formData.contextable_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "contextable_id",
                                            $$v
                                          )
                                        },
                                        expression: "formData.contextable_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm.formData.type_of_activity == 2
                            ? _c("div", { staticClass: "form-row mt-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-sm-12" },
                                  [
                                    _c("app-input", {
                                      attrs: {
                                        type: "search-select",
                                        list: _vm.personList,
                                        placeholder: _vm.$t("choose_a_person"),
                                        "list-value-field": "name",
                                        required: true,
                                      },
                                      model: {
                                        value: _vm.formData.contextable_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "contextable_id",
                                            $$v
                                          )
                                        },
                                        expression: "formData.contextable_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm.formData.type_of_activity == 3
                            ? _c("div", { staticClass: "form-row mt-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-sm-12" },
                                  [
                                    _c("app-input", {
                                      attrs: {
                                        type: "search-select",
                                        list: _vm.organizationList,
                                        placeholder: _vm.$t(
                                          "choose_an_organization"
                                        ),
                                        "list-value-field": "name",
                                        required: true,
                                      },
                                      model: {
                                        value: _vm.formData.contextable_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "contextable_id",
                                            $$v
                                          )
                                        },
                                        expression: "formData.contextable_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-1 form-group" }, [
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "mb-0 col-sm-3 d-flex align-items-center",
                                },
                                [_vm._v(_vm._s(_vm.$t("save_as_done")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mt-2 col-sm-2" },
                                [
                                  _c("app-input", {
                                    attrs: {
                                      type: "single-checkbox",
                                      "list-value-field": _vm.$t(" "),
                                    },
                                    model: {
                                      value: _vm.formData.is_done_activity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "is_done_activity",
                                          $$v
                                        )
                                      },
                                      expression: "formData.is_done_activity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row p-2 mt-2" }, [
                            _c("div", { staticClass: "col-6 px-0" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-light shadow",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openModal()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("add_details")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-6 px-0 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary mr-2",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                    },
                                    on: { click: _vm.closeEventModal },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("cancel")) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addEvent($event)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("save")) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _c("div", { staticClass: "form-row mt-2" }, [
                            _c("div", { staticClass: "col-sm-12 mb-2" }, [
                              _c("h4", [
                                _c("b", [
                                  _vm._v(
                                    "#" + _vm._s(_vm.currentActivity.databaseId)
                                  ),
                                ]),
                                _vm._v(
                                  " -\n                                " +
                                    _vm._s(_vm.currentActivity.title) +
                                    "\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.currentActivity.description
                                ? _c(
                                    "p",
                                    { staticStyle: { padding: "15px 0" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentActivity.description)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.currentActivity.created_by &&
                              _vm.currentActivity.created_by.full_name
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          padding: "5px 15px",
                                          background: "#f1f1f1",
                                          display: "inline-block",
                                          "border-radius": "4px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currentActivity.created_by
                                              .full_name
                                          )
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-12" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "single-checkbox",
                                    required: true,
                                    disabled:
                                      _vm.activity_done ||
                                      !_vm.$can("update_activities") ||
                                      !_vm.$can("done_activities"),
                                    "list-value-field": _vm.activity_done
                                      ? _vm.$t("done")
                                      : _vm.$t("mark_as_done"),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.activityUpdate(
                                        _vm.currentActivity.databaseId
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.activity_done,
                                    callback: function ($$v) {
                                      _vm.activity_done = $$v
                                    },
                                    expression: "activity_done",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "radio-buttons",
                                    list: _vm.activityTypeOption,
                                  },
                                  model: {
                                    value: _vm.eventId,
                                    callback: function ($$v) {
                                      _vm.eventId = $$v
                                    },
                                    expression: "eventId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row p-2" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("start_time"))),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c("label", [_vm._v(_vm._s(_vm.$t("end_time")))]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row p-2 mt-2" }, [
                            _c("div", { staticClass: "col-6 px-0" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-light text-left shadow",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openForEdit()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("edit_details")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-6 px-0 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary mr-2",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                    },
                                    on: { click: _vm.closeEventModal },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("cancel")) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.updateEvent($event)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("update")) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }