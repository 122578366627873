var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                button: { label: _vm.$t("back") },
                directory: [_vm.$t("proposals"), _vm.$t("send_proposal")],
                icon: "hexagon",
                "page-title": _vm.$t("send_proposal"),
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.dataLoaded
        ? _c("form", { ref: "form", attrs: { "data-url": _vm.submitUrl } }, [
            _c(
              "div",
              {
                staticClass: "card border-0 card-with-shadow",
                class: { "loading-opacity": !_vm.editorShow },
              },
              [
                !_vm.editorShow ? _c("app-overlay-loader") : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("div", { staticClass: "form-group mb-0" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-lg-9 col-xl-9" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-lg-2 col-xl-2 d-flex align-items-center",
                              },
                              [
                                _c("label", { staticClass: "mb-lg-0" }, [
                                  _vm._v(_vm._s(_vm.$t("proposal_title"))),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-lg-10 col-xl-10" }, [
                              _c(
                                "div",
                                [
                                  _c("app-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "type_your_proposal_title"
                                      ),
                                      required: true,
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.formData.subject,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "subject", $$v)
                                      },
                                      expression: "formData.subject",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-xl-3 d-flex h-100 align-items-center justify-content-lg-end",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-with-shadow",
                                attrs: {
                                  "data-toggle": "modal",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.isChooseProposalModal = true
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("choose_template")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group mb-0" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-lg-9 col-xl-9" }, [
                          _c("div", { staticClass: "form-group row mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-lg-2 col-xl-2 d-flex align-items-center",
                              },
                              [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("deal_title"))),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-lg-10 col-xl-10" }, [
                              _c(
                                "div",
                                { staticClass: "mb-2" },
                                [
                                  _c("app-input", {
                                    attrs: {
                                      list: _vm.dealList,
                                      "list-value-field": "title",
                                      type: "search-select",
                                    },
                                    model: {
                                      value: _vm.formData.deal_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "deal_id", $$v)
                                      },
                                      expression: "formData.deal_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group row" }, [
                            _c("div", {
                              staticClass:
                                "col-lg-2 col-xl-2 d-flex align-items-center",
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-lg-10 col-xl-10" },
                              [
                                this.deal
                                  ? [
                                      _vm.deal.contact_person.length > 0
                                        ? [
                                            !_vm.checkContactPersonEmail
                                              ? [
                                                  _c("app-note", {
                                                    attrs: {
                                                      notes: _vm.noteMessage,
                                                      "show-title": false,
                                                      "content-type": "html",
                                                      "padding-class": "p-2",
                                                      title: "'send'",
                                                    },
                                                  }),
                                                ]
                                              : _vm._e(),
                                          ]
                                        : [
                                            _c("app-note", {
                                              attrs: {
                                                notes: [
                                                  _vm.$t(
                                                    "the_deal_has_no_contact_person_with_email"
                                                  ),
                                                ],
                                                "show-title": false,
                                                "padding-class": "p-2",
                                                title: "'send'",
                                              },
                                            }),
                                          ],
                                    ]
                                  : [
                                      _c("app-note", {
                                        attrs: {
                                          notes: [
                                            _vm.$t(
                                              "no_deal",
                                              "There is no deal"
                                            ),
                                          ],
                                          "show-title": false,
                                          "content-type": "html",
                                          "padding-class": "p-2",
                                          title: "'send'",
                                        },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-lg-3 col-xl-3" }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.emailList.length > 1
                      ? _c("div", { staticClass: "form-group mb-0" }, [
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-lg-9 col-xl-9" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-lg-2 col-xl-2" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-10 col-xl-10 p-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-lg-12 col-xl-12" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("choose_an_email"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-lg-12 col-xl-12" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mb-3" },
                                          [
                                            _c("app-input", {
                                              attrs: {
                                                type: "select",
                                                "list-value-field": "value",
                                                placeholder:
                                                  _vm.$t("choose_an_email"),
                                                list: _vm.emailList,
                                                required: true,
                                              },
                                              model: {
                                                value: _vm.formData.email,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.email",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-lg-3 col-xl-3" }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12" },
                          [
                            _vm.editorShow
                              ? _c("app-input", {
                                  attrs: {
                                    id: "text-editor-id",
                                    required: true,
                                    type: "text-editor",
                                  },
                                  model: {
                                    value: _vm.formData.content,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "content", $$v)
                                    },
                                    expression: "formData.content",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group text-center" },
                      _vm._l(_vm.all_tags, function (tag) {
                        return _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-primary px-3 py-1 mr-2 mt-4",
                            attrs: {
                              title: tag.description,
                              "data-placement": "top",
                              "data-toggle": "tooltip",
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addTag(tag.tag)
                              },
                            },
                          },
                          [_vm._v(_vm._s(tag.tag) + "\n          ")]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.dataLoaded
                      ? [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                disabled:
                                  !_vm.deal ||
                                  !_vm.deal.contact_person.length ||
                                  !_vm.checkContactPersonEmail
                                    ? true
                                    : false,
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.sendProposal($event)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "w-100" },
                                [
                                  _vm.loading
                                    ? _c("app-submit-button-loader")
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.loading
                                ? [_vm._v(_vm._s(_vm.$t("send_proposal")))]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary mr-2",
                              attrs: {
                                "data-dismiss": "modal",
                                onclick: "window.history.back()",
                                type: "button",
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("cancel")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ])
        : _c("app-overlay-loader"),
      _vm._v(" "),
      _vm.isChooseProposalModal
        ? _c("app-choose-template-modal", {
            attrs: { modalId: _vm.tableId, "table-id": _vm.tableId },
            on: {
              close: function ($event) {
                _vm.isChooseProposalModal = false
              },
              selected: _vm.setContent,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }