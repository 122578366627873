var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-filter-wrapper" },
    [
      _vm._l(_vm.activitiesFilter, function (filter, index) {
        return [
          _c(
            "button",
            {
              staticClass:
                "btn btn-sm px-3 rounded-pill primary-card-color mr-2",
              class: [
                _vm.value === filter.key
                  ? "text-primary"
                  : index === 0 && _vm.value === ""
                  ? "text-primary"
                  : "text-muted",
              ],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.filterActivities(_vm.activityFilterUrl, filter.key)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(filter.name) + "\n        ")]
          ),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2 activity-filtered-result-wrapper" },
        [
          _vm.preLoader
            ? void 0
            : [
                _vm.activitiesList.length < 1
                  ? [
                      _c(
                        "div",
                        { staticClass: "card card-with-shadow border-0" },
                        [
                          _c(
                            "div",
                            { staticClass: "card-body" },
                            [
                              _vm._l(_vm.activitiesFilter, function (filter) {
                                return [
                                  _vm.value === filter.key
                                    ? _c("app-empty-data-block", {
                                        key: filter.key,
                                        attrs: {
                                          message:
                                            _vm.$t("please_add_something_in") +
                                            filter.name,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  : _vm._l(_vm.activitiesList, function (activity, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: { "mb-2": _vm.activitiesList.length > 0 },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card card-with-shadow border-0" },
                            [
                              _c("div", { staticClass: "card-body" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between mb-3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center primary-text-color mr-2 activity-icon",
                                          },
                                          [
                                            _c("app-icon", {
                                              attrs: {
                                                name: activity.icon,
                                                "stroke-width": "1",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          activity.title
                                            ? _c("h6", [
                                                _vm._v(_vm._s(activity.title)),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          activity.path
                                            ? _c("h6", [
                                                _vm._v(
                                                  _vm._s(
                                                    activity.path
                                                      .split("/")
                                                      .pop()
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          activity.note
                                            ? _c(
                                                "h6",
                                                {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.textTruncate(
                                                        activity.note,
                                                        15
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(_vm.$t("note")) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center font-size-70",
                                            },
                                            [
                                              activity.status_id ==
                                              _vm.todoStatusId.id
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "customized-radio mini radio-default mr-0",
                                                    },
                                                    [
                                                      _c("input", {
                                                        staticClass:
                                                          "radio-inline",
                                                        attrs: {
                                                          disabled:
                                                            _vm.$can(
                                                              "update_activities"
                                                            ) &&
                                                            _vm.$can(
                                                              "done_activities"
                                                            )
                                                              ? false
                                                              : true,
                                                          type: "radio",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.activityChangeStatus(
                                                              activity.id
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _vm._m(0, true),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(activity.started_at) +
                                                  "\n                                        "
                                              ),
                                              activity.started_at
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-success",
                                                    },
                                                    [
                                                      activity.started_at ==
                                                      _vm.today.dateOnly
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("today")
                                                              ) +
                                                                " |\n                                                " +
                                                                _vm._s(
                                                                  _vm.onlyTimeFromTime(
                                                                    activity.started_at,
                                                                    activity.start_time
                                                                  )
                                                                )
                                                            ),
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatDateToLocal(
                                                                  activity.started_at,
                                                                  true,
                                                                  activity.start_time
                                                                )
                                                              ) +
                                                                "\n                                                |\n                                                " +
                                                                _vm._s(
                                                                  _vm.formatDateToLocal(
                                                                    activity.ended_at,
                                                                    true,
                                                                    activity.end_time
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-success",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDateToLocal(
                                                              activity.created_at,
                                                              true
                                                            )
                                                          ) +
                                                            " |\n                                                " +
                                                            _vm._s(
                                                              _vm.onlyTime(
                                                                activity.created_at
                                                              )
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    activity.status_id == _vm.todoStatusId.id &&
                                    ((_vm.$can("update_activities") &&
                                      _vm.$can("done_activities")) ||
                                      _vm.$can("delete_activities"))
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown options-dropdown",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-option btn",
                                                attrs: {
                                                  "data-toggle": "dropdown",
                                                  type: "button",
                                                },
                                              },
                                              [
                                                _c("app-icon", {
                                                  attrs: {
                                                    name: "more-vertical",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-menu dropdown-menu-right py-2 mt-1 text-muted",
                                              },
                                              [
                                                _vm.$can("update_activities")
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "dropdown-item font-size-80 px-4 py-2",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.editActivity(
                                                              activity
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("edit"))
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.$can("update_activities") &&
                                                _vm.$can("done_activities")
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "dropdown-item font-size-80 px-4 py-2",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.activityChangeStatus(
                                                              activity.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "mark_as_done"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.$can("delete_activities")
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "dropdown-item font-size-80 px-4 py-2",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.activityDelete(
                                                              activity.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("delete")
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    activity.path
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown options-dropdown",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-option btn",
                                                attrs: {
                                                  "data-toggle": "dropdown",
                                                  type: "button",
                                                },
                                              },
                                              [
                                                _c("app-icon", {
                                                  attrs: {
                                                    name: "more-vertical",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-menu dropdown-menu-right py-2 mt-1 text-muted",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "dropdown-item font-size-80 px-4 py-2",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.fileDownload(
                                                          activity
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("download"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    activity.note
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown options-dropdown",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-option btn",
                                                attrs: {
                                                  "data-toggle": "dropdown",
                                                  type: "button",
                                                },
                                              },
                                              [
                                                _c("app-icon", {
                                                  attrs: {
                                                    name: "more-vertical",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-menu dropdown-menu-right py-2 mt-1 text-muted",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "dropdown-item font-size-80 px-4 py-2",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.editNote(
                                                          activity
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("edit"))
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "dropdown-item font-size-80 px-4 py-2",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.noteDelete(
                                                          activity.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("delete"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                activity.description
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "note p-2 mb-3 note-warning",
                                      },
                                      [
                                        _c("p", { staticClass: "m-1" }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(activity.description) +
                                              "\n                            "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                activity.note
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "note p-2 mb-3 note-warning",
                                      },
                                      [
                                        _c("p", {
                                          staticClass: "m-1",
                                          domProps: {
                                            innerHTML: _vm._s(activity.note),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-3 d-flex flex-wrap align-items-center",
                                  },
                                  [
                                    _vm.formData.owner
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center mr-3",
                                          },
                                          [
                                            _c("app-avatar", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "avatar-class": "avatars-w-30",
                                                img: _vm.formData.owner
                                                  .profile_picture
                                                  ? _vm.urlGenerator(
                                                      _vm.formData.owner
                                                        .profile_picture.path
                                                    )
                                                  : _vm.formData.owner
                                                      .profile_picture,
                                                title:
                                                  _vm.formData.owner.full_name,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formData.owner.full_name
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.componentType == "person"
                                      ? _vm._l(
                                          _vm.formData.organizations,
                                          function (organization) {
                                            return _vm.formData.organizations
                                              .length == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center mr-3",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-2 text-secondary org-icon",
                                                      },
                                                      [
                                                        _c("app-icon", {
                                                          attrs: {
                                                            name: "briefcase",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            organization.name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        )
                                      : _vm.componentType == "org"
                                      ? _vm._l(
                                          _vm.formData.persons,
                                          function (person) {
                                            return _vm.formData.persons
                                              .length == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center mr-3",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-2 text-secondary org-icon",
                                                      },
                                                      [
                                                        _c("app-icon", {
                                                          attrs: {
                                                            name: "user",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              person.name
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        )
                                      : [
                                          _vm.formData.person
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center mr-3",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "mr-2 text-secondary person-icon",
                                                    },
                                                    [
                                                      _c("app-icon", {
                                                        attrs: { name: "user" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-muted font-size-90",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formData.person
                                                            .name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.formData.organization
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center mr-3",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "mr-2 text-secondary org-icon",
                                                    },
                                                    [
                                                      _c("app-icon", {
                                                        attrs: {
                                                          name: "briefcase",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-muted font-size-90",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formData
                                                            .organization.name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _vm.componentType == "person"
                                      ? [
                                          _vm.formData.organizations.length > 1
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "d-flex flex-wrap align-items-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.$t("organizations:")
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-start mb-3",
                                            },
                                            _vm._l(
                                              _vm.formData.organizations,
                                              function (organization) {
                                                return _vm.formData
                                                  .organizations.length > 1
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "mr-3" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mr-2 text-secondary org-icon",
                                                          },
                                                          [
                                                            _c("app-icon", {
                                                              attrs: {
                                                                name: "briefcase",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-muted",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                organization.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      : _vm.componentType == "org"
                                      ? [
                                          _vm.formData.persons.length > 1
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "d-flex flex-wrap align-items-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.$t("persons:")
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-start mb-3",
                                            },
                                            _vm._l(
                                              _vm.formData.persons,
                                              function (person) {
                                                return _vm.formData.persons
                                                  .length > 1
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "mr-3" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mr-2 text-secondary org-icon",
                                                          },
                                                          [
                                                            _c("app-icon", {
                                                              attrs: {
                                                                name: "user",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-muted",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                person.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.isfileNoteFilter
                                  ? _c("div", [
                                      activity.participants.length
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap align-items-center",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$t("participants:")
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-wrap align-items-center",
                                          class: {
                                            "mb-3":
                                              activity.collaborators.length > 0,
                                          },
                                        },
                                        _vm._l(
                                          _vm.participants,
                                          function (participant) {
                                            return activity.id ==
                                              participant.pivot.activity_id
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mb-1 d-flex align-items-center mr-3",
                                                  },
                                                  [
                                                    _c("app-avatar", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        "avatar-class":
                                                          "avatars-w-30",
                                                        img: participant.profile_picture
                                                          ? _vm.urlGenerator(
                                                              participant
                                                                .profile_picture
                                                                .path
                                                            )
                                                          : participant.profile_picture,
                                                        title: participant.name,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            participant.name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      activity.collaborators.length
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "d-flex flex-wrap align-items-center",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$t("collaborators:")
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-wrap align-items-center",
                                        },
                                        _vm._l(
                                          _vm.collaborators,
                                          function (collaborator) {
                                            return activity.id ==
                                              collaborator.pivot.activity_id
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center mr-3",
                                                  },
                                                  [
                                                    _c("app-avatar", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        "avatar-class":
                                                          "avatars-w-30",
                                                        img: collaborator.profile_picture
                                                          ? _vm.urlGenerator(
                                                              collaborator
                                                                .profile_picture
                                                                .path
                                                            )
                                                          : collaborator.profile_picture,
                                                        title:
                                                          collaborator.full_name,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            collaborator.full_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "outside" }, [
      _c("span", { staticClass: "inside" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }