var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-success",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.showToast()
          },
        },
      },
      [_vm._v("\n        Show Toast\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }