var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow mb-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
        },
        [
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isPersonalInfoActive,
                  expression: "isPersonalInfoActive",
                },
              ],
              staticClass: "card-title text-muted mb-0",
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("lead_info")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isPersonalInfoActive,
                  expression: "!isPersonalInfoActive",
                },
              ],
              staticClass: "card-title text-muted mb-0",
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("change_lead_info")) + "\n    ")]
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isPersonalInfoActive,
                    expression: "!isPersonalInfoActive",
                  },
                ],
                staticClass: "text-muted",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.personalInfoClose($event)
                  },
                },
              },
              [
                _c("app-icon", {
                  attrs: { name: "x-square", "stroke-width": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isPersonalInfoActive,
                    expression: "!isPersonalInfoActive",
                  },
                ],
                staticClass: "text-muted",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.personalInfoUpdate($event)
                  },
                },
              },
              [
                _c("app-icon", {
                  attrs: { name: "check-square", "stroke-width": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.dealStatus
              ? _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPersonalInfoActive,
                        expression: "isPersonalInfoActive",
                      },
                    ],
                    staticClass: "text-muted",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.personalInfoEdit($event)
                      },
                    },
                  },
                  [
                    _c("app-icon", {
                      attrs: { name: "edit", "stroke-width": "1" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPersonalInfoActive,
                expression: "isPersonalInfoActive",
              },
            ],
          },
          [
            _vm.dealLeadInfo.contextable
              ? [
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "mb-0 text-muted col-4 d-flex align-items-center",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("lead_type")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.dealLeadInfo.lead_type == 1
                                ? _vm.$t("person")
                                : _vm.$t("organization")
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "mb-0 text-muted col-4 d-flex align-items-center",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("name")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center primary-text-color",
                          },
                          [
                            _c("app-avatar", {
                              staticClass: "mr-2",
                              attrs: {
                                title: _vm.dealLeadInfo.contextable.name,
                                "avatar-class": "avatars-w-30",
                                img: _vm.dealLeadInfo.contextable
                                  .profile_picture
                                  ? _vm.urlGenerator(
                                      _vm.dealLeadInfo.contextable
                                        .profile_picture.path
                                    )
                                  : _vm.dealLeadInfo.contextable
                                      .profile_picture,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: _vm.urlGenerator(
                                    "crm/" +
                                      _vm.componentType +
                                      "/" +
                                      _vm.dealLeadInfo.contextable.id +
                                      "/edit"
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.dealLeadInfo.contextable.name)
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.dealLeadInfo.lead_type == 2
                    ? _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "form-row" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "mb-0 text-muted col-4 d-flex align-items-center",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("contact_person")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.dealLeadInfo.contact_person,
                              function (contactPerson) {
                                return [
                                  _c("div", { staticClass: "col-8" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center primary-text-color",
                                      },
                                      [
                                        _c("app-avatar", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            title: contactPerson.name,
                                            "avatar-class": "avatars-w-30",
                                            img: contactPerson.profile_picture
                                              ? _vm.urlGenerator(
                                                  contactPerson.profile_picture
                                                    .path
                                                )
                                              : contactPerson.profile_picture,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              target: "_blank",
                                              href: _vm.urlGenerator(
                                                "crm/persons/" +
                                                  contactPerson.id +
                                                  "/edit"
                                              ),
                                            },
                                          },
                                          [_vm._v(_vm._s(contactPerson.name))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              }
                            ),
                            _vm._v(" "),
                            !_vm.dealLeadInfo.contact_person.length
                              ? [
                                  _c(
                                    "p",
                                    { staticClass: "col-8 text-muted mb-0" },
                                    [_vm._v(_vm._s(_vm.$t("not_added_yet")))]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-0" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "mb-0 text-muted col-4 d-flex align-items-center",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("owner")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("app-avatar", {
                              staticClass: "mr-2",
                              attrs: {
                                "avatar-class": "avatars-w-30",
                                img: _vm.dealLeadInfo.contextable.owner
                                  .profile_picture
                                  ? _vm.urlGenerator(
                                      _vm.dealLeadInfo.contextable.owner
                                        .profile_picture.path
                                    )
                                  : _vm.dealLeadInfo.contextable.owner
                                      .profile_picture,
                                title:
                                  _vm.dealLeadInfo.contextable.owner.full_name,
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.dealLeadInfo.contextable.owner.full_name
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              : [
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("no_lead_linked_yet"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "font-size-80",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.personalInfoEdit($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("link_lead_the_deal")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isPersonalInfoActive,
                expression: "!isPersonalInfoActive",
              },
            ],
          },
          [
            _c("form", { ref: "form" }, [
              _vm.dealLeadInfo.lead_type == 2
                ? _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "form-row align-items-center" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "mb-0 text-muted col-4 d-flex align-items-center",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("lead")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              list: _vm.organizationList,
                              placeholder: _vm.$t("choose_one"),
                              "list-value-field": "name",
                              type: "search-select",
                            },
                            on: { input: _vm.resetPerson },
                            model: {
                              value: _vm.formData.contextable_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "contextable_id", $$v)
                              },
                              expression: "formData.contextable_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "form-row align-items-center" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "mb-0 text-muted col-4 d-flex align-items-center",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("contact_person")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "col-8": !_vm.formData.person_id,
                        "col-7": _vm.formData.person_id,
                      },
                    },
                    [
                      _c("app-input", {
                        attrs: {
                          list: _vm.personAsOrg,
                          placeholder: _vm.$t("choose_a_contact_person"),
                          "list-value-field": "name",
                          type: "search-select",
                        },
                        model: {
                          value: _vm.formData.person_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "person_id", $$v)
                          },
                          expression: "formData.person_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dealLeadInfo.lead_type == 2 && _vm.formData.person_id
                    ? _c("div", { staticClass: "col-1" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-muted",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.detachContactPerson(
                                  _vm.dealLeadInfo.id
                                )
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              attrs: {
                                name: "trash",
                                "stroke-width": "1",
                                width: "20",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }