var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper", staticStyle: { "min-height": "100vh" } },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("app-input", {
            attrs: { type: "multi-select", list: _vm.multiSelectOptionList },
            model: {
              value: _vm.multiSelect,
              callback: function ($$v) {
                _vm.multiSelect = $$v
              },
              expression: "multiSelect",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }