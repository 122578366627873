var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-id": "contact-type-modal",
        "modal-size": "default",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(_vm.selectedUrl ? _vm.$t("edit") : _vm.$t("add")) +
              " " +
              _vm._s(_vm.$t("lead_group_lowercase"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              "aria-label": "Close",
              "data-dismiss": "modal",
              type: "button",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c(
          "form",
          {
            ref: "form",
            attrs: {
              "data-url": _vm.selectedUrl
                ? _vm.selectedUrl
                : _vm.route("types.store"),
            },
          },
          [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                { staticClass: "mb-0 col-sm-3 d-flex align-items-center" },
                [_c("label", [_vm._v(_vm._s(_vm.$t("name")))])]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("app-input", {
                    attrs: {
                      "error-message": _vm.$errorMessage(_vm.errors, "name"),
                      placeholder: _vm.$t("enter_name"),
                      required: true,
                      name: "lost_reason",
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                { staticClass: "mb-0 col-sm-3 d-flex align-items-center" },
                [_c("label", [_vm._v(_vm._s(_vm.$t("class")))])]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("app-input", {
                    attrs: { list: _vm.classList, type: "select" },
                    model: {
                      value: _vm.formData.class,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "class", $$v)
                      },
                      expression: "formData.class",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-0 col-sm-3 d-flex align-items-center" },
                [
                  _c(
                    "button",
                    {
                      class: "float-right mt-1 btn btn-" + _vm.formData.class,
                      attrs: { type: "button" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.formData.class) + "\n                    "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { "data-dismiss": "modal", type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }