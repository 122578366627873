var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: ["btn ", _vm.preloader ? "disabled" : "", _vm.className],
      attrs: { type: _vm.type },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
    },
    [
      _vm.preloader
        ? _c("span", { staticClass: "spinner-bounce" }, [
            _c("span", { staticClass: "bounce1 mr-1" }),
            _vm._v(" "),
            _c("span", { staticClass: "bounce2 mr-1" }),
            _vm._v(" "),
            _c("span", { staticClass: "bounce3 mr-1" }),
          ])
        : _c("span", [_vm._v("\n        " + _vm._s(_vm.btnLabel) + "\n    ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }