var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("all_notifications"),
          icon: "bell",
          directory: _vm.$t("notifications"),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown-items-wrapper custom-scrollbar" },
        _vm._l(_vm.notifications, function (item, index) {
          return _c(
            "a",
            {
              key: index,
              staticClass: "card border-0 mt-3 p-3",
              class: item.status == "new" ? "shadow" : "",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.readNotification(item.id)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-9" }, [
                  _c(
                    "div",
                    { staticClass: "media" },
                    [
                      _c("app-avatar", {
                        staticClass: "mr-3",
                        attrs: {
                          "avatar-class": "avatars-w-60",
                          img: item.img ? _vm.urlGenerator(item.img) : item.img,
                          title: item.name,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "media-body" }, [
                        _c("p", {
                          staticClass: "my-0 default-font-color",
                          domProps: { innerHTML: _vm._s(item.title) },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "primary-text-color link" }, [
                          _c("span", { staticClass: "mr-3" }, [
                            _vm._v(_vm._s(item.date)),
                          ]),
                          _vm._v(" "),
                          item.status == "new"
                            ? _c(
                                "span",
                                {
                                  staticClass: "badge badge-primary badge-pill",
                                },
                                [_vm._v(_vm._s(item.status))]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "span",
                    { staticClass: "primary-text-color time-text float-right" },
                    [_vm._v(_vm._s(item.time))]
                  ),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }