var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card border-0 card-with-shadow" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header d-flex align-items-center justify-content-between p-primary bg-transparent",
      },
      [
        _c("h5", { staticClass: "card-title text-muted m-0" }, [
          _vm._v(_vm._s(_vm.$t("tags"))),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c("app-tag-manager", {
          attrs: {
            tags: _vm.tags,
            list: _vm.allTags,
            "list-value-field": "name",
            "tag-preloader": _vm.tagPreloader,
          },
          on: {
            storeTag: _vm.storeAndAttachTag,
            attachTag: _vm.attachTag,
            detachTag: _vm.detachTag,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }