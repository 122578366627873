var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow mb-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
        },
        [
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCustomFieldEditable,
                  expression: "isCustomFieldEditable",
                },
              ],
              staticClass: "card-title text-muted mb-0",
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("custom_fields")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isCustomFieldEditable,
                  expression: "!isCustomFieldEditable",
                },
              ],
              staticClass: "card-title text-muted mb-0",
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("edit_custom_fields")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCustomFieldEditable,
                    expression: "!isCustomFieldEditable",
                  },
                ],
                staticClass: "text-muted",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.showCustomFieldHide($event)
                  },
                },
              },
              [
                _c("app-icon", {
                  attrs: { name: "x-square", "stroke-width": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCustomFieldEditable,
                    expression: "!isCustomFieldEditable",
                  },
                ],
                staticClass: "text-muted",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.showCustomFieldSave($event)
                  },
                },
              },
              [
                _c("app-icon", {
                  attrs: { name: "check-square", "stroke-width": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkIsEditable,
                    expression: "checkIsEditable",
                  },
                ],
                staticClass: "text-muted",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.showCustomFieldEditable($event)
                  },
                },
              },
              [
                _c("app-icon", {
                  attrs: { name: "edit", "stroke-width": "1" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCustomFieldEditable,
                expression: "isCustomFieldEditable",
              },
            ],
          },
          [
            _vm.customFields.length
              ? _vm._l(_vm.customFields, function (field, index) {
                  return _vm.loadData
                    ? _c("div", { key: index, staticClass: "form-group row" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "mb-0 text-muted col-4 d-flex align-items-center",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(field.name) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-8" },
                          [
                            field.custom_field_type.name === "number"
                              ? [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.numberFormatter(
                                          _vm.customFieldData[field.name]
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              : field.custom_field_type.name === "date"
                              ? [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.customFieldData[field.name] == "-"
                                          ? _vm.customFieldData[field.name]
                                          : _vm.formatDateToLocal(
                                              _vm.customFieldData[field.name],
                                              false,
                                              null
                                            )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.customFieldData[field.name]) +
                                      "\n                        "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ])
                    : _c("app-overlay-loader")
                })
              : [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("no_custom_field_added"))),
                  ]),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCustomFieldEditable,
                expression: "!isCustomFieldEditable",
              },
            ],
          },
          [
            _vm.customFields.length
              ? _vm._l(_vm.customFields, function (field, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "form-group row" },
                    [
                      _c(
                        "label",
                        { staticClass: "col-4 mb-0 d-flex align-items-center" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(field.name) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          field.custom_field_type.name === "text"
                            ? [
                                _c("app-input", {
                                  attrs: {
                                    id: field.name,
                                    list: _vm.generateInputList(field),
                                    type: "text",
                                  },
                                  model: {
                                    value: _vm.customFieldValue[field.name],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customFieldValue,
                                        field.name,
                                        $$v
                                      )
                                    },
                                    expression: "customFieldValue[field.name]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          field.custom_field_type.name === "textarea"
                            ? [
                                _c("app-input", {
                                  attrs: {
                                    id: field.name,
                                    list: _vm.generateInputList(field),
                                    type: "textarea",
                                  },
                                  model: {
                                    value: _vm.customFieldValue[field.name],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customFieldValue,
                                        field.name,
                                        $$v
                                      )
                                    },
                                    expression: "customFieldValue[field.name]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          field.custom_field_type.name === "radio"
                            ? [
                                _c("app-input", {
                                  attrs: {
                                    list: _vm.generateInputList(field),
                                    "radio-checkbox-name": field.name,
                                    type: "radio",
                                  },
                                  model: {
                                    value: _vm.customFieldValue[field.name],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customFieldValue,
                                        field.name,
                                        $$v
                                      )
                                    },
                                    expression: "customFieldValue[field.name]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          field.custom_field_type.name === "checkbox"
                            ? [
                                _c("app-input", {
                                  attrs: {
                                    list: _vm.generateInputList(field),
                                    "radio-checkbox-name": field.name,
                                    type: "checkbox",
                                  },
                                  model: {
                                    value: _vm.customFieldValue[field.name],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customFieldValue,
                                        field.name,
                                        $$v
                                      )
                                    },
                                    expression: "customFieldValue[field.name]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          field.custom_field_type.name === "select"
                            ? [
                                _c("app-input", {
                                  attrs: {
                                    list: _vm.generateInputList(field),
                                    type: "select",
                                  },
                                  model: {
                                    value: _vm.customFieldValue[field.name],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customFieldValue,
                                        field.name,
                                        $$v
                                      )
                                    },
                                    expression: "customFieldValue[field.name]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          field.custom_field_type.name === "number"
                            ? [
                                _c("app-input", {
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.customFieldValue[field.name],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customFieldValue,
                                        field.name,
                                        $$v
                                      )
                                    },
                                    expression: "customFieldValue[field.name]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          field.custom_field_type.name === "date"
                            ? [
                                _c("app-input", {
                                  attrs: { type: "date" },
                                  model: {
                                    value: _vm.customFieldValue[field.name],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customFieldValue,
                                        field.name,
                                        $$v
                                      )
                                    },
                                    expression: "customFieldValue[field.name]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                })
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }