var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center justify-content-between p-primary bg-transparent",
        },
        [
          _c("h5", { staticClass: "card-title text-muted m-0" }, [
            _vm._v(
              _vm._s(_vm.$t("deals")) + " (" + _vm._s(_vm.Deals.length) + ")"
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.commonDealModalOpen($event)
                },
              },
            },
            [
              _c("app-icon", {
                attrs: { "stroke-width": "1", name: "plus-square" },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body font-size-90" }, [
        _vm.Deals.length
          ? _c(
              "div",
              { staticClass: "default-base-color rounded p-2" },
              [
                _vm._l(_vm.Deals, function (deal, index) {
                  return index < 3
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "card border-0 card-with-shadow shadow person-details-deal",
                          class:
                            index === _vm.Deals.length - 1 || index === 2
                              ? ""
                              : "mb-2",
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.urlGenerator(
                                  _vm.route("deal_details.page", {
                                    id: deal.id,
                                  })
                                ),
                              },
                            },
                            [
                              _c("div", { staticClass: "card-body p-3" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "media d-flex align-items-center mb-3",
                                  },
                                  [
                                    _c("app-avatar", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        title: deal.title,
                                        "avatar-class": "avatars-w-30",
                                        img: deal.contextable
                                          ? deal.contextable.profile_picture
                                            ? _vm.urlGenerator(
                                                deal.contextable.profile_picture
                                                  .path
                                              )
                                            : _vm.urlGenerator(
                                                "/images/" +
                                                  _vm.componentType +
                                                  ".png"
                                              )
                                          : "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "media-body" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(deal.title) +
                                          "\n                "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-muted d-flex align-items-center justify-content-between",
                                  },
                                  [
                                    deal.owner
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "media d-flex align-items-center",
                                          },
                                          [
                                            _c("app-avatar", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                title: deal.owner.full_name,
                                                "avatar-class": "avatars-w-30",
                                                img: deal.owner.profile_picture
                                                  ? _vm.urlGenerator(
                                                      deal.owner.profile_picture
                                                        .path
                                                    )
                                                  : deal.owner.profile_picture,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "media-body" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      deal.owner.full_name
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "font-size-90" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.numberWithCurrencySymbol(
                                                deal.value
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.Deals.length > 3
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-center mt-2" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary btn-block",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.viewAll()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("view_all")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.isCommonDealModal
        ? _c("app-deal-modal", {
            attrs: {
              "pre-selected-option": _vm.preSelected,
              "component-type": _vm.componentType,
              selectedUrlId: _vm.Id,
            },
            on: { "close-modal": _vm.closeCommonDealModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.viewAllModal
        ? _c("app-common-all-deals", {
            attrs: {
              id: _vm.Id,
              "context-type": _vm.contextType,
              "table-id": "details-view-modal",
            },
            on: { "close-modal": _vm.closedViewModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }