var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper calendar-position-modified" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.$t("pipelines"),
                directory: [_vm.$t("deals"), _vm.$t("pipelines")],
                icon: "clipboard",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c("div", { staticClass: "float-md-right mb-3 mb-sm-3 mb-md-0" }, [
            _vm.$can("create_pipelines")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-with-shadow",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.addPipeline($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("add_pipeline")) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("app-table", {
        attrs: { id: "pipelines-table", options: _vm.options },
        on: { action: _vm.getAction },
      }),
      _vm._v(" "),
      _vm.isDeleteModal
        ? _c("pipeline-delete-modal", {
            attrs: {
              modalId: _vm.tableId,
              pipelineId: _vm.pipeline,
              deletePipelineUrl: _vm.deleteUrl,
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }