var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropzone",
      class: { disabled: _vm.data.disabled },
      attrs: { id: _vm.inputFieldId, name: _vm.name },
    },
    [
      _c(
        "div",
        { staticClass: "dz-default dz-message" },
        [
          _c("app-icon", {
            staticClass: "text-primary",
            attrs: { name: "upload-cloud" },
          }),
          _vm._v(" "),
          _c("h5", { staticClass: "title text-muted text-center" }, [
            _vm._v(_vm._s(_vm.$t("drag_and_drop"))),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "p-0 m-0 text-center" }, [
            _vm._v(_vm._s(_vm.$t("or"))),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-primary text-center" }, [
            _vm._v(_vm._s(_vm.$t("browse"))),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }