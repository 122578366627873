var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow card-with-search h-100" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center p-primary bg-transparent",
        },
        [
          _c("h5", { staticClass: "card-title d-inline-block mb-0" }, [
            _vm._v(_vm._s(_vm.$t("roles"))),
          ]),
          _vm._v(" "),
          _c("app-search", {
            on: {
              input: function ($event) {
                return _vm.getSearchValue($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body p-0" },
        [
          _c("app-table", {
            attrs: {
              id: _vm.roleTableId,
              options: _vm.roleOptions,
              search: _vm.search,
            },
            on: { action: _vm.triggerActions },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.modalOpen
        ? _c("app-roles-modal", {
            attrs: {
              "modal-title": _vm.modalTitle,
              tableId: _vm.roleTableId,
              "selected-url": _vm.computedSelectedUrl,
              data: { permissions: this.permissions },
            },
            on: { "close-modal": _vm.closeRoleModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmationRoleModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "role-delete-modal" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isManageUserModal
        ? _c("app-manage-users-modal", {
            attrs: {
              "modal-id": "manage-user",
              tableId: _vm.roleTableId,
              role: _vm.role,
              "modal-scroll": false,
            },
            on: { "close-modal": _vm.closeManageUserModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isViewModalActive
        ? _c("app-view-user-modal", {
            attrs: {
              "modal-id": "view-user-modal",
              tableId: _vm.roleTableId,
              role: _vm.role,
            },
            on: { "close-modal": _vm.closeViewUserModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }