var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "person-modal",
        "modal-size": "large",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.selectedUrl ? _vm.$t("edit_person") : _vm.$t("add_person")
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.dataLoaded
            ? _c(
                "form",
                {
                  ref: "form",
                  attrs: {
                    "data-url": _vm.selectedUrl
                      ? _vm.selectedUrl
                      : _vm.route("persons.store"),
                  },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-0 col-sm-3 d-flex align-items-center",
                        },
                        [_c("label", [_vm._v(_vm._s(_vm.$t("name")))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("enter_name"),
                              name: "name",
                              "error-message": _vm.$errorMessage(
                                _vm.errors,
                                "name"
                              ),
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-0 col-sm-3 d-flex align-items-center",
                        },
                        [_c("label", [_vm._v(_vm._s(_vm.$t("lead_group")))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "select",
                              "list-value-field": "name",
                              list: _vm.contactTypeList,
                              placeholder: _vm.$t("choose_an_lead_group"),
                              "error-message": _vm.$errorMessage(
                                _vm.errors,
                                "contact_type_id"
                              ),
                            },
                            model: {
                              value: _vm.formData.contact_type_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "contact_type_id", $$v)
                              },
                              expression: "formData.contact_type_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: { "mb-0": _vm.organizations.length == 1 },
                    },
                    _vm._l(_vm.organizations, function (input, idx) {
                      return _c("div", { key: idx, staticClass: "row mb-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-0 col-sm-3 d-flex align-items-center",
                          },
                          [
                            _vm.organizations.length == 1
                              ? _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: idx < 1,
                                        expression: "idx < 1",
                                      },
                                    ],
                                  },
                                  [_vm._v(_vm._s(_vm.$t("organization")))]
                                )
                              : _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: idx < 1,
                                        expression: "idx < 1",
                                      },
                                    ],
                                  },
                                  [_vm._v(_vm._s(_vm.$t("organization(s)")))]
                                ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-6 pr-2" },
                          [
                            _c("app-input", {
                              attrs: {
                                type: "search-select",
                                "list-value-field": "name",
                                list: _vm.organizationList,
                                placeholder: _vm.$t("choose_an_organization"),
                              },
                              model: {
                                value: input.pivot.organization_id,
                                callback: function ($$v) {
                                  _vm.$set(input.pivot, "organization_id", $$v)
                                },
                                expression: "input.pivot.organization_id",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: idx == _vm.organizations.length - 1,
                                    expression:
                                      "idx == organizations.length - 1",
                                  },
                                ],
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openModal()
                                  },
                                },
                              },
                              [_vm._v("+" + _vm._s(_vm.$t("create_new")))]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pl-0",
                            class: {
                              "col-sm-3": _vm.organizations.length == 1,
                              "col-sm-2 pr-0": _vm.organizations.length > 1,
                            },
                          },
                          [
                            _c("app-input", {
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("enter_job_title"),
                              },
                              model: {
                                value: input.pivot.job_title,
                                callback: function ($$v) {
                                  _vm.$set(input.pivot, "job_title", $$v)
                                },
                                expression: "input.pivot.job_title",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: idx == _vm.organizations.length - 1,
                                    expression:
                                      "idx == organizations.length - 1",
                                  },
                                ],
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.OrgAndJobAdd(idx)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("add_more")))]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-1 pt-2 pr-0" }, [
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    idx ||
                                    (!idx && _vm.organizations.length > 1),
                                  expression:
                                    "idx || (!idx && organizations.length > 1)",
                                },
                              ],
                              staticClass: "text-muted",
                              attrs: { href: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.OrgAndJobRemove(idx)
                                },
                              },
                            },
                            [
                              _c("app-icon", {
                                attrs: {
                                  width: "20",
                                  "stroke-width": "1",
                                  name: "trash",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("h6", { staticClass: "pb-3 pt-3" }, [
                    _vm._v(_vm._s(_vm.$t("contact_info"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "mb-0 col-sm-3" }, [
                      _vm._v(_vm._s(_vm.$t("phone"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [
                        _vm._l(_vm.phone, function (input, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "row no-gutters align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-sm-7" },
                                [
                                  _c("app-input", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      type: "tel-input",
                                      id: "phone",
                                      placeholder: _vm.$t("enter_number"),
                                    },
                                    model: {
                                      value: input.value,
                                      callback: function ($$v) {
                                        _vm.$set(input, "value", $$v)
                                      },
                                      expression: "input.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: {
                                    "col-sm-5": _vm.phone.length == 1,
                                    "col-sm-4": _vm.phone.length > 1,
                                  },
                                },
                                [
                                  _c("app-input", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      type: "select",
                                      "list-value-field": "name",
                                      placeholder: _vm.$t("type"),
                                      list: _vm.phoneEmailTypeList,
                                    },
                                    model: {
                                      value: input.type_id,
                                      callback: function ($$v) {
                                        _vm.$set(input, "type_id", $$v)
                                      },
                                      expression: "input.type_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: {
                                    "col-sm-1": _vm.phone.length == 1,
                                    "col-sm-1": _vm.phone.length > 1,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                index ||
                                                (!index &&
                                                  _vm.phone.length > 1),
                                              expression:
                                                "index || (!index && phone.length > 1)",
                                            },
                                          ],
                                          staticClass: "text-muted",
                                          attrs: { href: "" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.phoneRemove(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("app-icon", {
                                            attrs: {
                                              width: "20",
                                              "stroke-width": "1",
                                              name: "trash",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.phoneAdd()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("add_more")) +
                                "\n                "
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "mb-0 col-sm-3" }, [
                      _vm._v(_vm._s(_vm.$t("email"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [
                        _vm._l(_vm.emails, function (input, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "row no-gutters align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-sm-7" },
                                [
                                  _c("app-input", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      type: "email",
                                      id: "email",
                                      placeholder: _vm.$t("enter_email"),
                                    },
                                    model: {
                                      value: input.value,
                                      callback: function ($$v) {
                                        _vm.$set(input, "value", $$v)
                                      },
                                      expression: "input.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: {
                                    "col-sm-5": _vm.emails.length == 1,
                                    "col-sm-4": _vm.emails.length > 1,
                                  },
                                },
                                [
                                  _c("app-input", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      type: "select",
                                      "list-value-field": "name",
                                      placeholder: _vm.$t("type"),
                                      list: _vm.phoneEmailTypeList,
                                    },
                                    model: {
                                      value: input.type_id,
                                      callback: function ($$v) {
                                        _vm.$set(input, "type_id", $$v)
                                      },
                                      expression: "input.type_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: {
                                    "col-sm-1": _vm.emails.length == 1,
                                    "col-sm-1": _vm.emails.length > 1,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                index ||
                                                (!index &&
                                                  _vm.emails.length > 1),
                                              expression:
                                                "index || (!index && emails.length > 1)",
                                            },
                                          ],
                                          staticClass: "text-muted",
                                          attrs: { href: "" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.emailRemove(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("app-icon", {
                                            attrs: {
                                              width: "20",
                                              "stroke-width": "1",
                                              name: "trash",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.emailAdd()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("add_more")) +
                                "\n                "
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.$can("manage_public_access")
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mb-0 col-sm-3 d-flex align-items-center",
                            },
                            [_c("label", [_vm._v(_vm._s(_vm.$t("owner")))])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-9" },
                            [
                              _c("app-input", {
                                attrs: {
                                  type: "select",
                                  "list-value-field": "full_name",
                                  list: _vm.ownerList,
                                  required: true,
                                },
                                model: {
                                  value: _vm.formData.owner_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "owner_id", $$v)
                                  },
                                  expression: "formData.owner_id",
                                },
                              }),
                              _vm._v(" "),
                              _vm.errors.owner_id
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(_vm.errors.owner_id[0])),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.addAddressDetails
                    ? [
                        _c("h6", { staticClass: "pb-3 pt-3" }, [
                          _vm._v(_vm._s(_vm.$t("address_details"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-0 col-sm-3 d-flex align-items-center",
                              },
                              [_c("label", [_vm._v(_vm._s(_vm.$t("country")))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-9" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "search-select",
                                    "list-value-field": "name",
                                    list: _vm.countryList,
                                    placeholder: _vm.$t("choose_a_country"),
                                  },
                                  model: {
                                    value: _vm.formData.country_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "country_id", $$v)
                                    },
                                    expression: "formData.country_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-0 col-sm-3 d-flex align-items-center",
                              },
                              [_c("label", [_vm._v(_vm._s(_vm.$t("area")))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-9" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("enter_area"),
                                  },
                                  model: {
                                    value: _vm.formData.area,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "area", $$v)
                                    },
                                    expression: "formData.area",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-0 col-sm-3 d-flex align-items-center",
                              },
                              [_c("label", [_vm._v(_vm._s(_vm.$t("city")))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-9" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("enter_city"),
                                  },
                                  model: {
                                    value: _vm.formData.city,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "city", $$v)
                                    },
                                    expression: "formData.city",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-0 col-sm-3 d-flex align-items-center",
                              },
                              [_c("label", [_vm._v(_vm._s(_vm.$t("state")))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-9" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("enter_state"),
                                  },
                                  model: {
                                    value: _vm.formData.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "state", $$v)
                                    },
                                    expression: "formData.state",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-0 col-sm-3 d-flex align-items-center",
                              },
                              [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("zip_code"))),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-9" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("enter_zip_code"),
                                  },
                                  model: {
                                    value: _vm.formData.zip_code,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "zip_code", $$v)
                                    },
                                    expression: "formData.zip_code",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mb-0 col-sm-3 d-flex align-items-center",
                            },
                            [_c("label", [_vm._v(_vm._s(_vm.$t("address")))])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-9" },
                            [
                              _c("app-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: _vm.$t(
                                    "add_address_details_here"
                                  ),
                                },
                                model: {
                                  value: _vm.formData.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "address", $$v)
                                  },
                                  expression: "formData.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-3" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addAddress()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("add_address")))]
                            ),
                          ]),
                        ]),
                      ]),
                  _vm._v(" "),
                  _vm.customFields.length && _vm.customFieldDataLoaded
                    ? [
                        _c("h6", { staticClass: "pb-3 pt-3" }, [
                          _vm._v(_vm._s(_vm.$t("custom_fields"))),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.customFields, function (field) {
                          return _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mb-0 col-sm-3 d-flex align-items-center",
                                },
                                [_c("label", [_vm._v(_vm._s(field.name))])]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-9" },
                                [
                                  field.custom_field_type.name === "text"
                                    ? [
                                        _c("app-input", {
                                          attrs: {
                                            type: "text",
                                            id: field.name,
                                          },
                                          model: {
                                            value:
                                              _vm.customFieldValue[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customFieldValue,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "customFieldValue[field.name]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  field.custom_field_type.name === "textarea"
                                    ? [
                                        _c("app-input", {
                                          attrs: {
                                            type: "textarea",
                                            id: field.name,
                                          },
                                          model: {
                                            value:
                                              _vm.customFieldValue[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customFieldValue,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "customFieldValue[field.name]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  field.custom_field_type.name === "radio"
                                    ? [
                                        _c("app-input", {
                                          attrs: {
                                            type: "radio",
                                            "radio-checkbox-name": field.name,
                                            list: _vm.generateInputList(field),
                                          },
                                          model: {
                                            value:
                                              _vm.customFieldValue[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customFieldValue,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "customFieldValue[field.name]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  field.custom_field_type.name === "checkbox"
                                    ? [
                                        _c("app-input", {
                                          attrs: {
                                            type: "checkbox",
                                            "radio-checkbox-name": field.name,
                                            list: _vm.generateInputList(field),
                                          },
                                          model: {
                                            value:
                                              _vm.customFieldValue[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customFieldValue,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "customFieldValue[field.name]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  field.custom_field_type.name === "select"
                                    ? [
                                        _c("app-input", {
                                          attrs: {
                                            type: "select",
                                            list: _vm.generateInputList(field),
                                          },
                                          model: {
                                            value:
                                              _vm.customFieldValue[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customFieldValue,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "customFieldValue[field.name]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  field.custom_field_type.name === "number"
                                    ? [
                                        _c("app-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value:
                                              _vm.customFieldValue[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customFieldValue,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "customFieldValue[field.name]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  field.custom_field_type.name === "date"
                                    ? [
                                        _c("app-input", {
                                          attrs: { type: "date" },
                                          model: {
                                            value:
                                              _vm.customFieldValue[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customFieldValue,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "customFieldValue[field.name]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ])
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _c("app-overlay-loader"),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("cancel")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submit($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }