var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-6 col-sm-8 col-md-9 col-lg-10 col-xl-10" },
          [
            _c(
              "div",
              {
                staticClass:
                  "filters-wrapper d-flex justify-content-start flex-wrap",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn d-block d-sm-none btn-toggle-filters",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleFilters($event)
                      },
                    },
                  },
                  [
                    _c("app-icon", { attrs: { name: "filter" } }),
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("filters")) + "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFiltersOpen,
                        expression: "isFiltersOpen",
                      },
                    ],
                    staticClass: "mobile-filters-wrapper",
                  },
                  [
                    _c("app-filter", {
                      attrs: { filters: _vm.options.filters },
                      on: { "get-values": _vm.getFilterValues },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "\n              btn btn-primary btn-with-shadow\n              d-sm-none\n              btn-close-filter-wrapper\n              d-flex\n              justify-content-center\n              align-items-center\n            ",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleFilters },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("close")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2" },
          [
            _c(
              "div",
              { staticClass: "mr-0 single-filter single-search-wrapper" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group form-group-with-search d-flex align-items-center",
                  },
                  [_c("app-search", { on: { input: _vm.getSearchValue } })],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "proposal-kanban-view card card-with-shadow border-0" },
        [
          _c(
            "div",
            { staticClass: "card-body", staticStyle: { padding: "0.5rem" } },
            [
              _vm.dataLoaded
                ? _c(
                    "div",
                    {
                      staticClass:
                        "kanban-wrapper custom-scrollbar overflow-auto pl-0",
                    },
                    _vm._l(_vm.statusProperty, function (status, index) {
                      return !(
                        status.name == "Draft" &&
                        !_vm.$can("manage_public_access") &&
                        _vm.$can("client_access")
                      )
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "kanban-column position-relative",
                              class: {
                                "draft-bg-color": status.name == "Draft",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "py-3 stage-header rounded-top row",
                                },
                                [
                                  _c("div", { staticClass: "col-10" }, [
                                    _c(
                                      "h6",
                                      {
                                        staticClass: "stage-name text-truncate",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(status.name) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                  text-muted\n                  d-flex\n                  flex-wrap\n                  align-items-center\n                  stage-information\n                ",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.proposalData[status.id]
                                                ? _vm.proposalData[status.id]
                                                    .length
                                                : 0
                                            ) + "\n                  proposals"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.proposalData[status.id].length
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.numberWithCurrencySymbol(
                                                    _vm.totalDealsValues.length
                                                      ? _vm
                                                          .collect(
                                                            _vm.totalDealsValues
                                                          )
                                                          .find(status.id)
                                                        ? _vm
                                                            .collect(
                                                              _vm.totalDealsValues
                                                            )
                                                            .find(status.id)
                                                            .value
                                                        : ""
                                                      : ""
                                                  )
                                                )
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.numberWithCurrencySymbol(
                                                    0
                                                  )
                                                )
                                              ),
                                            ]),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  staticClass: "kanban-draggable-column",
                                  attrs: {
                                    options: {
                                      disabled:
                                        status.name == "Draft" ||
                                        !_vm.$can("update_proposals")
                                          ? true
                                          : false,
                                    },
                                    "data-stage": status.id,
                                    list: _vm.proposalData[status.id],
                                    move: _vm.dragMove,
                                    animation: "150",
                                    chosenClass: "pipex-sortable-chosen",
                                    dragClass: "pipex-sortable-drag",
                                    easing: "cubic-bezier(1, 0, 0, 1)",
                                    forceFallback: "true",
                                    ghostClass: "pipex-sortable-ghost",
                                    group: "deals",
                                  },
                                  on: {
                                    change: _vm.dragChanged,
                                    end: _vm.dragEnd,
                                  },
                                },
                                _vm._l(
                                  _vm.proposalData[status.id],
                                  function (element, ix) {
                                    return _c(
                                      "div",
                                      {
                                        key: ix,
                                        staticClass:
                                          "card card-with-shadow draggable-item mt-2 border-0",
                                        class: {
                                          "cursor-default":
                                            status.name === "Draft" ||
                                            !_vm.$can("update_proposals"),
                                        },
                                        attrs: { "data-id": element.id },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card-body font-size-90",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row py-2" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-10" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media d-flex align-items-start mb-3 p-1",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: { href: "" },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.openPreviewModal(
                                                                  element
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "media-body",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      element.subject
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-2" },
                                                  [
                                                    _vm.$can(
                                                      "create_proposals"
                                                    ) ||
                                                    _vm.$can(
                                                      "update_proposals"
                                                    ) ||
                                                    _vm.$can("delete_proposals")
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown options-dropdown d-inline-block",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-option btn",
                                                                attrs: {
                                                                  type: "button",
                                                                  "data-toggle":
                                                                    "dropdown",
                                                                  title:
                                                                    _vm.$t(
                                                                      "actions"
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _c("app-icon", {
                                                                  attrs: {
                                                                    name: "more-vertical",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown-menu dropdown-menu-right py-2 mt-1",
                                                              },
                                                              [
                                                                element.status
                                                                  .name ==
                                                                  "status_draft" &&
                                                                _vm.$can(
                                                                  "create_proposals"
                                                                )
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "dropdown-item px-4 py-2 font-size-90",
                                                                        attrs: {
                                                                          href: "#",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.sendProposal(
                                                                                element
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "send"
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.$can(
                                                                  "update_proposals"
                                                                )
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "dropdown-item px-4 py-2 font-size-90",
                                                                        attrs: {
                                                                          href: "#",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.editProposal(
                                                                                element.id
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "edit"
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.$can(
                                                                  "delete_proposals"
                                                                )
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "dropdown-item px-4 py-2 font-size-90",
                                                                        attrs: {
                                                                          href: "#",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.deleteProposal(
                                                                                element.id
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "delete"
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            element.deal
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media d-flex align-items-start mb-3 p-1",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              element.deal.title
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-size-90",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.numberWithCurrencySymbol(
                                                              element.deal.value
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            element.deal &&
                                            element.deal.contact_person.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media d-flex align-items-start mb-3 p-1",
                                                  },
                                                  [
                                                    _c("app-avatar", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        "alt-text":
                                                          _vm.$t("not_found"),
                                                        img: element.deal
                                                          .contact_person[0]
                                                          .profile_picture
                                                          ? _vm.urlGenerator(
                                                              element.deal
                                                                .contact_person[0]
                                                                .profile_picture
                                                                .path
                                                            )
                                                          : _vm.urlGenerator(
                                                              "/images/person.png"
                                                            ),
                                                        "avatar-class":
                                                          "avatars-w-20",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              element.deal
                                                                .contact_person[0]
                                                                .name
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !(
                                              !_vm.$can(
                                                "manage_public_access"
                                              ) && _vm.$can("client_access")
                                            )
                                              ? [
                                                  element.tags.length
                                                    ? _c("hr", {
                                                        staticClass: "mt-0",
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      element.tags.length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "\n                        col-12\n                        d-flex\n                        tags-background\n                        flex-wrap\n                        justify-content-start\n                      ",
                                                            },
                                                            _vm._l(
                                                              element.tags,
                                                              function (
                                                                tag,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "span",
                                                                  {
                                                                    key: index,
                                                                    staticClass:
                                                                      "\n                          badge badge-sm badge-pill\n                          text-capitalize\n                          mr-1\n                          mb-1\n                          badge-text-truncate\n                        ",
                                                                    style: {
                                                                      "background-color":
                                                                        tag.color_code,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        tag.name
                                                                      ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e()
                    }),
                    0
                  )
                : _c("app-overlay-loader"),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.confirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "proposal-delete-modal" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCheckMailModalActive
        ? _c("app-check-email-deliver", {
            attrs: { "header-title": _vm.$t("send_proposal") },
            on: { "close-modal": _vm.closeModalCheckMail },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSendProposalModalActive
        ? _c("app-send-proposal-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "selected-url": _vm.selectUrl,
              "selected-deal": _vm.proposalDeal,
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isPreviewModalActive
        ? _c("app-proposal-preview-modal", {
            attrs: {
              content: _vm.selectedProposalContent,
              title: _vm.selectedProposalTitle,
              modalId: "proposal-preview",
            },
            on: {
              close: function ($event) {
                _vm.isPreviewModalActive = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }