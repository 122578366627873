var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.showModal = true
            },
          },
        },
        [_vm._v("\n        Modal\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.deleteModal = true
            },
          },
        },
        [_vm._v("\n        Delete Modal\n    ")]
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "app-modal",
            {
              attrs: {
                "modal-id": "example",
                "modal-backdrop": true,
                "modal-size": "default",
              },
              on: { "close-modal": _vm.closeAddEditModal },
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "exampleModalLabel" },
                  },
                  [_vm._v("Modal title")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close outline-none",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAddEditModal($event)
                      },
                    },
                  },
                  [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
                ),
              ]),
              _vm._v(" "),
              _c("template", { slot: "body" }, [
                _c("div", { staticClass: "p-4" }, [
                  _vm._v("This is for testing modal component"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.openSecondModal },
                  },
                  [_vm._v("Second Modal")]
                ),
                _vm._v(" "),
                _c(
                  "form",
                  { attrs: { action: "" } },
                  [
                    _c("app-input", {
                      attrs: { type: "text-editor" },
                      model: {
                        value: _vm.textEditor,
                        callback: function ($$v) {
                          _vm.textEditor = $$v
                        },
                        expression: "textEditor",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-4",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.closeAddEditModal },
                  },
                  [_vm._v("\n                Close\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.save },
                  },
                  [_vm._v("Save changes")]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteModal
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "delete-example" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.secondModal
        ? _c(
            "app-modal",
            {
              attrs: { "modal-id": "second-modal", "modal-size": "default" },
              on: { "close-modal": _vm.closeSecondModal },
            },
            [
              _c("div", { staticClass: "p-primary" }, [
                _vm._v("\n            this is test second modal\n        "),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }