var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow mb-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center justify-content-between p-primary bg-transparent",
        },
        [
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isContactInfoActive,
                  expression: "isContactInfoActive",
                },
              ],
              staticClass: "card-title text-muted m-0",
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("contact_info")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isContactInfoActive,
                  expression: "!isContactInfoActive",
                },
              ],
              staticClass: "card-title text-muted m-0",
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("edit_contact_info")) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isContactInfoActive,
                      expression: "!isContactInfoActive",
                    },
                  ],
                  staticClass: "text-muted",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.contactInfoClose($event)
                    },
                  },
                },
                [
                  _c("app-icon", {
                    attrs: { name: "x-square", "stroke-width": "1" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.personId
                ? [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isContactInfoActive,
                            expression: "!isContactInfoActive",
                          },
                        ],
                        staticClass: "text-muted",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.contactInfoSync($event)
                          },
                        },
                      },
                      [
                        _c("app-icon", {
                          attrs: { name: "check-square", "stroke-width": "1" },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.statusCheck && _vm.personId
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isContactInfoActive,
                          expression: "isContactInfoActive",
                        },
                      ],
                      staticClass: "text-muted",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.contactInfoEdit($event)
                        },
                      },
                    },
                    [
                      _c("app-icon", {
                        attrs: { name: "edit", "stroke-width": "1" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _vm.personId
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isContactInfoActive,
                        expression: "isContactInfoActive",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "label",
                          { staticClass: "text-muted col-12" },
                          [
                            _vm.contactInfoData &&
                            _vm.contactInfoData.phone.length
                              ? [
                                  _vm.contactInfoData.phone.length > 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("phones"))),
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.$t("phone"))),
                                      ]),
                                ]
                              : [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("no_phone_number_added"))
                                    ),
                                  ]),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.contactInfoData && _vm.contactInfoData.phone
                          ? _c(
                              "div",
                              { staticClass: "col-12" },
                              _vm._l(
                                _vm.contactInfoData.phone,
                                function (phoneNumber, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(phoneNumber.value)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            "mb-2":
                                              _vm.contactInfoData &&
                                              _vm.contactInfoData.phone.length >
                                                1 &&
                                              index !==
                                                _vm.contactInfoData.phone
                                                  .length -
                                                  1,
                                          },
                                        },
                                        [
                                          phoneNumber.type
                                            ? _c(
                                                "span",
                                                [
                                                  phoneNumber.type.class
                                                    ? _c("app-badge", {
                                                        attrs: {
                                                          "class-name":
                                                            "badge-sm badge-pill badge-" +
                                                            phoneNumber.type
                                                              .class,
                                                          label:
                                                            phoneNumber.type
                                                              .name,
                                                        },
                                                      })
                                                    : _c("app-badge", {
                                                        attrs: {
                                                          "class-name":
                                                            "badge-sm badge-pill badge-secondary",
                                                          label:
                                                            phoneNumber.type
                                                              .name,
                                                        },
                                                      }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group mb-0" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "label",
                          { staticClass: "text-muted col-12" },
                          [
                            _vm.contactInfoData &&
                            _vm.contactInfoData.email.length
                              ? [
                                  _vm.contactInfoData.email.length > 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("emails"))),
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.$t("email"))),
                                      ]),
                                ]
                              : [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("no_email_added"))),
                                  ]),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.contactInfoData && _vm.contactInfoData.email
                          ? _c(
                              "div",
                              { staticClass: "col-12" },
                              _vm._l(
                                _vm.contactInfoData.email,
                                function (email, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "text-break" }, [
                                        _vm._v(_vm._s(email.value)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            "mb-2":
                                              _vm.contactInfoData.email.length >
                                                1 &&
                                              index !==
                                                _vm.contactInfoData.email
                                                  .length -
                                                  1,
                                          },
                                        },
                                        [
                                          email.type
                                            ? _c(
                                                "span",
                                                [
                                                  email.type.class
                                                    ? _c("app-badge", {
                                                        attrs: {
                                                          "class-name":
                                                            "badge-sm badge-pill badge-" +
                                                            email.type.class,
                                                          label:
                                                            email.type.name,
                                                        },
                                                      })
                                                    : _c("app-badge", {
                                                        attrs: {
                                                          "class-name":
                                                            "badge-sm badge-pill badge-secondary",
                                                          label:
                                                            email.type.name,
                                                        },
                                                      }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isContactInfoActive,
                        expression: "!isContactInfoActive",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _vm.contactInfoData &&
                        _vm.contactInfoData.phone.length > 1
                          ? _c("label", { staticClass: "col-12" }, [
                              _vm._v(
                                "\n\n              " + _vm._s(_vm.$t("phones"))
                              ),
                            ])
                          : _c("label", { staticClass: "col-12" }, [
                              _vm._v(_vm._s(_vm.$t("phone"))),
                            ]),
                        _vm._v(" "),
                        _vm.contactInfoData && _vm.contactInfoData.phone
                          ? _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _vm._l(
                                  _vm.contactInfoData.phone,
                                  function (phoneNumber, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        class: {
                                          "mb-3":
                                            _vm.contactInfoData.phone.length >
                                              1 &&
                                            index !==
                                              _vm.contactInfoData.phone.length -
                                                1,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-12 mb-1" },
                                            [
                                              _c("app-input", {
                                                attrs: {
                                                  placeholder:
                                                    _vm.$t("enter_phone"),
                                                  type: "tel-input",
                                                },
                                                model: {
                                                  value: phoneNumber.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      phoneNumber,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "phoneNumber.value",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.errors[
                                                "phone." + index + ".value"
                                              ]
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors[
                                                            "phone." +
                                                              index +
                                                              ".value"
                                                          ][0]
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-10" },
                                            [
                                              _c("app-input", {
                                                attrs: {
                                                  list: _vm.typeList,
                                                  placeholder: _vm.$t("type"),
                                                  "list-value-field": "name",
                                                  type: "select",
                                                },
                                                model: {
                                                  value: phoneNumber.type_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      phoneNumber,
                                                      "type_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "phoneNumber.type_id",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-2 d-flex justify-content-center align-items-center",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "text-muted",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.deleteContactInfo(
                                                        "phone",
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("app-icon", {
                                                    attrs: {
                                                      name: "trash",
                                                      "stroke-width": "1",
                                                      width: "20",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "font-size-90",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addMoreContactInfo("phone")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("add_more")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group mb-0" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _vm.contactInfoData &&
                        _vm.contactInfoData.email.length > 1
                          ? _c("label", { staticClass: "col-12" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("emails")) +
                                  "\n            "
                              ),
                            ])
                          : _c("label", { staticClass: "col-12" }, [
                              _vm._v(_vm._s(_vm.$t("email"))),
                            ]),
                        _vm._v(" "),
                        _vm.contactInfoData && _vm.contactInfoData.email
                          ? _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _vm._l(
                                  _vm.contactInfoData.email,
                                  function (email, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        class: {
                                          "mb-3":
                                            _vm.contactInfoData.email.length >
                                              1 &&
                                            index !==
                                              _vm.contactInfoData.email.length -
                                                1,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-12 mb-1" },
                                            [
                                              _c("app-input", {
                                                attrs: {
                                                  placeholder:
                                                    _vm.$t("enter_email"),
                                                  type: "text",
                                                },
                                                model: {
                                                  value: email.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      email,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "email.value",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.errors[
                                                "email." + index + ".value"
                                              ]
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.generateValidationMessage(
                                                              _vm.errors[
                                                                "email." +
                                                                  index +
                                                                  ".value"
                                                              ][0],
                                                              "email"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-10" },
                                            [
                                              _c("app-input", {
                                                attrs: {
                                                  list: _vm.typeList,
                                                  placeholder: _vm.$t("type"),
                                                  "list-value-field": "name",
                                                  type: "select",
                                                },
                                                model: {
                                                  value: email.type_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      email,
                                                      "type_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "email.type_id",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-2 d-flex justify-content-center align-items-center",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "text-muted",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.deleteContactInfo(
                                                        "email",
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("app-icon", {
                                                    attrs: {
                                                      name: "trash",
                                                      "stroke-width": "1",
                                                      width: "20",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "font-size-90",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addMoreContactInfo("email")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("add_more")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]
            : [
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("not_added_yet"))),
                ]),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }