var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-error", {
    attrs: {
      "error-type": "500",
      "error-title": "Bad request",
      "error-description":
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }