var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c(
      "form",
      { ref: "form", attrs: { "data-url": _vm.route("pipelines.store") } },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("app-breadcrumb", {
                attrs: {
                  "page-title": _vm.$t("add_pipeline"),
                  directory: [_vm.$t("deals"), _vm.$t("add_pipeline")],
                  icon: "clipboard",
                  button: { label: _vm.$t("back") },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12 col-md-6" }, [
            _c(
              "div",
              { staticClass: "text-sm-right mb-primary mb-sm-0 mb-md-0" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.saveData($event)
                      },
                    },
                  },
                  [
                    _vm.loading
                      ? _c(
                          "span",
                          { staticClass: "w-100" },
                          [_c("app-submit-button-loader")],
                          1
                        )
                      : [_vm._v(_vm._s(_vm.$t("save")))],
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-with-shadow",
                    attrs: { type: "button", onclick: "window.history.back()" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("cancel")) +
                        "\n          "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card card-with-shadow border-0" }, [
          _c(
            "div",
            {
              staticClass: "card-body",
              staticStyle: { padding: "2rem 1.5rem" },
            },
            [
              _c("div", { staticClass: "form-group mb-primary px-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "m-0 d-flex align-items-center col col-sm-2",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("name")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col col-sm-6" },
                    [
                      _c("app-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("type_pipeline_name"),
                          required: true,
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.name
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.errors.name[0])),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex custom-scrollbar overflow-auto" },
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "kanban-wrapper pr-0",
                      attrs: { list: _vm.defaultStageList, group: "deals" },
                    },
                    _vm._l(_vm.defaultStageList, function (stage, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kanban-column rounded",
                          staticStyle: { cursor: "move" },
                        },
                        [
                          _c("div", { staticClass: "p-3 mb-2 stage-header" }, [
                            stage.name !== ""
                              ? _c("h6", { staticClass: "text-truncate" }, [
                                  _vm._v(_vm._s(stage.name)),
                                ])
                              : _c("h6", { staticClass: "text-truncate" }, [
                                  _vm._v(_vm._s(_vm.$t("untitled_stage"))),
                                ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-muted d-flex flex-wrap align-items-center stage-information",
                              },
                              [
                                stage.probability !== ""
                                  ? _c("span", [
                                      _vm._v(_vm._s(stage.probability)),
                                    ])
                                  : _c("span", [_vm._v("0%")]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "px-2 mb-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card card-with-shadow default-box-shadow border-0",
                              },
                              [
                                _c("div", { staticClass: "card-body p-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.$t("name")) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("app-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            _vm.$t("type_pipeline_name"),
                                          id: "stage-name-" + stage.id,
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                          },
                                        },
                                        model: {
                                          value: stage.name,
                                          callback: function ($$v) {
                                            _vm.$set(stage, "name", $$v)
                                          },
                                          expression: "stage.name",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors["stage." + index + ".name"]
                                        ? _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.errors[
                                                        "stage." +
                                                          index +
                                                          ".name"
                                                      ][0]
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.$t("probability")) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("app-input", {
                                        attrs: {
                                          id: "probability-" + stage.id,
                                          type: "number",
                                          placeholder:
                                            _vm.$t("type_probability"),
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                          },
                                        },
                                        model: {
                                          value: stage.probability,
                                          callback: function ($$v) {
                                            _vm.$set(stage, "probability", $$v)
                                          },
                                          expression: "stage.probability",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-stage-action shadow mt-3",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.deleteStage(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("app-icon", {
                                        staticClass: "mr-1",
                                        attrs: { name: "trash" },
                                      }),
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.$t("delete_stage")) +
                                          "\n                    "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "add-new-stage" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center p-primary rounded h-100 d-flex align-items-center flex-column justify-content-center wrapper",
                      },
                      [
                        _c("h6", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("add_new_stage"))),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-muted font-size-90" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("add_new_stage_description")) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addNewStage($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("add_stage")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }