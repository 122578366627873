var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-table", {
        attrs: { id: _vm.tableId, options: _vm.options },
        on: { action: _vm.getAction },
      }),
      _vm._v(" "),
      _vm.showNotificationSettingsModal
        ? _c("app-notification-setting-modal", {
            attrs: {
              eventId: _vm.event_id,
              "table-id": _vm.tableId,
              "selected-url": _vm.notificationSettingSelectedUrl,
            },
            on: { "close-modal": _vm.closeSettingModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showTemplateModal
        ? _c("app-notification-template-setting", {
            attrs: {
              eventId: _vm.event_id,
              "selected-url": _vm.notificationSelectedUrl,
              "table-id": _vm.tableId,
              "modal-id": "notification-template",
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }