var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "mb-0" }, [
    _c("div", { staticClass: "mb-primary" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "form-row" }, [
          _c("label", { staticClass: "col-3 mb-0 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("email")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-9" },
            [
              _c("app-input", {
                attrs: { type: "text", placeholder: _vm.$t("enter_email") },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "form-row" }, [
          _c("label", { staticClass: "col-3 mb-0 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("subject")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-9" },
            [
              _c("app-input", {
                attrs: { type: "text", placeholder: _vm.$t("enter_subject") },
                model: {
                  value: _vm.subject,
                  callback: function ($$v) {
                    _vm.subject = $$v
                  },
                  expression: "subject",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0" }, [
        _c("div", { staticClass: "form-row" }, [
          _c("label", { staticClass: "col-3 mb-0 h-fit-content" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("body")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "textarea",
                  "text-area-rows": 6,
                  placeholder: _vm.$t("type_here"),
                },
                model: {
                  value: _vm.body,
                  callback: function ($$v) {
                    _vm.body = $$v
                  },
                  expression: "body",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-primary px-primary border-top mx-minus-primary" },
      [
        _c(
          "button",
          { staticClass: "btn btn-primary mr-1", attrs: { type: "button" } },
          [_vm._v(_vm._s(_vm.$t("save")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-secondary", attrs: { type: "button" } },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }