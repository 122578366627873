var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-id": "lost-deal-confirm-modal",
        "modal-size": "default",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.dealLostReasonData ? _vm.$t("deal_lost") : "")),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              "aria-label": "Close",
              "data-dismiss": "modal",
              type: "button",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c("form", { ref: "form" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("lost_reason")))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("app-input", {
                    attrs: {
                      list: _vm.reasonsList,
                      required: true,
                      "list-value-field": "lost_reason",
                      type: "select",
                    },
                    model: {
                      value: _vm.formData.lost_reason_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "lost_reason_id", $$v)
                      },
                      expression: "formData.lost_reason_id",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("comment")))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("app-input", {
                    attrs: { name: "comment", type: "textarea" },
                    model: {
                      value: _vm.formData.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "comment", $$v)
                      },
                      expression: "formData.comment",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { "data-dismiss": "modal", type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("cancel")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("confirm")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }