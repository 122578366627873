var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bulk-floating-action-wrapper" }, [
        _c("div", { staticClass: "actions" }, [
          _c(
            "div",
            {
              staticClass:
                "dropdown d-inline-block btn-dropdown keep-inside-clicks-open",
              attrs: { title: _vm.$t("move"), "data-toggle": "tooltip" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-light dropdown-toggle border-right",
                  attrs: {
                    "aria-expanded": "false",
                    "aria-haspopup": "true",
                    "data-toggle": "dropdown",
                    type: "button",
                    id: "MoveMultipleDeals",
                  },
                },
                [_c("app-icon", { attrs: { name: "arrow-right-circle" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu p-primary",
                  staticStyle: { width: "500px" },
                  attrs: { "aria-labelledby": "MoveMultipleDeals" },
                },
                [
                  _c(
                    "form",
                    {
                      ref: "form",
                      attrs: { "data-url": _vm.route("multiple_deals.move") },
                    },
                    [
                      _c("h5", { staticClass: "mb-3" }, [
                        _vm._v(_vm._s(_vm.$t("move_deal"))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mb-0 col-sm-3 d-flex align-items-center",
                            },
                            [_vm._v(_vm._s(_vm.$t("pipeline")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-9" },
                            [
                              _c("app-input", {
                                attrs: {
                                  list: _vm.pipelines,
                                  placeholder: _vm.$t("choose_a_pipeline"),
                                  "list-value-field": "name",
                                  type: "select",
                                },
                                model: {
                                  value: _vm.formData.pipeline_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "pipeline_id", $$v)
                                  },
                                  expression: "formData.pipeline_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mb-0 col-sm-3 d-flex align-items-center",
                            },
                            [_vm._v(_vm._s(_vm.$t("stage")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-9" },
                            [
                              _vm.pipelineChange
                                ? _c("app-step-input-selector", {
                                    tag: "component",
                                    attrs: {
                                      required: true,
                                      "step-complete": _vm.stageIndexAsId,
                                      "step-lists": _vm.stageListAsPipelineId,
                                    },
                                    on: { stepChanges: _vm.setStageId },
                                    model: {
                                      value: _vm.formData.stage_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "stage_id", $$v)
                                      },
                                      expression: "formData.stage_id",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "float-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { "data-dismiss": "modal", type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.move($event)
                              },
                            },
                          },
                          [
                            _vm.loading
                              ? _c(
                                  "span",
                                  { staticClass: "w-100" },
                                  [_c("app-submit-button-loader")],
                                  1
                                )
                              : [_vm._v(_vm._s(_vm.$t("move")))],
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown d-inline-block btn-dropdown",
              attrs: { title: _vm.$t("manage_tag"), "data-toggle": "tooltip" },
            },
            [
              _c("bulk-action-tag-manager", {
                attrs: {
                  tags: _vm.allSelectedTags,
                  list: _vm.allTags,
                  "list-value-field": "name",
                  "tag-preloader": _vm.tagPreloader,
                },
                on: {
                  storeTag: _vm.storeAndAttachTag,
                  attachTag: _vm.attachTag,
                  detachTag: _vm.detachTag,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown d-inline-block btn-dropdown",
              attrs: { title: _vm.$t("delete"), "data-toggle": "tooltip" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-light dropdown-toggle border-right",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.deleteDeal($event)
                    },
                  },
                },
                [_c("app-icon", { attrs: { name: "trash-2" } })],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.dealDeleteModal
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "deal-delete-modal" },
            on: { confirmed: _vm.deleteDeals, cancelled: _vm.cancelled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }