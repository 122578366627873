var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.preloader
        ? _c("app-overlay-loader")
        : _c(
            "form",
            {
              ref: "form",
              attrs: {
                "data-url": _vm.route("core.settings.index"),
                enctype: "multipart/form-data",
              },
            },
            [
              _c("fieldset", { staticClass: "form-group mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "legend",
                    {
                      staticClass:
                        "col-12 col-form-label text-primary pt-0 mb-3",
                    },
                    [_vm._v(_vm._s(_vm.$t("company_info")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(_vm._s(_vm.$t("company_name"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("type_your_company_name"),
                              required: true,
                            },
                            model: {
                              value: _vm.appSettings.company_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.appSettings, "company_name", $$v)
                              },
                              expression: "appSettings.company_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 col-form-label h-fit-content",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("company_logo"))
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "text-muted font-italic" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("recommended_company_logo_size"))
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "custom-file-upload",
                              "generate-file-url": false,
                              label: _vm.$t("change_logo"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.companyLogo = true
                              },
                            },
                            model: {
                              value: _vm.appSettings.company_logo,
                              callback: function ($$v) {
                                _vm.$set(_vm.appSettings, "company_logo", $$v)
                              },
                              expression: "appSettings.company_logo",
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.company_logo
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.errors.company_logo[0])),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 col-form-label h-fit-content",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("company_icon"))
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "text-muted font-italic" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("recommended_company_icon_size"))
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "custom-file-upload",
                              "generate-file-url": false,
                              label: _vm.$t("change_icon"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.companyIcon = true
                              },
                            },
                            model: {
                              value: _vm.appSettings.company_icon,
                              callback: function ($$v) {
                                _vm.$set(_vm.appSettings, "company_icon", $$v)
                              },
                              expression: "appSettings.company_icon",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 col-form-label h-fit-content",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("company_banner"))
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "text-muted font-italic" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("recommended_company_banner_size")
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "custom-file-upload",
                              "generate-file-url": false,
                              label: _vm.$t("change_banner"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.companyBanner = true
                              },
                            },
                            model: {
                              value: _vm.appSettings.company_banner,
                              callback: function ($$v) {
                                _vm.$set(_vm.appSettings, "company_banner", $$v)
                              },
                              expression: "appSettings.company_banner",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("language")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "select",
                              list: _vm.languageData,
                              required: true,
                            },
                            model: {
                              value: _vm.appSettings.language,
                              callback: function ($$v) {
                                _vm.$set(_vm.appSettings, "language", $$v)
                              },
                              expression: "appSettings.language",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "form-group mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "legend",
                    {
                      staticClass:
                        "col-12 col-form-label text-primary pt-0 mb-3",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("date_and_time_setting")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("date_format")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: { type: "select", list: _vm.dateFormats },
                            model: {
                              value: _vm.appSettings.date_format,
                              callback: function ($$v) {
                                _vm.$set(_vm.appSettings, "date_format", $$v)
                              },
                              expression: "appSettings.date_format",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("time_format")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "radio-buttons",
                              list: _vm.timeFormats,
                            },
                            model: {
                              value: _vm.appSettings.time_format,
                              callback: function ($$v) {
                                _vm.$set(_vm.appSettings, "time_format", $$v)
                              },
                              expression: "appSettings.time_format",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("time_zone")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: { type: "select", list: _vm.timeZones },
                            model: {
                              value: _vm.appSettings.time_zone,
                              callback: function ($$v) {
                                _vm.$set(_vm.appSettings, "time_zone", $$v)
                              },
                              expression: "appSettings.time_zone",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "form-group mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "legend",
                    {
                      staticClass:
                        "col-12 col-form-label text-primary pt-0 mb-3",
                    },
                    [_vm._v(_vm._s(_vm.$t("currency_setting")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("currency_symbol")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: { type: "text", required: true },
                            model: {
                              value: _vm.appSettings.currency_symbol,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "currency_symbol",
                                  $$v
                                )
                              },
                              expression: "appSettings.currency_symbol",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("decimal_separator")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "radio-buttons",
                              list: _vm.decimalSeparators,
                              required: true,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.changeValue("decimal_separator")
                              },
                            },
                            model: {
                              value: _vm.appSettings.decimal_separator,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "decimal_separator",
                                  $$v
                                )
                              },
                              expression: "appSettings.decimal_separator",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("thousand_separator")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "radio-buttons",
                              list: _vm.thousandSeparators,
                              required: true,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.changeValue("thousand_separator")
                              },
                            },
                            model: {
                              value: _vm.appSettings.thousand_separator,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "thousand_separator",
                                  $$v
                                )
                              },
                              expression: "appSettings.thousand_separator",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("number_of_decimal")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "radio-buttons",
                              list: _vm.numberOfDecimals,
                              required: true,
                            },
                            model: {
                              value: _vm.appSettings.number_of_decimal,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "number_of_decimal",
                                  $$v
                                )
                              },
                              expression: "appSettings.number_of_decimal",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("currency_position")) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("app-input", {
                            attrs: {
                              type: "radio-buttons",
                              list: _vm.currencyPositions,
                              required: true,
                            },
                            model: {
                              value: _vm.appSettings.currency_position,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "currency_position",
                                  $$v
                                )
                              },
                              expression: "appSettings.currency_position",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit($event)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "w-100" },
                      [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }