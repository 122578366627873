var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "notification-template",
        "modal-body-class": "p-0",
        "modal-size": "large",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(_vm.$t("template")) +
              " : " +
              _vm._s(_vm.eventName.translated_name)
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      !_vm.preLoader
        ? _c("app-overlay-loader")
        : _c(
            "template",
            { slot: "body" },
            [_c("app-tab", { attrs: { type: "horizontal", tabs: _vm.tabs } })],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }