var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow card-with-search" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center p-primary primary-card-color",
        },
        [
          _c("h5", { staticClass: "card-title d-inline-block mb-0" }, [
            _vm._v(_vm._s(_vm.$t("users"))),
          ]),
          _vm._v(" "),
          _c("app-search", { on: { input: _vm.getSearchValue } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "p-primary d-flex align-items-center primary-card-color",
        },
        [
          _c(
            "ul",
            { staticClass: "nav tab-filter-menu justify-content-flex-end" },
            _vm._l(_vm.userStatuses, function (item, index) {
              return _c("li", { key: index, staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link py-0 font-size-default",
                    class: [
                      _vm.value === item.id
                        ? "active"
                        : index === 0 && _vm.value === ""
                        ? "active"
                        : "",
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.filterUser(item.id)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.translated_name))]
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body p-0" },
        [
          _c("app-table", {
            attrs: {
              id: _vm.userTable,
              options: _vm.userTableOptions,
              "filtered-data": _vm.filteredData,
              search: _vm.search,
            },
            on: { action: _vm.triggerActions },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isUserModalActive
        ? _c("app-user-modal", {
            attrs: {
              tableId: _vm.userTable,
              "selected-url": _vm.userSelectedUrl,
            },
            on: { "close-modal": _vm.closeUserModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.userConfirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "app-confirmation-modal" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isManageRoleModal
        ? _c("app-user-manage-role-modal", {
            attrs: {
              tableId: _vm.userTable,
              "modal-scroll": false,
              "selected-url": _vm.userSelectedUrl,
              "user-id": _vm.userId,
            },
            on: { "close-modal": _vm.closeRoleModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isTransferModal
        ? _c("app-transfer-deal-modal", {
            attrs: { "user-id": _vm.userId, tableId: _vm.userTable },
            on: { "close-modal": _vm.closeTransferDealModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }