var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "form-group form-group-with-search d-flex align-items-center",
    },
    [
      _c(
        "span",
        { key: "search", staticClass: "form-control-feedback" },
        [_c("app-icon", { attrs: { name: "search" } })],
        1
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchValue,
            expression: "searchValue",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "text", placeholder: _vm.$t("search") },
        domProps: { value: _vm.searchValue },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.getSearchValue($event)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchValue = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }