var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: { "modal-id": "exampleModal", "modal-alignment": "top" },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "h5",
          { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
          [_vm._v("Modal title")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c(
          "form",
          {
            ref: "form",
            attrs: { "data-url": "store", enctype: "multipart/form-data" },
          },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("app-input", {
                  attrs: { type: "text", required: true },
                  model: {
                    value: _vm.testFields.textValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.testFields, "textValue", $$v)
                    },
                    expression: "testFields.textValue",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("app-input", {
                  attrs: { type: "email" },
                  model: {
                    value: _vm.testFields.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.testFields, "email", $$v)
                    },
                    expression: "testFields.email",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-4",
            attrs: { type: "button", "data-dismiss": "modal" },
          },
          [_vm._v("Close")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.submitData },
          },
          [_vm._v("Save changes")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }