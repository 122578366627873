var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loaded
        ? _c("app-overlay-loader")
        : _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11" },
              [
                _c(
                  "draggable",
                  {
                    attrs: { tag: "div" },
                    on: {
                      start: function ($event) {
                        _vm.isDragging = true
                      },
                      end: function ($event) {
                        _vm.isDragging = false
                      },
                    },
                    model: {
                      value: _vm.socialLinks,
                      callback: function ($$v) {
                        _vm.socialLinks = $$v
                      },
                      expression: "socialLinks",
                    },
                  },
                  [
                    _c(
                      "transition-group",
                      { attrs: { type: "transition", name: "flip-list" } },
                      _vm._l(_vm.socialLinks, function (socialLink, index) {
                        return _c(
                          "div",
                          {
                            key: "social-link" + index,
                            staticClass:
                              "d-flex flex-wrap flex-column flex-lg-row align-items-lg-center justify-content-between py-half-primary",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-3 cursor-grab",
                                  attrs: { name: "menu" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "avatars-w-40 mr-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "no-img rounded-circle shadow",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { key: socialLink.icon },
                                          [
                                            _c("app-icon", {
                                              attrs: {
                                                name: socialLink.icon,
                                                width: "19px",
                                                height: "19",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    _vm._s(socialLink.name.replace("_", " "))
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 mt-lg-0" }, [
                              socialLink.link && !socialLink.edit
                                ? _c(
                                    "div",
                                    {
                                      key: "with-link-" + index,
                                      staticClass:
                                        "d-flex align-items-center text-muted",
                                      staticStyle: { "max-width": "310px" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-0 mr-2 text-truncate",
                                        },
                                        [_vm._v(_vm._s(socialLink.link))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-muted",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeSocialLink(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("app-icon", {
                                            attrs: { name: "trash" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _c("div", { key: "without-link-" + index }, [
                                    socialLink.edit
                                      ? _c(
                                          "div",
                                          {
                                            key: "without-link-edit-" + index,
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c("app-input", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                id: "social-link-" + index,
                                                type: "text",
                                              },
                                              model: {
                                                value: socialLink.link,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    socialLink,
                                                    "link",
                                                    $$v
                                                  )
                                                },
                                                expression: "socialLink.link",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-primary mr-2",
                                                attrs: { type: "submit" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.addSocialLink(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("add")) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-muted",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.editToggle(index)
                                                  },
                                                },
                                              },
                                              [
                                                _c("app-icon", {
                                                  attrs: { name: "x" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            key: "without-link-show-" + index,
                                            staticClass: "text-lg-right",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-primary mr-2",
                                                attrs: { type: "submit" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.editToggle(index)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(_vm.$t("link")) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }