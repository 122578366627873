var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-widget", {
    attrs: {
      type: "app-widget-with-icon",
      label: _vm.label,
      number: _vm.number,
      icon: _vm.icon,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }