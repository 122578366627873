var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "transfer-deal-modal",
        "modal-size": "default",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("transfer_deals"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "mb-2 col-sm-12" }, [
              _c("h5", { staticStyle: { color: "red" } }, [
                _vm._v(_vm._s(_vm.$t("opps!"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12" }, [
              _c("h5", [
                _vm._v(_vm._s(_vm.$t("what_about_the_deals_of_user"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("app-input", {
                  attrs: {
                    type: "radio",
                    list: [
                      {
                        id: 1,
                        value: _vm.$t(
                          "want_to_move_these_deals_to_another_user"
                        ),
                      },
                    ],
                  },
                  model: {
                    value: _vm.formData.delete_option,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "delete_option", $$v)
                    },
                    expression: "formData.delete_option",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.formData.delete_option == 1
          ? _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      _c("app-input", {
                        attrs: {
                          type: "select",
                          "list-value-field": "full_name",
                          placeholder: _vm.$t("choose_a_user"),
                          list: _vm.systemRoleList,
                        },
                        model: {
                          value: _vm.formData.new_user_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "new_user_id", $$v)
                          },
                          expression: "formData.new_user_id",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("app-input", {
                  attrs: {
                    type: "radio",
                    list: [{ id: 0, value: _vm.$t("or_delete_anyway") }],
                  },
                  model: {
                    value: _vm.formData.delete_option,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "delete_option", $$v)
                    },
                    expression: "formData.delete_option",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _vm.formData.delete_option == 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deletePipeline($event)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "w-100" },
                  [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                  1
                ),
                _vm._v(" "),
                !_vm.loading ? [_vm._v(_vm._s(_vm.$t("move")))] : _vm._e(),
              ],
              2
            )
          : _c(
              "button",
              {
                staticClass: "btn btn-danger",
                attrs: {
                  disabled: _vm.formData.delete_option ? false : true,
                  type: "button",
                  "data-dismiss": "modal",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deletePipeline($event)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "w-100" },
                  [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                  1
                ),
                _vm._v(" "),
                !_vm.loading ? [_vm._v(_vm._s(_vm.$t("delete")))] : _vm._e(),
              ],
              2
            ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }