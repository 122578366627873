var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "common-activity-modal",
        "modal-size": "large",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(this.activity.id ? _vm.$t("edit") : _vm.$t("add")) +
              " " +
              _vm._s(_vm.$t("activity_lowercase"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.dataLoaded
            ? _c("form", { attrs: { action: "" } }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "label",
                      { staticClass: "col-2 mb-0 d-flex align-items-center" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("activity")) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-10" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "radio-buttons",
                            "list-value-field": "name",
                            list: _vm.activityTypeList,
                          },
                          model: {
                            value: _vm.activity.activity_type_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.activity, "activity_type_id", $$v)
                            },
                            expression: "activity.activity_type_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "label",
                      { staticClass: "col-2 mb-0 d-flex align-items-center" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("title")) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-10" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("enter_title"),
                          },
                          model: {
                            value: _vm.activity.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.activity, "title", $$v)
                            },
                            expression: "activity.title",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c("label", { staticClass: "mb-0 col-sm-2" }, [
                      _vm._v(_vm._s(_vm.$t("description"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "textarea",
                            "text-area-rows": 5,
                            placeholder: _vm.$t("description_here"),
                          },
                          model: {
                            value: _vm.activity.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.activity, "description", $$v)
                            },
                            expression: "activity.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 mb-0 d-flex align-items-center",
                      },
                      [_vm._v(_vm._s(_vm.$t("set_schedule")))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center flex-column flex-sm-row",
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-lg-7 pr-lg-0" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "date",
                                    placeholder: _vm.$t("start_date"),
                                  },
                                  on: { input: _vm.setEndDateAsStartDate },
                                  model: {
                                    value: _vm.formData.started_at,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "started_at", $$v)
                                    },
                                    expression: "formData.started_at",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-lg-5 pl-lg-0" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "time",
                                    placeholder: _vm.$t("start_time"),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.setStart($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.activity.start_time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.activity, "start_time", $$v)
                                    },
                                    expression: "activity.start_time",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "d-flex align-items-center justify-content-center schedule-divider",
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-lg-7 pr-lg-0" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "date",
                                    "min-date": _vm.formData.started_at,
                                    placeholder: _vm.$t("end_date"),
                                  },
                                  model: {
                                    value: _vm.formData.ended_at,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "ended_at", $$v)
                                    },
                                    expression: "formData.ended_at",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-lg-5 pl-lg-0" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "time",
                                    placeholder: _vm.$t("end_time"),
                                  },
                                  model: {
                                    value: _vm.activity.end_time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.activity, "end_time", $$v)
                                    },
                                    expression: "activity.end_time",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "label",
                      {
                        staticClass: "mb-0 col-sm-2 d-flex align-items-center",
                      },
                      [_vm._v(_vm._s(_vm.$t("participants")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "multi-select",
                            list: _vm.personList,
                            "list-value-field": "name",
                            "is-animated-dropdown": true,
                          },
                          model: {
                            value: _vm.formData.person_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "person_id", $$v)
                            },
                            expression: "formData.person_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "label",
                      {
                        staticClass: "mb-0 col-sm-2 d-flex align-items-center",
                      },
                      [_vm._v(_vm._s(_vm.$t("collaborators")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "multi-select",
                            list: _vm.ownerList,
                            "list-value-field": "full_name",
                            "is-animated-dropdown": true,
                          },
                          model: {
                            value: _vm.formData.owner_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "owner_id", $$v)
                            },
                            expression: "formData.owner_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _c("app-overlay-loader"),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }