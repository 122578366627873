var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "vertical"
    ? _c("vertical-tab", { attrs: { tabs: _vm.tabs, icon: _vm.icon } })
    : _c("horizontal-tab", { attrs: { tabs: _vm.tabs } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }