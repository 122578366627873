var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": "Test Table",
          icon: "settings",
          directory: "Datatable",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card border-0 bg-transparent" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex align-items-center p-primary primary-card-color",
          },
          [
            _c("h4", { staticClass: "card-title d-inline-block mb-0" }, [
              _vm._v("Table name"),
            ]),
            _vm._v(" "),
            _c("app-search", { on: { input: _vm.getSearchValue } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body p-0" },
          [
            _c("app-table", {
              attrs: {
                id: _vm.tableId,
                options: _vm.options,
                "filtered-data": _vm.filteredData,
                search: _vm.search,
              },
              on: { action: _vm.getAction },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }