var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-primary user-profile" }, [
    _c(
      "div",
      {
        staticClass:
          "card position-relative card-with-shadow py-primary border-0",
      },
      [
        _c("div", { staticClass: "row align-items-center" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "media border-right px-5 pr-xl-5 pl-xl-0 align-items-center user-header-media",
                },
                [
                  _c(
                    "div",
                    { staticClass: "profile-pic-wrapper position-relative" },
                    [
                      _c("app-input", {
                        attrs: {
                          "generate-file-url": false,
                          label: _vm.$t("change"),
                          "wrapper-class": "circle small-wrapper mx-xl-auto",
                          name: "organization_logo",
                          type: "custom-file-upload",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeProfile()
                          },
                        },
                        model: {
                          value: _vm.profile_picture,
                          callback: function ($$v) {
                            _vm.profile_picture = $$v
                          },
                          expression: "profile_picture",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "media-body user-info-header" },
                    [
                      _vm.userInfoData.contact_type
                        ? [
                            _c("app-badge", {
                              attrs: {
                                className:
                                  "badge-sm badge-pill badge-" +
                                  _vm.userInfoData.contact_type.class,
                                label: _vm.userInfoData.contact_type.name,
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.componentType == "org"
                        ? [
                            _vm.userInfoData.country ||
                            _vm.userInfoData.area ||
                            _vm.userInfoData.state ||
                            _vm.userInfoData.city ||
                            _vm.userInfoData.zip_code ||
                            _vm.userInfoData.address
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center text-muted mt-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("app-icon", {
                                          staticClass: "mr-2 size-20",
                                          attrs: {
                                            name: "map-pin",
                                            "stroke-width": "1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("app-common-address", {
                                      staticClass: "font-size-90",
                                      attrs: { "row-data": _vm.userInfoData },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : [
                            _vm.userInfoData["email"]
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center justify-content-center justify-content-sm-start text-muted font-size-90 mt-2",
                                  },
                                  [
                                    _vm.userInfoData.email.length > 0
                                      ? _c("app-icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            name: "mail",
                                            "stroke-width": "1",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "mb-0 text-break" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.userInfoData.email.length > 0
                                                ? _vm.userInfoData.email[0]
                                                    .value
                                                : ""
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.userInfoData["phone"]
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center justify-content-center justify-content-sm-start text-muted font-size-90 mt-2",
                                  },
                                  [
                                    _vm.userInfoData.phone.length > 0
                                      ? _c("app-icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            name: "phone-call",
                                            "stroke-width": "1",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.userInfoData.phone.length > 0
                                              ? _vm.userInfoData.phone[0].value
                                              : ""
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-7" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "user-details px-5 px-sm-5 px-md-5 px-lg-0 px-xl-0 mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0",
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "border-right h-100 custom d-flex flex-column justify-content-around",
                          },
                          [
                            _c("div", { staticClass: "media mb-4 mb-xl-5" }, [
                              _c(
                                "div",
                                { staticClass: "align-self-center mr-3" },
                                [
                                  _c("app-icon", {
                                    staticClass: "primary-text-color",
                                    attrs: {
                                      name: "user",
                                      "stroke-width": "1",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.componentType == "org"
                                ? _c(
                                    "div",
                                    { staticClass: "media-body font-size-90" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "text-muted mb-0" },
                                        [_vm._v(_vm._s(_vm.$t("have")) + ":")]
                                      ),
                                      _vm._v(" "),
                                      _vm.userInfoData.linked_contact_count > 0
                                        ? _c("p", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.userInfoData
                                                    .linked_contact_count
                                                ) +
                                                "\n                      " +
                                                _vm._s(
                                                  _vm.$t("contact_person")
                                                ) +
                                                "\n                    "
                                            ),
                                          ])
                                        : _c("p", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("no_persons_linked_yet")
                                              )
                                            ),
                                          ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "media-body font-size-90" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "text-muted mb-0" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("working_on")) + ":"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.userInfoData.organizations.length > 0
                                        ? [
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.userInfoData
                                                      .organizations[0].name
                                                  ) +
                                                  "\n                        "
                                              ),
                                              _vm.userInfoData
                                                .linked_contact_count > 1
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "text-muted",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t("and")
                                                          ) +
                                                          "\n                          " +
                                                          _vm._s(
                                                            _vm.userInfoData
                                                              .linked_contact_count -
                                                              1
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t("more")
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        : [
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "no_organization_linked_yet"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                    ],
                                    2
                                  ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "media mb-4 mb-xl-0" }, [
                              _c(
                                "div",
                                { staticClass: "align-self-center mr-3" },
                                [
                                  _c("app-icon", {
                                    staticClass: "primary-text-color",
                                    attrs: {
                                      name: "phone",
                                      "stroke-width": "1",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "media-body font-size-90" },
                                [
                                  _c("p", { staticClass: "text-muted mb-0" }, [
                                    _vm._v(_vm._s(_vm.$t("deals")) + ":"),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("closed")) +
                                        ": " +
                                        _vm._s(
                                          _vm.userInfoData.close_deals_count
                                        ) +
                                        ",\n                      " +
                                        _vm._s(_vm.$t("open")) +
                                        ": " +
                                        _vm._s(
                                          _vm.userInfoData.open_deals_count
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-100 d-flex flex-column justify-content-around",
                          },
                          [
                            _c("div", { staticClass: "media mb-4 mb-xl-5" }, [
                              _c(
                                "div",
                                { staticClass: "align-self-center mr-3" },
                                [
                                  _c("app-icon", {
                                    staticClass: "primary-text-color",
                                    attrs: {
                                      name: "user",
                                      "stroke-width": "1",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "media-body font-size-90" },
                                [
                                  _c("p", { staticClass: "text-muted mb-0" }, [
                                    _vm._v(_vm._s(_vm.$t("owner")) + ":"),
                                  ]),
                                  _vm._v(" "),
                                  _vm.userInfoData.owner
                                    ? _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.userInfoData.owner.full_name
                                            ) +
                                            "\n                    "
                                        ),
                                      ])
                                    : _c(
                                        "p",
                                        {
                                          staticClass:
                                            "m-0 font-size-90 text-secondary",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.$t("user_deleted")) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "media mb-4 mb-xl-0" }, [
                              _c(
                                "div",
                                { staticClass: "align-self-center mr-3" },
                                [
                                  _c("app-icon", {
                                    staticClass: "primary-text-color",
                                    attrs: {
                                      name: "calendar",
                                      "stroke-width": "1",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "media-body font-size-90" },
                                [
                                  _c("p", { staticClass: "text-muted mb-0" }, [
                                    _vm._v(_vm._s(_vm.$t("created")) + ":"),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDateToLocal(
                                          _vm.userInfoData.created_at
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }