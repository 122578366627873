var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper p-4" }, [
    _c(
      "div",
      { staticClass: "card card-with-shadow border border-0 mb-primary" },
      [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("app-form-wizard", {
              attrs: { tabs: _vm.tabs },
              on: {
                selectedComponentName: _vm.selectedComponent,
                disabledTab: _vm.currentDisableTab,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }