var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("dashboard"),
          directory: _vm.$t("dashboard"),
          icon: "pie-chart",
        },
      }),
      _vm._v(" "),
      _vm.initialResponseCount < 2
        ? _c(
            "div",
            { staticClass: "card border-0 min-height-400" },
            [_c("app-overlay-loader")],
            1
          )
        : [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xl-8 mb-primary" }, [
                _c(
                  "div",
                  { staticClass: "card card-with-shadow border-0 h-100" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                      },
                      [
                        _c("h5", { staticClass: "card-title mb-0" }, [
                          _vm._v(_vm._s(_vm.$t("deals_overview"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "nav tab-filter-menu justify-content-flex-end",
                          },
                          _vm._l(
                            _vm.chartFilterOptions,
                            function (item, index) {
                              return _c(
                                "li",
                                { key: index, staticClass: "nav-item" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link py-0",
                                      class: [
                                        _vm.dealsFilter == item.id
                                          ? "active"
                                          : index === 0 &&
                                            _vm.dealsFilter === ""
                                          ? "active"
                                          : "",
                                      ],
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.dealsFilterValue(item.id)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.value) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body min-height-300" },
                      [
                        _vm.lineChartLoad
                          ? _c("app-overlay-loader")
                          : [
                              _c("app-chart", {
                                staticClass: "mb-primary",
                                attrs: {
                                  type: "custom-line-chart",
                                  height: 230,
                                  labels: _vm.lineChartLabels,
                                  "data-sets": _vm.lineChartData,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "chart-data-list d-flex flex-wrap justify-content-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "data-group-item",
                                      staticStyle: { color: "#4466f2" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "square",
                                        staticStyle: {
                                          "background-color": "#4466f2",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.$t("open")) +
                                          "\n\n                                    "
                                      ),
                                      _c("span", { staticClass: "value" }, [
                                        _vm._v(_vm._s(_vm.openDeal)),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "data-group-item",
                                      staticStyle: { color: "#27ae60" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "square",
                                        staticStyle: {
                                          "background-color": "#27ae60",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.$t("won")) +
                                          "\n\n                                    "
                                      ),
                                      _c("span", { staticClass: "value" }, [
                                        _vm._v(_vm._s(_vm.wonDeal)),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "data-group-item",
                                      staticStyle: { color: "#fc5710" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "square",
                                        staticStyle: {
                                          "background-color": "#fc5710",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.$t("lost")) +
                                          "\n\n                                    "
                                      ),
                                      _c("span", { staticClass: "value" }, [
                                        _vm._v(_vm._s(_vm.lostDeal)),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "data-group-item",
                                      staticStyle: { color: "#a45ffd" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "square",
                                        staticStyle: {
                                          "background-color": "#a45ffd",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.$t("total")) +
                                          "\n\n                                    "
                                      ),
                                      _c("span", { staticClass: "value" }, [
                                        _vm._v(_vm._s(_vm.totalDealOverview)),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xl-4 mb-primary" }, [
                _c(
                  "div",
                  { staticClass: "card card-with-shadow border-0 h-100" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                      },
                      [
                        _c("h5", { staticClass: "card-title mb-0" }, [
                          _vm._v(_vm._s(_vm.$t("total_deals"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "badge dashboard-badge badge-pill text-capitalize",
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.totalDeal) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.dataload
                      ? _c("app-overlay-loader")
                      : _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("app-chart", {
                              staticClass: "mb-primary",
                              attrs: {
                                type: "dough-chart",
                                height: 230,
                                labels: _vm.totalDealsLabels,
                                "data-sets": _vm.totalDealsDataSet,
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "chart-data-list" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                _vm._l(
                                  _vm.totalDealsChartElement,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "data-group-item",
                                          style: item.color,
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "square",
                                            style: item.background_color,
                                          }),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.key) +
                                              "\n                                        "
                                          ),
                                          _c("span", { staticClass: "value" }, [
                                            _vm._v(_vm._s(item.value)),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.$can("manage_public_access")
              ? [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xl-4 mb-primary" }, [
                      _c(
                        "div",
                        { staticClass: "card card-with-shadow border-0" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", { staticClass: "card-title mb-0" }, [
                                _vm._v(_vm._s(_vm.$t("total_contacts"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "badge dashboard-badge badge-pill text-capitalize",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.totalContact) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-body p-primary" },
                            _vm._l(_vm.contactList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "dashboard-widgets dashboard-icon-widget",
                                  class:
                                    index == _vm.contactList.length - 1
                                      ? ""
                                      : "pb-primary",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon-wrapper" },
                                    [
                                      _c("app-icon", {
                                        key: item.icon,
                                        attrs: { name: item.icon },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "widget-data" }, [
                                    _c("h6", [_vm._v(_vm._s(item.value))]),
                                    _vm._v(" "),
                                    _c("p", [_vm._v(_vm._s(item.title))]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-4 mb-primary" }, [
                      _c(
                        "div",
                        { staticClass: "card card-with-shadow border-0" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", { staticClass: "card-title mb-0" }, [
                                _vm._v(_vm._s(_vm.$t("total_employees"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "badge dashboard-badge badge-pill text-capitalize",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.totalEmployees) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-body p-primary" },
                            _vm._l(_vm.employeesList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "dashboard-widgets dashboard-icon-widget",
                                  class:
                                    index == _vm.employeesList.length - 1
                                      ? ""
                                      : "pb-primary",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon-wrapper" },
                                    [
                                      _c("app-icon", {
                                        key: item.icon,
                                        attrs: { name: item.icon },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "widget-data" }, [
                                    _c("h6", [_vm._v(_vm._s(item.value))]),
                                    _vm._v(" "),
                                    _c("p", [_vm._v(_vm._s(item.title))]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-4 mb-primary" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row dashboard-without-icon-widget mb-primary",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-xl-6 mb-4 mb-xl-0" },
                            [
                              _c("app-widget", {
                                attrs: {
                                  type: "app-widget-without-icon",
                                  label: _vm.$t("total_sent_proposal"),
                                  number: _vm.totalSendProposal,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xl-6" },
                            [
                              _c("app-widget", {
                                attrs: {
                                  type: "app-widget-without-icon",
                                  label: _vm.$t("total_accepted_proposal"),
                                  number: _vm.totalAcceptedProposal,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row dashboard-circle-widget" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-xl-6 mb-4 mb-xl-0" },
                            [
                              _c("app-widget", {
                                attrs: {
                                  type: "app-widget-with-circle",
                                  label: _vm.$t("sending_rate"),
                                  number: _vm.sendingRate,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xl-6" },
                            [
                              _c("app-widget", {
                                attrs: {
                                  type: "app-widget-with-circle",
                                  label: _vm.$t("acceptance_rate"),
                                  number: _vm.acceptanceRate,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xl-8 mb-4 mb-xl-0" }, [
                      _c(
                        "div",
                        { staticClass: "card card-with-shadow border-0 h-100" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", { staticClass: "card-title mb-0" }, [
                                _vm._v(_vm._s(_vm.$t("deals_on_pipeline"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "nav tab-filter-menu justify-content-flex-end",
                                },
                                _vm._l(
                                  _vm.lineChartFilterOption,
                                  function (item, index) {
                                    return _c(
                                      "li",
                                      { key: index, staticClass: "nav-item" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link py-0",
                                            class: [
                                              _vm.lineChartFilterValue ==
                                              item.id
                                                ? "active"
                                                : index === 0 &&
                                                  _vm.lineChartFilterValue ===
                                                    ""
                                                ? "active"
                                                : "",
                                            ],
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.getLineChartFilterValue(
                                                  item.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.value) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-body min-height-480" },
                            [
                              _vm.pipelineDataload
                                ? _c("app-overlay-loader")
                                : _c("app-chart", {
                                    attrs: {
                                      type: "horizontal-line-chart",
                                      height: 480,
                                      labels: _vm.HorizontalLineChartLabel,
                                      "data-sets": _vm.HorizontalLineChartData,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-4" }, [
                      _c(
                        "div",
                        { staticClass: "mb-primary" },
                        [
                          _c("app-widget", {
                            attrs: {
                              type: "app-widget-with-icon",
                              label: _vm.$t("total_pipeline"),
                              number: _vm.totalPipeline,
                              icon: "sun",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card card-with-shadow border-0" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", { staticClass: "card-title mb-0" }, [
                                _vm._v(_vm._s(_vm.$t("top_five_owners"))),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-body min-height-340" },
                            [
                              _vm.dataload
                                ? _c("app-overlay-loader")
                                : _c("app-chart", {
                                    attrs: {
                                      type: "bar-chart",
                                      height: 340,
                                      labels: _vm.barChartLabel,
                                      "data-sets": _vm.barChartData,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }