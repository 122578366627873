var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-5" }, [
    _c("h2", { staticClass: "text-center text-capitalize mb-primary" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.$t("install")) +
          " " +
          _vm._s(_vm.appName) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card card-with-shadow border-0 mb-primary" }, [
      _c("div", { staticClass: "card-header bg-transparent p-primary" }, [
        _c(
          "h5",
          { staticClass: "card-title mb-0 d-flex align-items-center" },
          [
            _c("app-icon", {
              staticClass: "primary-text-color mr-2",
              attrs: { name: "database" },
            }),
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("database_configuration")) +
                "\n            "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("db_connection")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "select",
                  list: [
                    { id: "mysql", value: _vm.$t("mysql") },
                    { id: "pgsql", value: _vm.$t("pgsql") },
                    { id: "sqlsrv", value: _vm.$t("sqlsrv") },
                  ],
                  placeholder: _vm.$t("app_environment"),
                  "error-message": _vm.$errorMessage(
                    _vm.errors,
                    "app_environment"
                  ),
                  required: true,
                },
                model: {
                  value: _vm.environment.database_connection,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "database_connection", $$v)
                  },
                  expression: "environment.database_connection",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("database_hostname")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("database_hostname"),
                  "error-message": _vm.$errorMessage(
                    _vm.errors,
                    "database_hostname"
                  ),
                  required: true,
                },
                model: {
                  value: _vm.environment.database_hostname,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "database_hostname", $$v)
                  },
                  expression: "environment.database_hostname",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("database_port")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("database_port"),
                  "error-message": _vm.$errorMessage(
                    _vm.errors,
                    "database_port"
                  ),
                },
                model: {
                  value: _vm.environment.database_port,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "database_port", $$v)
                  },
                  expression: "environment.database_port",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("database_name")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("database_name"),
                  "error-message": _vm.$errorMessage(
                    _vm.errors,
                    "database_name"
                  ),
                },
                model: {
                  value: _vm.environment.database_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "database_name", $$v)
                  },
                  expression: "environment.database_name",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("database_username")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("database_username"),
                  "error-message": _vm.$errorMessage(
                    _vm.errors,
                    "database_username"
                  ),
                  autocomplete: "off",
                },
                model: {
                  value: _vm.environment.database_username,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "database_username", $$v)
                  },
                  expression: "environment.database_username",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("database_password")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("database_password"),
                  "error-message": _vm.$errorMessage(
                    _vm.errors,
                    "database_password"
                  ),
                  autocomplete: "off",
                },
                model: {
                  value: _vm.environment.database_password,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "database_password", $$v)
                  },
                  expression: "environment.database_password",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card card-with-shadow border-0 mb-primary" }, [
      _c("div", { staticClass: "card-header bg-transparent p-primary" }, [
        _c(
          "h5",
          { staticClass: "card-title mb-0 d-flex align-items-center " },
          [
            _c("app-icon", {
              staticClass: "primary-text-color mr-2",
              attrs: { name: "key" },
            }),
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("purchase_code")) +
                "\n            "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("code")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("code"),
                  "error-message": _vm.$errorMessage(_vm.errors, "code"),
                },
                model: {
                  value: _vm.environment.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "code", $$v)
                  },
                  expression: "environment.code",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group mt-5" },
      [
        _c("app-submit-button", {
          attrs: {
            loading: _vm.loading,
            "btn-class": "btn-block",
            label: _vm.$t("save_&_next"),
          },
          on: { click: _vm.submitData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }