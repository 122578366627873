var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-tag-manager", {
        attrs: {
          tags: _vm.tags,
          list: _vm.allTags,
          "list-value-field": "name",
          "tag-preloader": _vm.tagPreloader,
        },
        on: {
          storeTag: _vm.storeAndAttachTag,
          attachTag: _vm.attachTag,
          detachTag: _vm.detachTag,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }