var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "radio-button-group",
      class: { disabled: _vm.data.disabled },
    },
    [
      _c(
        "div",
        {
          staticClass: "btn-group btn-group-toggle",
          attrs: { "data-toggle": "buttons" },
        },
        _vm._l(_vm.data.list, function (item, index) {
          return _c(
            "label",
            {
              key: index,
              staticClass: "btn border",
              class: _vm.value == item.id ? "focus active" : "",
            },
            [
              _c("input", {
                attrs: {
                  type: "radio",
                  name: _vm.name,
                  id: _vm.name + "-" + index,
                  required: _vm.data.required,
                  disabled: item.disabled,
                },
                domProps: { value: item.id, checked: _vm.value == item.id },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.getValue(item.id)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(item[_vm.data.listValueField]))]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }