var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow mb-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
        },
        [
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isDetailsEditable,
                  expression: "isDetailsEditable",
                },
              ],
              staticClass: "card-title text-muted mb-0",
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.$t("details")) + "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isDetailsEditable,
                  expression: "!isDetailsEditable",
                },
              ],
              staticClass: "card-title text-muted mb-0",
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.$t("edit_details")) + "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isDetailsEditable,
                    expression: "!isDetailsEditable",
                  },
                ],
                staticClass: "text-muted",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.showDetailsHide($event)
                  },
                },
              },
              [
                _c("app-icon", {
                  attrs: { name: "x-square", "stroke-width": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isDetailsEditable,
                    expression: "!isDetailsEditable",
                  },
                ],
                staticClass: "text-muted",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.showDetailsSave($event)
                  },
                },
              },
              [
                _c("app-icon", {
                  attrs: { name: "check-square", "stroke-width": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.dealOpen
              ? _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isDetailsEditable,
                        expression: "isDetailsEditable",
                      },
                    ],
                    staticClass: "text-muted",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showDetailsEditable($event)
                      },
                    },
                  },
                  [
                    _c("app-icon", {
                      attrs: { name: "edit", "stroke-width": "1" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDetailsEditable,
                expression: "isDetailsEditable",
              },
            ],
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "mb-0 text-muted" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("title")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.dealDetails.title) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "mb-0 text-muted" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("description")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.dealDetails.description
                        ? _vm.dealDetails.description
                        : _vm.$t("not_added_yet")
                    ) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.dealDetails.status
              ? _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "mb-0 text-muted col-4 d-flex align-items-center",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("status")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-8" },
                    [
                      _c("app-badge", {
                        attrs: {
                          className:
                            "badge-sm badge-pill badge-" +
                            _vm.dealDetails.status.class,
                          label: _vm.dealDetails.status.translated_name,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass:
                    "mb-0 text-muted col-4 d-flex align-items-center",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("value")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-8" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.dealDetails.value
                        ? _vm.dealDetails.value
                        : _vm.$t("not_added_yet")
                    ) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.dealDetails.contextable
              ? [
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "mb-0 text-muted col-4 d-flex align-items-center",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("lead_type")) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-8" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.dealDetails.lead_type == 1
                              ? _vm.$t("person")
                              : _vm.$t("organization")
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "mb-0 text-muted col-4 d-flex align-items-center",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("name")) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center primary-text-color",
                        },
                        [
                          _c("app-avatar", {
                            staticClass: "mr-2",
                            attrs: {
                              title: _vm.dealDetails.contextable.name,
                              "avatar-class": "avatars-w-30",
                              img: _vm.dealDetails.contextable.profile_picture
                                ? _vm.urlGenerator(
                                    _vm.dealDetails.contextable.profile_picture
                                      .path
                                  )
                                : _vm.dealDetails.contextable.profile_picture,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.urlGenerator(
                                  "/" +
                                    _vm.componentType +
                                    "/" +
                                    _vm.dealDetails.contextable.id +
                                    "/edit"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.dealDetails.contextable.name) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.dealDetails.lead_type == 2
                    ? _c(
                        "div",
                        { staticClass: "form-group row" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mb-0 text-muted col-4 d-flex align-items-center",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("contact_person")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.dealDetails.contact_person,
                            function (contactPerson) {
                              return [
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center primary-text-color",
                                    },
                                    [
                                      _c("app-avatar", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          title: contactPerson.name,
                                          "avatar-class": "avatars-w-30",
                                          img: contactPerson.profile_picture
                                            ? _vm.urlGenerator(
                                                contactPerson.profile_picture
                                                  .path
                                              )
                                            : contactPerson.profile_picture,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            href: _vm.route("persons.edit", {
                                              id: contactPerson.id,
                                            }),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(contactPerson.name)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            }
                          ),
                          _vm._v(" "),
                          !_vm.dealDetails.contact_person.length
                            ? [
                                _c(
                                  "p",
                                  { staticClass: "col-8 text-muted mb-0" },
                                  [_vm._v(_vm._s(_vm.$t("not_added_yet")))]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass:
                    "mb-0 text-muted col-4 d-flex align-items-center",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("expected_closing_date")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-8 text-muted" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.dealDetails.expired_at == null
                        ? _vm.$t("not_added_yet")
                        : _vm.formatDateToLocal(_vm.dealDetails.expired_at)
                    ) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass:
                    "mb-0 text-muted col-4 d-flex align-items-center",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("owner")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.dealDetails.owner
                ? _c("div", { staticClass: "col-8" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("app-avatar", {
                          staticClass: "mr-2",
                          attrs: {
                            "avatar-class": "avatars-w-30",
                            img: _vm.dealDetails.owner.profile_picture
                              ? _vm.urlGenerator(
                                  _vm.dealDetails.owner.profile_picture.path
                                )
                              : _vm.dealDetails.owner.profile_picture,
                            title: _vm.dealDetails.owner.full_name,
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.dealDetails.owner.full_name)),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isDetailsEditable,
                expression: "!isDetailsEditable",
              },
            ],
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(" " + _vm._s(_vm.$t("title")) + " ")]),
              _vm._v(" "),
              _c(
                "div",
                {},
                [
                  _c("app-input", {
                    attrs: { type: "text", placeholder: _vm.$t("enter_title") },
                    model: {
                      value: _vm.dealDetails.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.dealDetails, "title", $$v)
                      },
                      expression: "dealDetails.title",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(" " + _vm._s(_vm.$t("description")) + " ")]),
              _vm._v(" "),
              _c(
                "div",
                {},
                [
                  _c("app-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("description_here"),
                    },
                    model: {
                      value: _vm.dealDetails.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.dealDetails, "description", $$v)
                      },
                      expression: "dealDetails.description",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "col-4 mb-0 d-flex align-items-center" },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("value")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-8" },
                [
                  _c("app-input", {
                    attrs: { type: "number", placeholder: _vm.$t("value") },
                    model: {
                      value: _vm.dealDetails.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.dealDetails, "value", $$v)
                      },
                      expression: "dealDetails.value",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "mb-0 col-4 d-flex align-items-center" },
                [_vm._v("\n                    " + _vm._s(_vm.$t("lead_type")))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-8" },
                [
                  _c("app-input", {
                    attrs: {
                      list: [
                        { id: 1, value: "Person" },
                        { id: 2, value: "Organization" },
                      ],
                      type: "radio",
                    },
                    on: { change: _vm.changeLeadType },
                    model: {
                      value: _vm.formData.lead_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "lead_type", $$v)
                      },
                      expression: "formData.lead_type",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.formData.lead_type == 1
              ? _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      { staticClass: "mb-0 col-4 d-flex align-items-center" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("lead")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            list: _vm.personList,
                            placeholder: _vm.$t("choose_one"),
                            "list-value-field": "name",
                            type: "search-select",
                          },
                          model: {
                            value: _vm.formData.contextable_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "contextable_id", $$v)
                            },
                            expression: "formData.contextable_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.formData.lead_type == 2
              ? _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      { staticClass: "mb-0 col-4 d-flex align-items-center" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("lead")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            list: _vm.organizationList,
                            placeholder: _vm.$t("choose_one"),
                            "list-value-field": "name",
                            type: "search-select",
                          },
                          on: { input: _vm.resetPerson },
                          model: {
                            value: _vm.formData.contextable_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "contextable_id", $$v)
                            },
                            expression: "formData.contextable_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.formData.lead_type == 2
              ? _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      { staticClass: "mb-0 col-4 d-flex align-items-center" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("contact_person")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: {
                          "col-8": !_vm.formData.person_id,
                          "col-7": _vm.formData.person_id,
                        },
                      },
                      [
                        _c("app-input", {
                          attrs: {
                            list: _vm.personAsOrg,
                            placeholder: _vm.$t("choose_a_contact_person"),
                            "list-value-field": "name",
                            type: "search-select",
                          },
                          model: {
                            value: _vm.formData.person_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "person_id", $$v)
                            },
                            expression: "formData.person_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.formData.lead_type == 2 && _vm.formData.person_id
                      ? _c("div", { staticClass: "col-1 pl-0" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-muted",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.detachContactPerson(
                                    _vm.dealDetails.id
                                  )
                                },
                              },
                            },
                            [
                              _c("app-icon", {
                                attrs: {
                                  name: "trash",
                                  "stroke-width": "1",
                                  width: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                { staticClass: "col-4 mb-0 d-flex align-items-center" },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("expected_closing_date"))
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-8" },
                [
                  _c("app-input", {
                    attrs: { type: "date" },
                    model: {
                      value: _vm.formData.expired_at,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "expired_at", $$v)
                      },
                      expression: "formData.expired_at",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.$can("manage_public_access")
              ? _c("div", { staticClass: "form-group row mb-0" }, [
                  _c(
                    "label",
                    { staticClass: "col-4 mb-0 d-flex align-items-center" },
                    [_vm._v("\n                    " + _vm._s(_vm.$t("owner")))]
                  ),
                  _vm._v(" "),
                  _vm.dealDetails.owner
                    ? _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              list: _vm.ownerList,
                              "list-value-field": "full_name",
                              type: "select",
                            },
                            model: {
                              value: _vm.dealDetails.owner_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.dealDetails, "owner_id", $$v)
                              },
                              expression: "dealDetails.owner_id",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }