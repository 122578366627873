var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "modalId",
        "modal-size": "default",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("delete_stage"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c(
          "form",
          { ref: "form" },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "mb-2 col-sm-12" }, [
                  _c("h5", { staticStyle: { color: "#ff0000" } }, [
                    _vm._v(_vm._s(_vm.$t("opps!"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12" }, [
                  _vm.stageList.length > 0
                    ? _c("h5", [
                        _vm._v(
                          _vm._s(_vm.$t("what_about_the_deals_of_this_stage")) +
                            "?"
                        ),
                      ])
                    : _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("deals_on_this_stage_will_be_lost_forever")
                          )
                        ),
                      ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.stageList.length > 0
              ? _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "radio",
                            list: [
                              {
                                id: 1,
                                value:
                                  "Want to move these deals to another stage?",
                              },
                            ],
                          },
                          model: {
                            value: _vm.formData.delete_option,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "delete_option", $$v)
                            },
                            expression: "formData.delete_option",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.stageList.length > 0
              ? [
                  _vm.formData.delete_option == 1
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c("app-input", {
                                attrs: {
                                  type: "select",
                                  "list-value-field": "name",
                                  list: _vm.stageList,
                                },
                                model: {
                                  value: _vm.formData.stage_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "stage_id", $$v)
                                  },
                                  expression: "formData.stage_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "radio",
                        list: [{ id: 0, value: "Or delete anyway" }],
                      },
                      model: {
                        value: _vm.formData.delete_option,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "delete_option", $$v)
                        },
                        expression: "formData.delete_option",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("cancel")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-danger",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.deleteStage($event)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("delete")) + "\n    ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }