var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "view-user-modal",
        "modal-size": "default",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.role.name) + " : " + _vm._s(_vm.$t("permission"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.role.name === "App Admin"
            ? [
                _c("p", [
                  _vm._v(
                    "\n                App admin (Application administrator) performs all admin activities and has full management access.\n                Also\n                can add & configure application settings, create users through users invitation & assign a role with\n                application permissions\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mt-3" }, [
                  _vm._v(
                    "\n                N.B: Remember that your application must have at least one individually assigned App admin. If you\n                just\n                have one individually assigned super admin, you can't edit or revoke their admin permissions.\n            "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.role.name === "Manager"
            ? [
                _c("p", [
                  _vm._v(
                    "\n                Managers perform user-related activities for specific modules of the application. Managers can view\n                and manage(add/edit/delete) as well as all Leads, Deals, Proposals, Activities & Reports. Also can\n                view a presentation of these data in the dashboard.\n            "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.role.name === "Agent"
            ? [
                _c("p", [
                  _vm._v(
                    "\n                Agent has a fixed set of permissions, but there are also restrictions on what this role can do.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("Agent have the following permissions:")]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _vm._v(
                      " Can view Dashboard (Only his/her deals related data)."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      " Can add leads People and Organization (manage his/her own created People and Organization).\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Can view & use lead groups.")]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Can view & use the Pipeline/Kanban view."),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Can add deals for his/her person and organization."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Can manage and comment only on his/her own created deal."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Can view and use lost reasons.")]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Can send proposals to his/her own lead of a deal."),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Can create activities within his/her own People, Organizations and deals."
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.role.name === "Client"
            ? [
                _c("p", [
                  _vm._v(
                    '\n                Clients (Leads) have view and communication access to most data in the application. You can manage\n                Leads by inviting as "Client" users from the '
                  ),
                  _c("b", [_vm._v("Leads (People)")]),
                  _vm._v(" module action.\n            "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                Client can view the following:\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _vm._v(
                      "Can view Dashboard (Only his/her deals related data)."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Can view organizations that he/she belongs to."),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Can view his/her own deals and comment on these deals."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Can view proposals list and Kanban view."),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("close")) + "\n        ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }