var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mainPreloader
        ? _c("app-overlay-loader")
        : _c(
            "div",
            { staticClass: "content-wrapper reports" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c("app-breadcrumb", {
                      attrs: {
                        directory: _vm.$t("reports"),
                        icon: "bar-chart",
                        "page-title": _vm.pageTitle,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.isActivePrimaryFilters
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-6 col-sm-8 col-md-9 col-lg-10 col-xl-10",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "filters-wrapper d-flex justify-content-start flex-wrap",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn d-block d-sm-none btn-toggle-filters",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.toggleFilters($event)
                                  },
                                },
                              },
                              [
                                _vm._v("\n            co\n            "),
                                _c("app-icon", { attrs: { name: "filter" } }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("filters")) +
                                    "\n          "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isFiltersOpen,
                                    expression: "isFiltersOpen",
                                  },
                                ],
                                staticClass: "mobile-filters-wrapper",
                              },
                              [
                                _c("app-filter", {
                                  attrs: {
                                    filters: _vm.filters,
                                    "table-id": _vm.tableId,
                                  },
                                  on: { "get-values": _vm.getFilterValues },
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-with-shadow d-sm-none btn-close-filter-wrapper d-flex justify-content-center align-items-center",
                                    attrs: { type: "button" },
                                    on: { click: _vm.toggleFilters },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("close")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 mb-primary" }, [
                  _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _vm.showChartFilter
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-start align-items-center",
                                },
                                [
                                  _c("div", { staticClass: "mr-3" }, [
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "nav tab-filter-menu justify-content-flex-end",
                                      },
                                      _vm._l(
                                        _vm.reportsChartsFilter,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "nav-item",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "nav-link py-0",
                                                  class: [
                                                    _vm.filterParameter
                                                      .group_by == item.id
                                                      ? "active"
                                                      : index === 0 &&
                                                        _vm.filterParameter
                                                          .group_by === ""
                                                      ? "active"
                                                      : "",
                                                  ],
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.getGroupByFilterOptions(
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.value))]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "filters-wrapper d-flex justify-content-start flex-wrap mt-2 ml-2",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn d-block d-sm-none btn-toggle-filters",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.toggleFilters($event)
                                            },
                                          },
                                        },
                                        [
                                          _c("app-icon", {
                                            attrs: { name: "filter" },
                                          }),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("filters")) +
                                              "\n                "
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isFiltersOpen,
                                              expression: "isFiltersOpen",
                                            },
                                          ],
                                          staticClass: "mobile-filters-wrapper",
                                        },
                                        [
                                          _c("app-filter", {
                                            staticClass: "ml-2",
                                            attrs: {
                                              filters: _vm.secondaryFilters,
                                              "table-id": "stagechartFilter",
                                            },
                                            on: {
                                              "get-values":
                                                _vm.getSecondaryFilterValues,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-with-shadow d-sm-none btn-close-filter-wrapper d-flex justify-content-center align-items-center",
                                              attrs: { type: "button" },
                                              on: { click: _vm.toggleFilters },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.$t("close")) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center",
                                },
                                [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("order_report_by")) + ":"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("app-input", {
                                    attrs: {
                                      list: _vm.countValueList,
                                      "list-value-field": "name",
                                      type: "radio-buttons",
                                    },
                                    on: {
                                      input: _vm.getCountValueFilterValues,
                                    },
                                    model: {
                                      value: _vm.filterParameter.countValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterParameter,
                                          "countValue",
                                          $$v
                                        )
                                      },
                                      expression: "filterParameter.countValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isActive
                        ? _c(
                            "div",
                            { staticClass: "card-body pt-5" },
                            [
                              _c("app-chart", {
                                tag: "component",
                                attrs: {
                                  "data-sets": _vm.dealReport.dataSet,
                                  height: 380,
                                  labels: _vm.dealReport.labels,
                                  type: "horizontal-line-chart",
                                },
                              }),
                              _vm._v(" "),
                              _c("hr", {
                                staticClass: "my-5 reports-table-divider",
                              }),
                              _vm._v(" "),
                              _vm.isActivePrimaryFilters
                                ? _c("app-table", {
                                    staticClass: "remove-datatable-x-padding",
                                    attrs: {
                                      id: _vm.tableId,
                                      "filtered-data": _vm.filterParameter,
                                      options: _vm.tableOptions,
                                    },
                                    on: { action: _vm.triggerActions },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.detailsViewModal && _vm.isActivePrimaryFilters
                ? _c("report-deal-details", {
                    attrs: {
                      id: _vm.detailsViewId,
                      filterValue: this.filterParameter,
                      groupBy: _vm.filterParameter.group_by,
                      "modal-id": "details-view-modal",
                      pageHeader: _vm.detailsModalHeader,
                      statusLostId: _vm.statusLostId,
                      "table-id": "details-view-modal",
                    },
                    on: { "close-modal": _vm.closedDetailsViewModal },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }