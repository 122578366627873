var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-wrapper",
      staticStyle: { "min-height": "600px", width: "300px" },
    },
    [
      _c("app-template-preview-card", {
        attrs: {
          id: _vm.id,
          card: _vm.card,
          "default-content-key": "defaultContent",
          "custom-content-key": "customContent",
          "subject-key": "subject",
          "show-action": true,
          "preview-type": "image",
          "preview-image-key": { relation: "thumbnail", key: "path" },
          actions: _vm.actions,
        },
      }),
      _vm._v(" "),
      _vm.addEditModal
        ? _c(
            "app-modal",
            {
              attrs: { "modal-id": "example", "modal-size": "default" },
              on: { "close-moadal": _vm.closeAddEditModal },
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "exampleModalLabel" },
                  },
                  [_vm._v("Modal title")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close outline-none",
                    attrs: { type: "button" },
                    on: { click: _vm.closeAddEditModal },
                  },
                  [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
                ),
              ]),
              _vm._v(" "),
              _c("template", { slot: "body" }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.card.customContent
                        ? _vm.card.customContent
                        : _vm.card.defaultContent
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-4",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.closeAddEditModal },
                  },
                  [_vm._v("Close\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "button" } },
                  [_vm._v("Save changes")]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteModal
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "delete-example" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }