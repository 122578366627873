var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("date-range-picker", {
    attrs: {
      label: _vm.label,
      "preset-ranges": _vm.presetRanges,
      "visible-value": _vm.visibleValue,
      "table-id": _vm.tableId,
    },
    on: { changed: _vm.changed },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }