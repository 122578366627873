var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.dataLoaded
        ? _c("app-overlay-loader")
        : _c(
            "div",
            { staticClass: "content-wrapper" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c("app-breadcrumb", {
                      attrs: {
                        "page-title": _vm.name,
                        directory: [_vm.$t("contacts"), _vm.name],
                        icon: "users",
                        button: {
                          label: _vm.$t("back"),
                          url: _vm.route("organizations.lists"),
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "float-md-right mb-3 mb-sm-3 mb-md-0" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-with-shadow",
                          attrs: { type: "button", "data-toggle": "modal" },
                          on: {
                            click: function ($event) {
                              return _vm.openDealModal()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("add_deal")) + "\n            ")]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("app-user-info-details", {
                tag: "component",
                attrs: {
                  "user-info-sync-url": _vm.route(
                    "organizations.upload-profile-picture-of",
                    { id: _vm.formData.id }
                  ),
                  "user-info-data": _vm.formData,
                  "component-type": "org",
                },
                on: { "update-request": _vm.updateRequest },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xl-4" },
                  [
                    _c("app-details-information", {
                      staticClass: "mb-primary",
                      attrs: {
                        "details-data": _vm.formData,
                        "details-sync-url": _vm.route("organizations.update", {
                          id: _vm.formData.id,
                        }),
                      },
                      on: { "update-request": _vm.updateRequest },
                    }),
                    _vm._v(" "),
                    _vm.isComponent
                      ? _c("app-details-job-title", {
                          tag: "component",
                          staticClass: "mb-primary",
                          attrs: {
                            "job-title-data": _vm.formData.persons,
                            "job-title-url-sync": _vm.route(
                              "organizations.sync-person",
                              { id: _vm.formData.id }
                            ),
                            "component-type": "organization",
                            "data-list": _vm.peopleList,
                          },
                          on: { "update-request": _vm.updateRequest },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("app-address-details", {
                      staticClass: "mb-primary",
                      attrs: {
                        "address-details": _vm.formData,
                        "address-update-url": _vm.route(
                          "organizations.update",
                          { id: _vm.formData.id }
                        ),
                      },
                      on: { "update-request": _vm.updateRequest },
                    }),
                    _vm._v(" "),
                    _c("app-details-deals", {
                      tag: "component",
                      staticClass: "mb-primary",
                      attrs: {
                        Deals: _vm.formData.deals,
                        "contact-list": _vm.peopleList,
                        Id: _vm.formData.id,
                        "component-type": "org",
                      },
                    }),
                    _vm._v(" "),
                    _vm.dataLoaded && _vm.isComponent
                      ? _c("details-page-custom-field", {
                          attrs: {
                            "component-type": "organization",
                            "form-data": _vm.formData,
                            "update-url": _vm.route("organizations.update", {
                              id: _vm.formData.id,
                            }),
                          },
                          on: { "update-request": _vm.updateRequest },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("details-tag-manager", {
                      staticClass: "mb-primary",
                      attrs: {
                        "post-url": _vm.route("organizations.attach-tag", {
                          id: _vm.formData.id,
                        }),
                        "taggable-id": _vm.formData.id,
                        tagData: _vm.formData.tags,
                      },
                    }),
                    _vm._v(" "),
                    _c("app-follower-details", {
                      staticClass: "mb-primary",
                      attrs: {
                        "follower-data": _vm.formData,
                        "follower-sync-url": _vm.route(
                          "organizations.sync-follower",
                          { id: _vm.formData.id }
                        ),
                        "get-follower-url": _vm.route(
                          "organizations.get-follower",
                          { id: _vm.formData.id }
                        ),
                        "people-list": _vm.peopleList,
                      },
                      on: { "update-request": _vm.updateRequest },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xl-8" },
                  [
                    _vm.appTapShow
                      ? _c("app-tab", {
                          staticClass: "mb-primary",
                          attrs: {
                            type: "horizontal",
                            tabs: _vm.todoActivityTab,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isComponent && _vm.activityStatusList.length
                      ? _c("app-common-activity-show", {
                          attrs: {
                            Data: _vm.formData,
                            "edit-url": _vm.selectedUrl,
                            "activity-filter-url": _vm.route(
                              "organizations.view-activities",
                              { id: _vm.formData.id }
                            ),
                            "file-filter-url": _vm.route(
                              "organization.get-file",
                              { id: _vm.formData.id }
                            ),
                            "note-filter-url": _vm.route(
                              "organization.get-note",
                              { id: _vm.formData.id }
                            ),
                            "component-type": "org",
                            "activity-status": _vm.activityStatusList,
                          },
                          on: {
                            "open-activity": _vm.editActivity,
                            "open-note-modal": _vm.editNote,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.isDealModalActive
                ? _c("app-deal-modal", {
                    attrs: {
                      "pre-selected-option": {
                        organization_id: _vm.formData.id,
                      },
                      "component-type": "org",
                      selectedUrlId: _vm.formData.id,
                    },
                    on: { "close-modal": _vm.closeDealModal },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.orgActivitiesModal
                ? _c("app-common-activity-modal", {
                    attrs: {
                      activity: _vm.rowData,
                      "selected-url": _vm.editedUrl,
                    },
                    on: { "close-modal": _vm.closeActivityModal },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isNoteModal
                ? _c("app-common-note-modal", {
                    attrs: { note: _vm.noteRowData },
                    on: { "close-modal": _vm.closeNoteModal },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }