var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: { "modal-id": _vm.modalId, modalSize: "fullscreen" },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "h5",
          { staticClass: "modal-title" },
          [
            _c("app-icon", { attrs: { name: "cpu" } }),
            _vm._v(" " + _vm._s(_vm.$t("template_gallery")) + "\n        "),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [
            _c(
              "span",
              { staticClass: "close-btn", attrs: { "aria-hidden": "true" } },
              [_c("app-icon", { attrs: { name: "x" } })],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("h4", [
                _vm._v(
                  _vm._s(_vm.$t("our_template") + " " + _vm.$t("gallery"))
                ),
              ]),
              _vm._v(" "),
              _c("app-card-view", {
                attrs: {
                  id: "template-choose-modal",
                  properties: _vm.properties,
                },
                on: { selectedTemplateCard: _vm.selectTemplate },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }