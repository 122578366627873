var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "user-modal",
        "modal-size": "default",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _vm.selectedUrl
          ? _c("h5", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.$t("edit_user"))),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              class: { "loading-opacity": _vm.preloader },
              attrs: { "data-url": _vm.selectedUrl },
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("first_name")))]),
                  _vm._v(" "),
                  _c("app-input", {
                    attrs: { required: true },
                    model: {
                      value: _vm.formData.first_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "first_name", $$v)
                      },
                      expression: "formData.first_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("last_name")))]),
                  _vm._v(" "),
                  _c("app-input", {
                    attrs: { required: true },
                    model: {
                      value: _vm.formData.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "last_name", $$v)
                      },
                      expression: "formData.last_name",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }