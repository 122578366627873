var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleActions.length > 0
    ? _c("div", { staticClass: "dropdown options-dropdown d-inline-block" }, [
        _c(
          "button",
          {
            key: "more-vertical-" + _vm.visibleActions.length,
            staticClass: "btn-option btn",
            attrs: {
              type: "button",
              "data-toggle": "dropdown",
              title: _vm.$t("actions"),
            },
          },
          [_c("app-icon", { attrs: { name: "more-vertical" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            key: "dropdown-" + _vm.visibleActions.length,
            staticClass: "dropdown-menu dropdown-menu-right py-2 mt-1",
          },
          _vm._l(_vm.visibleActions, function (action, index) {
            return _c(
              "a",
              {
                key: index,
                staticClass: "dropdown-item px-4 py-2",
                class: action.className,
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.callMethod("", action)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(action.title) + "\n        ")]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }