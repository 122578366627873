var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c(
      "div",
      { staticClass: "card card-with-shadow border border-0 custom-scrollbar" },
      [
        _c("app-organization-chart", {
          attrs: { "chart-data": _vm.chartData, druggable: true },
          on: { "selected-item": _vm.selectedItem },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }