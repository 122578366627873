var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown-search-select tag-manager" }, [
    _c(
      "div",
      {
        staticClass: "search-filter-dropdown",
        class: { disabled: _vm.disabled },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "dropdown dropdown-with-animation keep-inside-clicks-open",
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-light dropdown-toggle border-right custom-scrollbar",
                attrs: {
                  id: "dropdownMenuLink",
                  "data-toggle": "dropdown",
                  type: "button",
                },
                on: { click: _vm.startNavigation },
              },
              [_c("app-icon", { attrs: { name: "flag" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dropdown-menu chips-dropdown-menu radius-15 py-0",
                attrs: { "aria-labelledby": "dropdownMenuLink" },
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group form-group-with-search" },
                  [
                    _c(
                      "span",
                      { staticClass: "form-control-feedback" },
                      [
                        _c("app-icon", {
                          staticClass: "size-20",
                          attrs: { name: "search" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchValue,
                          expression: "searchValue",
                        },
                      ],
                      ref: "searchInput",
                      staticClass: "form-control",
                      attrs: { type: "text", autofocus: _vm.startNavigation },
                      domProps: { value: _vm.searchValue },
                      on: {
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp",
                              ])
                            ) {
                              return null
                            }
                            return _vm.navigateUp($event)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown",
                              ])
                            ) {
                              return null
                            }
                            return _vm.navigateDown($event)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.enterSelectedValue($event)
                          },
                        ],
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchValue = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    !_vm.options.length && _vm.searchValue !== ""
                      ? _c(
                          "div",
                          {
                            staticClass: "color-picker-wrapper",
                            attrs: { title: _vm.$t("pick_a_color") },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "input-color-container" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.color,
                                      expression: "color",
                                    },
                                  ],
                                  staticClass: "input-color",
                                  attrs: { id: "input-color", type: "color" },
                                  domProps: { value: _vm.color },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.color = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-color-label",
                                    attrs: { for: "input-color" },
                                  },
                                  [
                                    _c("app-icon", {
                                      style: "color: " + _vm.color,
                                      attrs: { name: "palette" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                !_vm.options.length && _vm.searchValue !== ""
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "animate__animated animate__fadeIn d-flex flex-wrap align-items-center justify-content-between px-primary mb-3",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-truncate chip",
                            style: "background-color: " + _vm.color,
                          },
                          [
                            _c("span", { staticClass: "chips-label" }, [
                              _vm._v(_vm._s(_vm.searchValue)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "default-base-color rounded-circle width-27 height-27 d-flex align-items-center justify-content-center",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.randomColor($event)
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "text-primary size-15",
                              attrs: { name: "refresh-ccw" },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider my-0" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "optionList",
                    staticClass:
                      "dropdown-search-result-wrapper custom-scrollbar",
                  },
                  [
                    _vm._l(_vm.options, function (option, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass:
                            "dropdown-item d-flex align-items-center justify-content-between",
                          class: {
                            active: index === _vm.activeIndex,
                            selected: _vm.isChipSelected(option.id),
                            disabled: option.disabled,
                          },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addChips(option)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("span", {
                                staticClass:
                                  "width-20 height-20 d-inline-block rounded-circle mr-2",
                                style: "background-color: " + option.color,
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(option[_vm.listValueField]) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "check-sign" },
                            [
                              _c("app-icon", {
                                staticClass: "menu-icon",
                                attrs: { name: "check" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.options.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-center text-muted text-size-13 py-primary",
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.hintText) },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }