var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _vm.rowData.status.name == "status_draft"
        ? [_vm._v("-")]
        : [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c(
                "label",
                { staticClass: "custom-control border-switch mb-0 mr-3" },
                [
                  _c("input", {
                    staticClass: "border-switch-control-input",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: _vm.isChecked(_vm.rowData.status.name),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.statusChange($event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "border-switch-control-indicator",
                  }),
                ]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }