var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.$t("add_template"),
                directory: [_vm.$t("proposals"), _vm.$t("add_template")],
                icon: "hexagon",
                button: { label: _vm.$t("back") },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("form", { ref: "form", attrs: { "data-url": _vm.submitUrl } }, [
        _c("div", { staticClass: "card border-0 card-with-shadow" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("div", { staticClass: "col-lg-9 col-xl-9" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-lg-3 col-xl-3 d-flex align-items-center",
                    },
                    [
                      _c("label", { staticClass: "mb-lg-0" }, [
                        _vm._v(_vm._s(_vm.$t("template_title"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-9 col-xl-9" }, [
                    _c(
                      "div",
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("type_your_template_title"),
                            required: true,
                          },
                          model: {
                            value: _vm.formData.subject,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "subject", $$v)
                            },
                            expression: "formData.subject",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-3 col-xl-3  d-flex h-100 align-items-center justify-content-lg-end",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-with-shadow",
                      attrs: { type: "button", "data-toggle": "modal" },
                      on: {
                        click: function ($event) {
                          _vm.isChooseTemplateModal = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.$t("choose_template")) +
                          "\n\t\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    _vm.textEditorReboot
                      ? _c("app-input", {
                          attrs: {
                            type: "text-editor",
                            required: true,
                            id: "text-editor-id",
                          },
                          model: {
                            value: _vm.formData.custom_content,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "custom_content", $$v)
                            },
                            expression: "formData.custom_content",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group text-center" },
                      _vm._l(_vm.all_tags, function (tag) {
                        return _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-primary px-3 py-1 mr-2 mt-4",
                            attrs: {
                              type: "button",
                              "data-toggle": "tooltip",
                              "data-placement": "top",
                              title: tag.description,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addTag(tag.tag)
                              },
                            },
                          },
                          [_vm._v(_vm._s(tag.tag) + "\n\t\t\t\t\t\t\t\t\t")]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveAsTemplate($event)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "w-100" },
                    [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.loading
                    ? [_vm._v(_vm._s(_vm.$t("save_as_template")))]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveAndSend($event)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.$t("save_and_send")) +
                      "\n\t\t\t\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary mr-2",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    onclick: "window.history.back()",
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.$t("cancel")) +
                      "\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.isCheckMailModalActive
        ? _c("app-check-email-deliver", {
            attrs: { "header-title": _vm.$t("send_proposal") },
            on: { "close-modal": _vm.closeModalCheckMail },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSendProposalModalActive
        ? _c("app-send-proposal-modal", {
            attrs: {
              "table-id": _vm.tableIdSendProposal,
              "template-data": _vm.formData,
            },
            on: { "close-modal": _vm.closeSendProposalModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isChooseTemplateModal
        ? _c("app-choose-template-modal", {
            attrs: { "table-id": _vm.tableId, modalId: _vm.tableId },
            on: {
              selected: _vm.setContent,
              close: function ($event) {
                _vm.isChooseTemplateModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }