var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "form",
        staticClass: "mb-0",
        attrs: {
          "data-url": _vm.route("deal.sync-file", { id: _vm.props.id }),
          enctype: "multipart/form-data",
        },
      },
      [
        _c("app-input", {
          attrs: { type: "dropzone", required: true },
          model: {
            value: _vm.files,
            callback: function ($$v) {
              _vm.files = $$v
            },
            expression: "files",
          },
        }),
        _vm._v(" "),
        Object.values(_vm.errors).length > 0
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("you_can_not_upload_a_file_larger_than_5_MB")) +
                  "\n      "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pt-primary px-primary border-top mx-minus-primary" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  disabled: _vm.props.status ? false : true,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitData($event)
                  },
                },
              },
              [
                _vm.loading
                  ? _c(
                      "span",
                      { staticClass: "w-100" },
                      [_c("app-submit-button-loader")],
                      1
                    )
                  : [_vm._v(_vm._s(_vm.$t("save")))],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("cancel")))]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }