var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("smart-select", {
    key: "smart-select-filter",
    attrs: { data: _vm.$props },
    on: { input: _vm.changed },
    model: {
      value: _vm.searchAndSelect,
      callback: function ($$v) {
        _vm.searchAndSelect = $$v
      },
      expression: "searchAndSelect",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }