var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.rowData.status.name == "status_draft" ||
    (!this.$can("manage_public_access") && this.$can("client_access"))
      ? _c("div", [
          _c(
            "span",
            { class: "badge badge-pill badge-" + _vm.rowData.status.class },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.rowData.status.translated_name)
              ),
            ]
          ),
        ])
      : _c("div", { staticClass: "dropdown d-inline-block btn-dropdown" }, [
          _c(
            "button",
            {
              class:
                "btn dropdown-toggle ml-0 mr-2 py-1 px-2 btn-outline-" +
                _vm.rowData.status.class,
              attrs: {
                "aria-expanded": "false",
                "aria-haspopup": "true",
                "data-toggle": "dropdown",
                type: "button",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.rowData.status.translated_name) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-menu" }, [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeStatus(_vm.rowData, "status_accepted")
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("accepted")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeStatus(_vm.rowData, "status_rejected")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("status_rejected")) + "\n            ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeStatus(_vm.rowData, "status_no_reply")
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("status_no_reply")) +
                    "\n            "
                ),
              ]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }