var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", [
    _c(
      "ul",
      {
        staticClass:
          "pagination justify-content-center justify-content-md-end mb-0",
      },
      [
        _c(
          "li",
          {
            staticClass: "page-item",
            class: { disabled: _vm.activePage <= 1 },
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link border-0",
                attrs: { href: "#", "aria-label": "Previous" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.prevArrow()
                  },
                },
              },
              [_c("app-icon", { attrs: { name: "arrow-left" } })],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.paginationPages, function (page, index) {
          return page + _vm.addition <= _vm.totalPage
            ? _c("li", { key: index, staticClass: "page-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "page-link border-0",
                    class: {
                      "active disabled": _vm.activePage === page + _vm.addition,
                    },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.activated(page + _vm.addition)
                      },
                    },
                  },
                  [_vm._v(_vm._s(page + _vm.addition))]
                ),
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "page-item",
            class: { disabled: _vm.activePage >= _vm.totalPage },
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link border-0 align-content-center",
                attrs: { href: "#", "aria-label": "Next" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.nextArrow()
                  },
                },
              },
              [
                _c("app-icon", {
                  staticClass: "menu-arrow",
                  attrs: { name: "arrow-right" },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }