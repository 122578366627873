var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media d-flex align-items-center" }, [
    _c(
      "div",
      { staticClass: "avatars-w-50" },
      [
        _c("app-avatar", {
          attrs: {
            "alt-text": _vm.rowData.name,
            img: _vm.rowData.profile_picture
              ? _vm.urlGenerator(_vm.rowData.profile_picture.path)
              : _vm.rowData.profile_picture,
            shadow: true,
            title: _vm.rowData.name,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "media-body ml-3" },
      [
        _vm.data == "person"
          ? _c(
              "a",
              {
                attrs: {
                  href: _vm.urlGenerator(
                    _vm.route("persons.edit", { id: _vm.rowData.id })
                  ),
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.rowData.name) + "\n        "
                ),
              ]
            )
          : [
              _vm.$can("update_organizations")
                ? [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.urlGenerator(
                            _vm.route("organizations.edit", {
                              id: _vm.rowData.id,
                            })
                          ),
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.rowData.name) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                : [_c("span", [_vm._v(" " + _vm._s(_vm.rowData.name) + " ")])],
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }