var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-file" }, [
    _c(
      "input",
      _vm._g(
        {
          staticClass: "custom-file-input",
          attrs: {
            type: "file",
            id: _vm.inputFieldId,
            disabled: _vm.data.disabled,
          },
        },
        _vm.listeners
      )
    ),
    _vm._v(" "),
    _c(
      "label",
      { staticClass: "custom-file-label", attrs: { for: _vm.inputFieldId } },
      [_vm._v("\n        " + _vm._s(_vm.fileLabel) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }