var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("import_pipeline"),
          directory: [_vm.$t("pipelines"), _vm.$t("import_pipeline")],
          icon: "users",
          button: {
            label: _vm.$t("back_to_pipeline"),
            url: _vm.urlGenerator("/crm/pipelines/list/view"),
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card border-0 card-with-shadow" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0",
            attrs: {
              "data-url": "crm/pipeline-import",
              enctype: "multipart/form-data",
            },
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "note-title d-flex" },
                    [
                      _c("app-icon", { attrs: { name: "book-open" } }),
                      _vm._v(" "),
                      _c("h6", { staticClass: "card-title pl-2" }, [
                        _vm._v(_vm._s(_vm.$t("csv_format_guideline"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "note note-warning p-4 mb-5" }, [
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_1"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_2"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_3"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("span", { staticClass: "my-2" }, [
                      _vm._v(_vm._s(_vm.$t("csv_download_label"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.sampleDownload($event)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("download")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("app-input", {
                          attrs: { type: "dropzone", maxFiles: 1 },
                          model: {
                            value: _vm.files,
                            callback: function ($$v) {
                              _vm.files = $$v
                            },
                            expression: "files",
                          },
                        }),
                        _vm._v(" "),
                        _vm.check(_vm.errors).isArray()
                          ? _vm._l(_vm.errors, function (error) {
                              return _c(
                                "small",
                                { staticClass: "text-danger" },
                                [
                                  _vm._l(error, function (er) {
                                    return [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(er) +
                                          "\n                        "
                                      ),
                                      _c("br"),
                                    ]
                                  }),
                                ],
                                2
                              )
                            })
                          : [
                              _vm.errors.import_file
                                ? _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(_vm.errors.import_file[0])),
                                  ])
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer p-primary bg-transparent" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.submitData($event)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "w-100" },
                [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                1
              ),
              _vm._v(" "),
              !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary mr-4",
              attrs: { type: "button", "data-dismiss": "modal" },
              on: { click: _vm.closeModal },
            },
            [_vm._v(_vm._s(_vm.$t("cancel")) + "\n        ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }