var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _vm.$can("update_pipelines")
        ? [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.route("deals_pipeline.page", {
                    _query: { pipeline: _vm.rowData.id },
                  }),
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.rowData.name) + "\n        "
                ),
              ]
            ),
          ]
        : [_c("span", [_vm._v(_vm._s(_vm.rowData.name))])],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }