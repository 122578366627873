var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.dataLoaded
        ? _c("app-overlay-loader")
        : _c(
            "div",
            {
              staticClass: "content-wrapper",
              class: { "deal-closed": !_vm.dealStatus },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c("app-breadcrumb", {
                      attrs: {
                        button: {
                          label: _vm.$t("back"),
                          url: _vm.route("deals.lists"),
                        },
                        directory: [_vm.$t("deals"), _vm.formData.title],
                        icon: "clipboard",
                        "page-title": _vm.formData.title,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.$can("proposal_send_deal_person")
                  ? _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                      _vm.dealStatus
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "float-md-right mb-3 mb-sm-3 mb-md-0",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary btn-with-shadow",
                                  attrs: {
                                    "data-toggle": "modal",
                                    type: "button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openSendProposalModal()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.$t("send_proposal")) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("deal-contact-person", {
                attrs: {
                  dealPerson: _vm.formData,
                  "lead-type": _vm.formData.lead_type == 1 ? "person" : "org",
                },
                on: { "update-request": _vm.dealUpdateRequest },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xl-4 mb-primary" },
                  [
                    _vm.dataLoaded
                      ? _c("app-deal-detail", {
                          attrs: {
                            dealDetails: _vm.formData,
                            ownerList: _vm.ownerList,
                            statusList: _vm.statusList,
                            organizationList: _vm.organizationList,
                            personList: _vm.personList,
                            "component-type":
                              _vm.formData.lead_type == 1
                                ? "persons"
                                : "organizations",
                          },
                          on: { "update-request": _vm.dealUpdateRequest },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dataLoaded
                      ? _c("app-details-contact-info", {
                          attrs: {
                            "contact-info-data": _vm.formData.contact_person[0],
                            "contact-info-sync-url": _vm.formData.contact_person
                              .length
                              ? _vm.route("persons.sync-contact", {
                                  id: _vm.formData.contact_person[0].id,
                                })
                              : "",
                            "person-id": _vm.formData.contact_person.length
                              ? _vm.formData.contact_person[0].id
                              : null,
                            "status-check": _vm.dealStatus,
                          },
                          on: { "update-request": _vm.dealUpdateRequest },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dataLoaded && _vm.isComponent
                      ? _c("details-page-custom-field", {
                          attrs: {
                            "status-check": _vm.dealStatus,
                            "component-type": "deal",
                            "form-data": _vm.formData,
                            "update-url": _vm.route("deals.update", {
                              id: _vm.formData.id,
                            }),
                          },
                          on: { "update-request": _vm.dealUpdateRequest },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("details-tag-manager", {
                      staticClass: "mb-primary",
                      attrs: {
                        "post-url": _vm.route("deal.attach-tag", {
                          id: _vm.formData.id,
                        }),
                        "taggable-id": _vm.formData.id,
                        tagData: _vm.formData.tags,
                      },
                    }),
                    _vm._v(" "),
                    _vm.dataLoaded
                      ? _c("app-follower-details", {
                          staticClass: "mb-primary",
                          attrs: {
                            "follower-data": _vm.formData,
                            "follower-sync-url": _vm.route(
                              "deal.sync-followers",
                              { id: _vm.formData.id }
                            ),
                            "get-follower-url": _vm.route(
                              "deal.get-followers",
                              { id: _vm.formData.id }
                            ),
                            "people-list": _vm.personList,
                            "permission-check": _vm.syncPermission,
                            "status-check": _vm.dealStatus,
                          },
                          on: { "update-request": _vm.dealUpdateRequest },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dataLoaded &&
                    Object.values(_vm.groupByParticipant).length > 0
                      ? _c("app-deal-participation-info", {
                          attrs: {
                            activityParticipations: _vm.groupByParticipant,
                            participantsDeal: _vm.formData,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dataLoaded &&
                    Object.values(_vm.groupByCollaborator).length > 0
                      ? _c("app-deal-activity-collaborator", {
                          attrs: {
                            activityCollaborators: _vm.groupByCollaborator,
                            collaboratorsDeal: _vm.formData,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xl-8" },
                  [
                    _vm.appTapShow
                      ? _c("app-tab", {
                          staticClass: "mb-primary",
                          attrs: {
                            tabs: _vm.todoActivityTab,
                            type: "horizontal",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isComponent && _vm.activityStatusList.length
                      ? _c("app-common-activity-show", {
                          attrs: {
                            "activity-status": _vm.activityStatusList,
                            Data: _vm.formData,
                            "activity-filter-url": _vm.route(
                              "deal.view-activities",
                              { id: _vm.formData.id }
                            ),
                            "edit-url": _vm.selectedUrl,
                            "file-filter-url": _vm.route("deal.get-file", {
                              id: _vm.formData.id,
                            }),
                            "note-filter-url": _vm.route("deal.get-note", {
                              id: _vm.formData.id,
                            }),
                            "component-type": "deal",
                          },
                          on: {
                            "open-activity": _vm.editActivity,
                            "open-note-modal": _vm.editNote,
                            "update-data": _vm.groupByCollaboratorParticipant,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.isCheckMailModalActive
                ? _c("app-check-email-deliver", {
                    attrs: { "header-title": _vm.$t("send_proposal") },
                    on: { "close-modal": _vm.closeModalCheckMail },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isSendProposalModalActive
                ? _c("deal-send-proposal-modal", {
                    attrs: {
                      "deal-data": _vm.formData,
                      "template-list": _vm.templateList,
                    },
                    on: { "close-modal": _vm.closeSendProposalModal },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.dealActivitiesModal
                ? _c("app-deal-activities-modal", {
                    attrs: {
                      activity: _vm.rowData,
                      "selected-url": _vm.editedUrl,
                    },
                    on: { "close-modal": _vm.closeDealActivitiesModal },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.dealNoteModal
                ? _c("app-common-note-modal", {
                    attrs: { note: _vm.noteRowData },
                    on: { "close-modal": _vm.closeDealNoteModal },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }