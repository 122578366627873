var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
            _c(
              "ol",
              {
                staticClass:
                  "breadcrumb p-0 d-flex align-items-center mb-primary",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "breadcrumb-item page-header d-flex align-items-center",
                  },
                  [
                    _c("h4", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("user_and_role")) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("app-icon", {
                      staticClass: "ml-2 page-icon",
                      attrs: { name: "user" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("user_and_role")) +
                      "\n                    "
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c("div", { staticClass: "float-md-right mb-3 mb-sm-3 mb-md-0" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-with-shadow mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.inviteUserOpenModal()
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("invite_user")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-5" }, [_c("app-users")], 1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-7" },
          [
            _c("app-roles", {
              attrs: { roleModal: _vm.isRoleModalActive },
              on: {
                roleModalHide: function ($event) {
                  _vm.isRoleModalActive = null
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isInviterOpenModalActive
        ? _c("app-user-invite-modal", {
            attrs: {
              "check-email-delivery": _vm.checkEmailDelivery,
              tableId: _vm.userTable,
            },
            on: { "close-modal": _vm.closeInviteModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }