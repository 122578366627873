var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow mb-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center justify-content-between p-primary bg-transparent",
        },
        [
          _c("h5", { staticClass: "card-title text-muted m-0" }, [
            _vm._v(
              _vm._s(_vm.$t("participants")) +
                "(" +
                _vm._s(_vm.participantData.length) +
                ")"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _vm.participantData.length
            ? [
                _vm._l(_vm.participantData, function (participant, index) {
                  return index < 3
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "media d-flex align-items-center mb-3",
                          },
                          [
                            _c("app-avatar", {
                              staticClass: "mr-2",
                              attrs: {
                                title: participant[0].name,
                                "avatar-class": "avatars-w-40",
                                img: participant[0].profile_picture
                                  ? _vm.urlGenerator(
                                      participant[0].profile_picture.path
                                    )
                                  : participant[0].profile_picture,
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "media-body" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(participant[0].name) +
                                  "\n            "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e()
                }),
              ]
            : [_vm._v("\n      " + _vm._s(_vm.$t("not_added_yet")) + "\n    ")],
          _vm._v(" "),
          Object.keys(_vm.activityParticipations).length > 3
            ? _c("div", { staticClass: "d-flex justify-content-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.viewAll()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("view_all")) + "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.viewAllParticipants
            ? _c("app-deal-participant", {
                attrs: { "participant-url": _vm.participantUrl },
                on: { "close-modal": _vm.closedViewModal },
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }