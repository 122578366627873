var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow mb-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center justify-content-between p-primary bg-transparent",
        },
        [
          _c("h5", { staticClass: "card-title text-muted m-0" }, [
            _vm._v(_vm._s(_vm.$t("organization_info"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          Object.keys(_vm.organizationInfo.organizations).length > 0
            ? [
                _vm._l(
                  _vm.organizationInfo.organizations,
                  function (organization, index) {
                    return [
                      _c(
                        "div",
                        { staticClass: "media d-flex align-items-center mb-3" },
                        [
                          _c("app-avatar", {
                            staticClass: "mr-2",
                            attrs: {
                              img: organization.profile_picture
                                ? _vm.urlGenerator(
                                    organization.profile_picture.path
                                  )
                                : organization.profile_picture,
                              title: organization.name,
                              "avatar-class": "avatars-w-40",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "media-body" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(organization.name) +
                                "\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  }
                ),
              ]
            : [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("no_organization_added")) +
                    "\n        "
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }