var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper calendar-position-modified" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.$t("proposal_list"),
                directory: [_vm.$t("proposals"), _vm.$t("list")],
                icon: "hexagon",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c("div", { staticClass: "float-md-right d-flex" }, [
            _c(
              "span",
              { staticClass: "btn-filter proposal-view-toggle mr-2 d-flex" },
              [
                _c(
                  "span",
                  {
                    staticClass: "btn btn-sm",
                    class: { active: _vm.viewController },
                    on: {
                      click: function ($event) {
                        _vm.viewController = true
                      },
                    },
                  },
                  [_c("app-icon", { attrs: { name: "columns" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "btn btn-sm",
                    class: { active: !_vm.viewController },
                    on: {
                      click: function ($event) {
                        _vm.viewController = false
                      },
                    },
                  },
                  [_c("app-icon", { attrs: { name: "trello" } })],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.$can("create_proposals")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-with-shadow",
                    attrs: { type: "button", "data-toggle": "modal" },
                    on: {
                      click: function ($event) {
                        return _vm.sendProposal()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("new_proposal")) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.viewController
        ? _c("app-proposals-list-view")
        : _c("app-proposal-kanban"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }