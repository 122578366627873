var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "note-modal",
        "modal-size": "large",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(_vm.note.id ? _vm.$t("edit") : _vm.$t("add")) +
              " " +
              _vm._s(_vm.$t("note_lowercase"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _vm.dataLoaded
        ? _c("app-overlay-loader")
        : _c("template", { slot: "body" }, [
            _c(
              "form",
              {
                ref: "form",
                staticClass: "mb-0",
                attrs: {
                  "data-url": _vm.route("activities.update-note", {
                    id: _vm.note.id,
                  }),
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("app-input", {
                      attrs: { type: "text-editor", id: "editNote" },
                      model: {
                        value: _vm.formData.note,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "note", $$v)
                        },
                        expression: "formData.note",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("save")) + "\n        ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }