var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card border-0 card-with-shadow mb-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center justify-content-between p-primary bg-transparent",
        },
        [
          _c("h5", { staticClass: "card-title text-muted m-0" }, [
            _vm._v(_vm._s(_vm.$t("collaborators"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _vm.collaboratorsData.length
            ? [
                _vm._l(_vm.collaboratorsData, function (collaborator, index) {
                  return index < 3
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "media d-flex align-items-center mb-3",
                          },
                          [
                            _c("app-avatar", {
                              staticClass: "mr-2",
                              attrs: {
                                img: collaborator[0].profile_picture
                                  ? _vm.urlGenerator(
                                      collaborator[0].profile_picture.path
                                    )
                                  : collaborator[0].profile_picture,
                                title: collaborator[0].full_name,
                                "avatar-class": "avatars-w-40",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "media-body" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(collaborator[0].full_name) +
                                  "\n                    "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e()
                }),
              ]
            : [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("not_added_yet")) +
                    "\n        "
                ),
              ],
          _vm._v(" "),
          Object.keys(_vm.activityCollaborators).length > 3
            ? _c("div", { staticClass: "d-flex justify-content-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { "data-dismiss": "modal", type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.viewAll()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("view_all")) +
                        "\n            "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.viewAllCollaborator
            ? _c("app-deal-collaborator", {
                attrs: { "collaborator-url": _vm.collaboratorUrl },
                on: { "close-modal": _vm.closedViewModal },
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }