var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step-input-selector d-flex justify-content-around" },
    _vm._l(_vm.stepLists, function (list, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass:
            "step-input d-flex align-items-center justify-content-center",
          class: [
            { selected: index < _vm.doneIndex },
            { current: index == _vm.doneIndex - 1 },
            { "mr-1": index < _vm.stepLists.length - 1 },
            { once: list["label"] },
          ],
          attrs: {
            "data-toggle": "tooltip",
            title: list.name,
            "data-placement": "top",
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.addSelectedSteps(index)
            },
          },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showLabel,
                  expression: "showLabel",
                },
              ],
              staticClass: "text-truncate",
            },
            [
              _vm._v(
                _vm._s(
                  index < _vm.doneIndex
                    ? list.label
                    : list["label"]
                    ? list.label
                    : list.name
                )
              ),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }