var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customized-checkbox", class: _vm.data.customCheckboxType },
    [
      _c(
        "input",
        _vm._g(
          {
            attrs: {
              type: "checkbox",
              name: _vm.name,
              id: _vm.inputFieldId,
              disabled: _vm.data.disabled,
            },
            domProps: { checked: _vm.value, value: _vm.value },
          },
          _vm.listeners
        )
      ),
      _vm._v(" "),
      _c(
        "label",
        { class: _vm.data.labelClass, attrs: { for: _vm.inputFieldId } },
        [_vm._v("\n        " + _vm._s(_vm.data.listValueField) + "\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }