var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-5" }, [
    _c("h2", { staticClass: "text-center text-capitalize mb-primary" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.$t("install")) +
          " " +
          _vm._s(_vm.appName) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card card-with-shadow border-0 mb-primary" }, [
      _c("div", { staticClass: "card-header bg-transparent p-primary" }, [
        _c(
          "h5",
          { staticClass: "card-title mb-0 d-flex align-items-center " },
          [
            _c("app-icon", {
              staticClass: "primary-text-color mr-2",
              attrs: { name: "user" },
            }),
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("admin_login_details")) +
                "\n            "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("app-note", {
                attrs: {
                  title: _vm.$t("password_requirements"),
                  notes: [_vm.$t("password_requirements_message")],
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("name")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("name"),
                  "error-message": _vm.$errorMessage(_vm.errors, "full_name"),
                },
                model: {
                  value: _vm.environment.full_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "full_name", $$v)
                  },
                  expression: "environment.full_name",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("email")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("email"),
                  "error-message": _vm.$errorMessage(_vm.errors, "email"),
                },
                model: {
                  value: _vm.environment.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "email", $$v)
                  },
                  expression: "environment.email",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("password")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("password"),
                  "error-message": _vm.$errorMessage(_vm.errors, "password"),
                },
                model: {
                  value: _vm.environment.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "password", $$v)
                  },
                  expression: "environment.password",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group mt-5" },
      [
        _c("app-submit-button", {
          attrs: {
            loading: _vm.loading,
            "btn-class": "btn-block",
            label: _vm.$t("save_&_next"),
          },
          on: { click: _vm.submitData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }