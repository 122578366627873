var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid p-0" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-8" }, [
        _c("div", {
          staticClass: "back-image",
          style:
            "background-image: url(" +
            _vm.urlGenerator(_vm.configData.company_banner) +
            ")",
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-4 pl-0" }, [
        _c("div", { staticClass: "login-form d-flex align-items-center" }, [
          _c(
            "form",
            {
              ref: "form",
              staticClass: "sign-in-sign-up-form w-100",
              attrs: { "data-url": "users/password-reset" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "text-center mb-4" }, [
                _c("img", {
                  staticClass: "img-fluid logo",
                  attrs: {
                    src:
                      "" +
                      (_vm.configData.company_logo
                        ? _vm.urlGenerator(_vm.configData.company_logo)
                        : _vm.urlGenerator("/images/core.png")),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12" }, [
                  _c("label", { staticClass: "text-center d-block" }, [
                    _vm._v(_vm._s(_vm.$t("reset_password"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              !_vm.successMessage
                ? [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-12" },
                        [
                          _c("label", [_vm._v(_vm._s(_vm.$t("email")))]),
                          _vm._v(" "),
                          _c("app-input", {
                            attrs: {
                              type: "email",
                              placeholder: _vm.$t("your_email"),
                              required: true,
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.submitData($event)
                              },
                            },
                            model: {
                              value: _vm.formData.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "email", $$v)
                              },
                              expression: "formData.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-12" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary btn-block text-center",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.submitData($event)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "w-100" },
                              [
                                _vm.loading
                                  ? _c("app-submit-button-loader")
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.loading
                              ? [_vm._v(_vm._s(_vm.$t("request")))]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "a",
                          {
                            staticClass: "bluish-text",
                            attrs: { href: _vm.urlGenerator("/") },
                            on: { click: _vm.backLogin },
                          },
                          [
                            _c("i", {
                              staticClass: "pr-2",
                              attrs: { "data-feather": "home" },
                            }),
                            _vm._v(
                              _vm._s(
                                _vm.$t("back_to_login", {
                                  destination: _vm.$t("login"),
                                })
                              ) + "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "form-group text-justify" }, [
                      _c("p", { staticClass: "reset-pass-message" }, [
                        _vm._v(_vm._s(_vm.successMessage)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-12" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary btn-block text-center",
                            attrs: { type: "button" },
                            on: { click: _vm.backLogin },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("thank_you")) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "p",
                    {
                      staticClass: "text-center footer-copy",
                      class: {
                        "mt-3": _vm.successMessage != "",
                        "mt-5": _vm.successMessage == "",
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.copyRightText()) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }