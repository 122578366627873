var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("settings"),
          directory: _vm.$t("settings"),
          icon: "settings",
        },
      }),
      _vm._v(" "),
      _vm.dataLoaded
        ? _c("app-tab", { attrs: { tabs: _vm.tabs, icon: _vm.tabIcon } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }