var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "horizontal-tab" }, [
    _c("div", { staticClass: "card card-with-shadow border-0" }, [
      _c("nav", [
        _c(
          "div",
          { staticClass: "nav nav-tabs" },
          _vm._l(_vm.filteredTab, function (tab, index) {
            return _c(
              "a",
              {
                key: index,
                staticClass: "nav-item p-primary text-capitalize",
                class: { active: _vm.currentIndex == index },
                attrs: {
                  "data-toggle": "tab",
                  href: "#" + tab.name + "-" + index,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.loadComponent(tab, index)
                  },
                },
              },
              [
                tab.icon
                  ? _c(
                      "span",
                      { staticClass: "mr-2" },
                      [_c("app-icon", { attrs: { name: tab.icon } })],
                      1
                    )
                  : _vm._e(),
                _vm._v(_vm._s(tab.name) + "\n                "),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tab-content p-primary" }, [
        _c(
          "div",
          {
            staticClass: "tab-pane fade show active",
            attrs: { id: _vm.componentId },
          },
          [
            _c(_vm.componentName, {
              tag: "component",
              attrs: { props: _vm.componentProps },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }