var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-primary d-none",
        attrs: {
          type: "button",
          "data-toggle": "modal",
          "data-target": "#gain-modal",
          id: "modalTrigger",
        },
      },
      [_vm._v("\n        Launch demo modal\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "gain-modal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "exampleModalCenterTitle",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "exampleModalLongTitle" },
                  },
                  [_vm._v("Modal")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close",
                    },
                    on: { click: _vm.closeModal },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("pre", [
                  _vm._v(_vm._s(JSON.stringify(_vm.actionData, null, 4))),
                ]),
                _vm._v(" "),
                _vm.action.type == "page"
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("small", { staticClass: "ml-3" }, [
                          _vm._v("Redirecting in another page..."),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.action.type !== "page"
                ? _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default mr-2",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: { click: _vm.closeModal },
                      },
                      [
                        _vm._v(
                          "\n                        cancel\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: { click: _vm.save },
                      },
                      [_vm._v("save")]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spinner-border", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }