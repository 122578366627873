var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.avatarClass }, [
    _vm.img
      ? _c("img", {
          staticClass: "rounded-circle",
          class: { "avatar-shadow": _vm.shadow, "avatar-bordered": _vm.border },
          attrs: {
            src: _vm.img,
            alt: _vm.altText ? _vm.altText : _vm.$t("not_found"),
          },
        })
      : _c(
          "div",
          {
            staticClass: "no-img rounded-circle",
            class: {
              "avatar-shadow": _vm.shadow,
              "avatar-bordered": _vm.border,
            },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("titleFilter")(_vm.title ? _vm.title : "AV")) +
                "\n    "
            ),
          ]
        ),
    _vm._v(" "),
    _vm.status
      ? _c("span", { staticClass: "status", class: "bg-" + _vm.status })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }