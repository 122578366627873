var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border-bottom mb-4 pb-4" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between mb-2" },
      [
        _c("p", { staticClass: "mb-2 font-weight-bold" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("lead_type")) +
              " :\n            " +
              _vm._s(
                _vm.formData.contextable_type ==
                  "App\\Models\\CRM\\Person\\Person"
                  ? _vm.$t("person")
                  : _vm.$t("organization")
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEditLeadType,
                  expression: "!isEditLeadType",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closeLeadTypeEdit($event)
                },
              },
            },
            [
              _c("app-icon", {
                staticClass: "size-20",
                attrs: { name: "x-square", "stroke-width": "1" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEditLeadType,
                  expression: "!isEditLeadType",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.updateLeadInfo($event)
                },
              },
            },
            [
              _c("app-icon", {
                staticClass: "size-20",
                attrs: { name: "check-square", "stroke-width": "1" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isEditLeadType && _vm.clientAccess && _vm.dealStatus,
                expression: "isEditLeadType && clientAccess && dealStatus",
              },
            ],
            staticClass: "text-muted",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.editLeadInfo($event)
              },
            },
          },
          [
            _c("app-icon", {
              staticClass: "size-20",
              attrs: { name: "edit", "stroke-width": "1" },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _vm.isEditLeadType
      ? _c("div", [
          _c(
            "div",
            { staticClass: "d-flex justify-content-start mb-2" },
            [
              _c("app-avatar", {
                attrs: {
                  "avatar-class": "avatars-w-20",
                  title: "John Doe",
                  img: "/images/profile.png",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text-muted font-size-90 mb-0 ml-2" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.formData.contextable
                        ? _vm.formData.contextable.name
                        : _vm.$t("no_lead_added")
                    ) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formData.lead_type == 2
            ? _c(
                "div",
                { staticClass: "d-flex justify-content-start" },
                [
                  _c("app-avatar", {
                    attrs: {
                      "avatar-class": "avatars-w-20",
                      title: "John Doe",
                      img: "/images/profile.png",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-muted font-size-90 mb-0 ml-2" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.formData.contact_person.length
                              ? _vm.formData.contact_person[0].name
                              : _vm.$t("no_contact")
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEditLeadType,
            expression: "!isEditLeadType",
          },
        ],
      },
      [
        _vm.formData.lead_type == 2
          ? [
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  { staticClass: "mb-0 col-4 d-flex align-items-center" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("lead")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        list: _vm.organizationList,
                        placeholder: _vm.$t("choose_one"),
                        "list-value-field": "name",
                        type: "select",
                      },
                      on: { input: _vm.resetPerson },
                      model: {
                        value: _vm.formData.contextable_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "contextable_id", $$v)
                        },
                        expression: "formData.contextable_id",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  { staticClass: "mb-0 col-4 d-flex align-items-center" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("contact_person")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "col-8": !_vm.formData.person_id,
                      "col-6":
                        _vm.formData.person_id && _vm.formData.lead_type == 2,
                    },
                  },
                  [
                    _c("app-input", {
                      attrs: {
                        list: _vm.personAsOrg,
                        placeholder: _vm.$t("choose_a_contact_person"),
                        "list-value-field": "name",
                        type: "select",
                      },
                      model: {
                        value: _vm.formData.person_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "person_id", $$v)
                        },
                        expression: "formData.person_id",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.formData.lead_type == 2 && _vm.formData.person_id
                  ? _c("div", { staticClass: "col-1" }, [
                      _c(
                        "a",
                        {
                          staticClass: "text-muted",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.detachContactPerson(_vm.formData.id)
                            },
                          },
                        },
                        [
                          _c("app-icon", {
                            attrs: {
                              name: "trash",
                              "stroke-width": "1",
                              width: "20",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          : [
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  { staticClass: "mb-0 col-4 d-flex align-items-center" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("lead")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        list: _vm.personList,
                        placeholder: _vm.$t("choose_one"),
                        "list-value-field": "name",
                        type: "select",
                      },
                      model: {
                        value: _vm.formData.contextable_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "contextable_id", $$v)
                        },
                        expression: "formData.contextable_id",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }