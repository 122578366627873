import { render, staticRenderFns } from "./ExpandableView.vue?vue&type=template&id=25720366&"
import script from "./ExpandableView.vue?vue&type=script&lang=js&"
export * from "./ExpandableView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\sites\\tm-crm.ru\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25720366')) {
      api.createRecord('25720366', component.options)
    } else {
      api.reload('25720366', component.options)
    }
    module.hot.accept("./ExpandableView.vue?vue&type=template&id=25720366&", function () {
      api.rerender('25720366', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/js/core/examples/datatable/ExpandableView.vue"
export default component.exports