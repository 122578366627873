var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "inviteModal",
        "modal-scroll": false,
        "modal-size": "large",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("invite_user"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.checkEmailDelivery != 1
            ? [
                _c("app-note", {
                  attrs: {
                    title: _vm.$t("no_email_settings_found"),
                    notes: _vm.note,
                    "content-type": "html",
                    "padding-class": "p-3",
                  },
                }),
              ]
            : [
                _c(
                  "form",
                  {
                    ref: "form",
                    attrs: { "data-url": "admin/auth/users/invite-user" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("email")))]),
                        _vm._v(" "),
                        _c("app-input", {
                          attrs: {
                            placeholder: _vm.$t("email"),
                            required: true,
                          },
                          model: {
                            value: _vm.invite.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.invite, "email", $$v)
                            },
                            expression: "invite.email",
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.email
                          ? _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.errors.email[0])),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("roles")))]),
                        _vm._v(" "),
                        _c("app-input", {
                          attrs: {
                            type: "search-select",
                            "list-value-field": "name",
                            list: _vm.roleList,
                            required: true,
                            placeholder: _vm.$t("select_one"),
                          },
                          model: {
                            value: _vm.invite.role,
                            callback: function ($$v) {
                              _vm.$set(_vm.invite, "role", $$v)
                            },
                            expression: "invite.role",
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.roles
                          ? _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.errors.roles[0])),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.checkEmailDelivery != 1
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-2",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("close")) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            : [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-2",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("close")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitData($event)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "w-100" },
                      [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.loading
                      ? [_vm._v(_vm._s(_vm.$t("invite")))]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }