var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-id": "send-invitation-modal",
        "modal-size": "default",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("send_invitation"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              "aria-label": "Close",
              "data-dismiss": "modal",
              type: "button",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.dataLoaded
            ? _c("app-overlay-loader")
            : _c(
                "div",
                { staticClass: "form-group row" },
                [
                  _vm.personEmail.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-0 col-sm-3 d-flex align-items-center",
                          },
                          [_c("label", [_vm._v(_vm._s(_vm.$t("email")))])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-9" },
                          [
                            _c("app-input", {
                              attrs: {
                                type: "select",
                                placeholder: _vm.$t("choice_an_email"),
                                list: _vm.personEmail,
                                required: true,
                              },
                              model: {
                                value: _vm.formData.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email", $$v)
                                },
                                expression: "formData.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "col-sm-12" },
                          [
                            _c("app-note", {
                              attrs: {
                                notes: _vm.$t("this_lead_has_no_email"),
                                "show-title": false,
                                "padding-class": "p-2",
                                title: "'send'",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { "data-dismiss": "modal", type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              disabled: !_vm.personEmail.length ? true : false,
              type: "button",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("send")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }