var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid p-0" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-8" }, [
        _c("div", {
          staticClass: "back-image",
          style:
            "background-image: url(" +
            _vm.urlGenerator(_vm.configData.company_banner) +
            ")",
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-4 pl-0" }, [
        _c("div", { staticClass: "login-form d-flex align-items-center" }, [
          _c(
            "form",
            {
              ref: "form",
              staticClass: "sign-in-sign-up-form w-100",
              attrs: { "data-url": "users/confirm" },
            },
            [
              _c("div", { staticClass: "text-center mb-4" }, [
                _c("img", {
                  staticClass: "img-fluid logo",
                  attrs: {
                    src:
                      "" +
                      (_vm.configData.company_logo
                        ? _vm.urlGenerator(_vm.configData.company_logo)
                        : _vm.urlGenerator("/images/core.png")),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12" }, [
                  _c("h6", { staticClass: "text-center mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("sign_up"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("first_name")))]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "first_name"
                        ),
                        placeholder: _vm.$t("first_name"),
                        required: true,
                        type: "text",
                      },
                      model: {
                        value: _vm.userData.first_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.userData, "first_name", $$v)
                        },
                        expression: "userData.first_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("last_name")))]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "last_name"
                        ),
                        placeholder: _vm.$t("last_name"),
                        required: true,
                        type: "text",
                      },
                      model: {
                        value: _vm.userData.last_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.userData, "last_name", $$v)
                        },
                        expression: "userData.last_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("email")))]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        disabled: true,
                        placeholder: _vm.$t("email"),
                        type: "text",
                      },
                      model: {
                        value: _vm.formData.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "email", $$v)
                        },
                        expression: "formData.email",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("password")))]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "password"
                        ),
                        placeholder: _vm.$t("your_password"),
                        required: true,
                        type: "password",
                      },
                      model: {
                        value: _vm.userData.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.userData, "password", $$v)
                        },
                        expression: "userData.password",
                      },
                    }),
                    _vm._v(" "),
                    _c("app-note", {
                      staticClass: "mt-2",
                      attrs: {
                        notes: [_vm.$t("password_hint_note")],
                        "show-title": false,
                        "note-type": "note-warning",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group col-12" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("confirm_password")))]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "password"
                        ),
                        placeholder: _vm.$t("confirm_password"),
                        required: true,
                        type: "password",
                      },
                      model: {
                        value: _vm.userData.password_confirmation,
                        callback: function ($$v) {
                          _vm.$set(_vm.userData, "password_confirmation", $$v)
                        },
                        expression: "userData.password_confirmation",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block text-center",
                      attrs: { type: "button" },
                      on: { click: _vm.submitData },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "w-100" },
                        [
                          _vm.loading
                            ? _c("app-submit-button-loader")
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.loading
                        ? [_vm._v(_vm._s(_vm.$t("sign_up")))]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("p", { staticClass: "text-center mt-5 footer-copy" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.copyRightText()) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }