var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { ref: "form" }, [
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", { staticClass: "d-block" }, [
          _vm._v(_vm._s(_vm.$t("mail_subject"))),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "text-muted font-size-90" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(
                  "this_field_will_be_used_as_the_email_subject_and_identify_the_template"
                )
              )
          ),
        ]),
        _vm._v(" "),
        _c("app-input", {
          attrs: { type: "text", required: true },
          model: {
            value: _vm.template.subject,
            callback: function ($$v) {
              _vm.$set(_vm.template, "subject", $$v)
            },
            expression: "template.subject",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v(_vm._s(_vm.$t("contents")))]),
        _vm._v(" "),
        _c("app-input", {
          attrs: {
            type: "text-editor",
            required: true,
            id: "text-editor-for-template",
            "text-editor-hints": _vm.textEditorHints(Object.keys(_vm.tags)),
          },
          model: {
            value: _vm.template.custom_content,
            callback: function ($$v) {
              _vm.$set(_vm.template, "custom_content", $$v)
            },
            expression: "template.custom_content",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group text-center" },
      _vm._l(_vm.all_tags, function (tag) {
        return _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary px-3 py-1 margin-left-2 mt-2",
            attrs: {
              type: "button",
              "data-toggle": "tooltip",
              "data-placement": "top",
              title: tag.description,
            },
            on: {
              click: function ($event) {
                return _vm.addTag(tag.tag)
              },
            },
          },
          [_vm._v(_vm._s(tag.tag))]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "float-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary mr-2",
          attrs: { type: "button", "data-dismiss": "modal" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeModal($event)
            },
          },
        },
        [_vm._v("\n          " + _vm._s(_vm.$t("cancel")) + "\n      ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.submitData($event)
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "w-100" },
            [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
            1
          ),
          _vm._v(" "),
          !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }