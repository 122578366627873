var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-with-shadow border border-0 py-primary" },
    [
      _c("div", { staticClass: "circular-progress-bar" }, [
        _c(
          "div",
          {
            staticClass: "progress",
            attrs: { "data-percentage": parseInt(_vm.data.number) },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "progress-value" }, [
              _c("h6", { staticClass: "m-0" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.data.number) +
                    "%\n                "
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("small", { staticClass: "mt-3 text-center d-block" }, [
          _vm._v(_vm._s(_vm.data.label)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progress-left" }, [
      _c("span", { staticClass: "progress-bar" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progress-right" }, [
      _c("span", { staticClass: "progress-bar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }