var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.emailPhoneData, function (item, index) {
        return index < 2
          ? [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c(
                  "span",
                  { staticClass: "mb-1 badge badge-round badge-light" },
                  [_vm._v(_vm._s(item.value))]
                ),
                _vm._v(" "),
                item.type
                  ? _c(
                      "span",
                      {
                        class:
                          "ml-1 py-1 px-2 badge badge-round badge-" +
                          item.type.class,
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.type.name) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
              ]),
              _vm._v(" "),
              _vm.emailPhoneData.length > 2 && index == 1
                ? _c(
                    "small",
                    {
                      staticClass: "cursor-pointer",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.viewAll($event)
                        },
                      },
                    },
                    [
                      _c("b", [
                        _vm._v(
                          "+" + _vm._s(_vm.emailPhoneData.length - 2) + " more"
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.isViewModalOpen
        ? _c("all-email-phone", {
            attrs: { "row-data": _vm.emailPhoneData },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }