var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar-view" },
    [
      _vm.preloader
        ? _c("app-overlay-loader")
        : _c("FullCalendar", { attrs: { options: _vm.calendarOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }