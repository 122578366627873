var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rowData.contextable != null
    ? _c(
        "div",
        { key: _vm.iconName, staticClass: "d-flex align-items-center" },
        [
          _c("app-icon", { attrs: { name: _vm.iconName } }),
          _vm._v(" "),
          _c("p", { staticClass: "mb-0 ml-1" }, [
            _vm._v(_vm._s(_vm.value.name)),
          ]),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center ml-1 font-size-90 text-secondary",
        },
        [_vm._v("\n  " + _vm._s(_vm.$t("deal_has_no_lead")) + "\n")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }