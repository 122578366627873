var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showTitle
      ? _c(
          "div",
          { staticClass: "note-title d-flex" },
          [
            _c("app-icon", { attrs: { name: _vm.noteIcon } }),
            _vm._v(" "),
            _c("h6", { staticClass: "card-title pl-2" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "note", class: [_vm.noteType, _vm.paddingClass] },
      [
        _vm._l(_vm.notesData, function (note, index) {
          return _vm.contentType === "string"
            ? _c("p", { key: index, staticClass: "m-1" }, [
                _vm._v(_vm._s(note)),
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _vm._l(_vm.notesData, function (note, index) {
          return _vm.contentType === "html"
            ? _c("p", {
                key: index,
                staticClass: "m-1",
                domProps: { innerHTML: _vm._s(note) },
              })
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }