var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "app-modal",
        {
          attrs: {
            "modal-id": "manage-user",
            "modal-scroll": _vm.modalScroll,
            "modal-size": "default",
            "modal-alignment": "top",
          },
          on: { "close-modal": _vm.closeManageUserModal },
        },
        [
          _c("template", { slot: "header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.$t("manage_users"))),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close outline-none",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
              },
              [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
            ),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "body" },
            [
              _vm.preloader
                ? _c("app-overlay-loader")
                : [
                    _vm._l(_vm.user_list, function (user, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "d-flex align-items-center justify-content-between",
                          class: {
                            "pb-3 mb-3 border-bottom":
                              index !== _vm.user_list.length - 1,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "media d-flex align-items-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "avatars-w-50 ml-2" },
                                [
                                  _c("app-avatar", {
                                    attrs: {
                                      title: user.full_name,
                                      status: "success",
                                      shadow: true,
                                      img: user.profile_picture
                                        ? user.profile_picture.path
                                        : user.profile_picture,
                                      "alt-text": user.full_name,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "media-body ml-3" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(user.full_name) +
                                    "\n                            "
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-muted font-size-90 mb-0",
                                  },
                                  [_vm._v(_vm._s(user.email))]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        ref: "form",
                        attrs: {
                          "data-url": _vm.route("roles.attach_app_users_to", {
                            id: _vm.role.id,
                          }),
                        },
                      },
                      [
                        _vm.role.name != "Client"
                          ? _c(
                              "div",
                              { staticClass: "mt-primary" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "multi-select",
                                    list: _vm.attachableUsers,
                                    "list-value-field": "full_name",
                                    required: true,
                                    "is-animated-dropdown": true,
                                  },
                                  model: {
                                    value: _vm.formData.users,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "users", $$v)
                                    },
                                    expression: "formData.users",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.errors.users
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "text-danger",
                                        staticStyle: { "font-size": "12px" },
                                      },
                                      [_vm._v(_vm._s(_vm.errors.users[0]))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.role.is_admin
                      ? [
                          _c("app-note", {
                            attrs: {
                              title: _vm.$t("note"),
                              notes: _vm.$t(
                                "you_can_add_more_users_in_this_app_admin_role_but_you_cant_remove_the_app_admin_users"
                              ),
                              "content-type": "html",
                              "padding-class": "p-3",
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.role.name == "Client"
                      ? _c(
                          "div",
                          { staticClass: "mt-5" },
                          [
                            _c("app-note", {
                              attrs: {
                                title: _vm.$t("note"),
                                notes: _vm.$t(
                                  "you_can_manage_invite_client_user_from_people_module"
                                ),
                                "content-type": "html",
                                "padding-class": "p-3",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _vm.role.name != "Client"
            ? _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-2",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeManageUserModal($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("cancel")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitData($event)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "w-100" },
                      [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }