var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "mb-3" }, [
      _c("h4", [_vm._v(_vm._s(_vm.$t("download")))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.value, function (val) {
        return _c("div", { staticClass: "col-2 mb-primary" }, [
          _c("a", { attrs: { href: _vm.exportUrl + "/" + val } }, [
            _c(
              "div",
              { staticClass: "card card-with-shadow border-0 file-card" },
              [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c(
                    "div",
                    { staticClass: "icon-box mx-auto mb-primary" },
                    [_c("app-icon", { attrs: { name: "download" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "default-font-color mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("sheet")) + " - " + _vm._s(val)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-size-90 mb-0" }, [
                    _c("span", { staticClass: "default-font-color" }, [
                      _vm._v(_vm._s(_vm.$t("rows")) + ":"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v(
                        _vm._s(val * _vm.sheetNumber - _vm.perSheet + 1) +
                          " - " +
                          _vm._s(val * _vm.perSheet)
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }