var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.stages, function (stage) {
        return [
          stage.id == _vm.formData.stage_id
            ? _c(
                "div",
                { key: stage.id, staticClass: "border-bottom mb-4 pb-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between",
                    },
                    [
                      _c("p", { staticClass: "mb-2 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("stage"))),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isEditableData,
                                expression: "!isEditableData",
                              },
                            ],
                            staticClass: "text-muted",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.closeEdit($event)
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "size-20",
                              attrs: { name: "x-square", "stroke-width": "1" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isEditableData,
                                expression: "!isEditableData",
                              },
                            ],
                            staticClass: "text-muted",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.updateStage($event)
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "size-20",
                              attrs: {
                                name: "check-square",
                                "stroke-width": "1",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.isEditableData &&
                                _vm.clientAccess &&
                                _vm.dealStatus,
                              expression:
                                "isEditableData && clientAccess && dealStatus",
                            },
                          ],
                          staticClass: "text-muted",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.editStage($event)
                            },
                          },
                        },
                        [
                          _c("app-icon", {
                            staticClass: "size-20",
                            attrs: { name: "edit", "stroke-width": "1" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEditableData,
                          expression: "isEditableData",
                        },
                      ],
                      staticClass: "mb-0 font-size-90 text-muted",
                    },
                    [
                      _c("app-icon", {
                        staticClass: "text-primary size-15",
                        attrs: { name: "trello" },
                      }),
                      _vm._v(
                        "\n                " +
                          _vm._s(stage.name) +
                          "\n            "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEditableData,
                          expression: "!isEditableData",
                        },
                      ],
                      staticClass: "col-sm-12 p-0",
                    },
                    [
                      _c("app-input", {
                        attrs: {
                          type: "select",
                          "list-value-field": "name",
                          placeholder: _vm.$t("choose_a_stage"),
                          list: _vm.stages,
                          required: true,
                        },
                        model: {
                          value: _vm.formData.stage_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "stage_id", $$v)
                          },
                          expression: "formData.stage_id",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }