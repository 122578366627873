var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      style: "cursor: url(" + _vm.cursorImage() + "), auto !important",
      attrs: {
        id: _vm.modalId,
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              {
                staticClass:
                  "modal-body d-flex flex-column justify-content-center modal-alert",
              },
              [
                _vm.loading
                  ? _c("app-pre-loader")
                  : [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("app-icon", {
                            class:
                              "text-" +
                              (_vm.modalClass ? _vm.modalClass : "danger"),
                            attrs: { name: _vm.icon ? _vm.icon : "x-circle" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "h5",
                        { staticClass: "text-center font-weight-bold mt-4" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.title ? _vm.title : _vm.$t("are_you_sure")
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.subTitle
                        ? _c(
                            "p",
                            { staticClass: "text-center font-size-90 m-0 p-0" },
                            [_vm._v(_vm._s(_vm.subTitle))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-primary text-center font-size-90 p-0",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.message
                                ? _vm.message
                                : _vm.$t(
                                    "this_content_will_be_deleted_permanently"
                                  )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-secondary mr-2",
                            attrs: { href: "#", "data-dismiss": "modal" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.cancelled($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.secondButtonName
                                    ? _vm.secondButtonName
                                    : _vm.$t("no")
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn",
                            class:
                              "btn-" +
                              (_vm.modalClass ? _vm.modalClass : "danger"),
                            attrs: {
                              href: "#",
                              "data-dismiss": _vm.selfClose ? "modal" : "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.confirmed($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.firstButtonName
                                    ? _vm.firstButtonName
                                    : _vm.$t("yes")
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ],
              ],
              2
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }