var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    _vm._g(
      {
        ref: "select",
        class: "custom-select" + _vm.data.inputClass,
        style: "backgroundImage: url(" + _vm.downArrow() + ")",
        attrs: { id: _vm.inputFieldId, disabled: _vm.data.disabled },
        domProps: { value: _vm.value },
      },
      _vm.listeners
    ),
    [
      _vm.data.placeholder
        ? _c("option", { attrs: { disabled: "", value: "", hidden: "" } }, [
            _vm._v("\n    " + _vm._s(_vm.data.placeholder) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.data.list, function (item, index) {
        return _c(
          "option",
          {
            key: index,
            attrs: { disabled: item.disabled },
            domProps: { value: item.id, selected: item.id === _vm.value },
          },
          [_vm._v("\n    " + _vm._s(item[_vm.data.listValueField]) + "\n  ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }