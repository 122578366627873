var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          button: { label: _vm.$t("back_to_organizations") },
          directory: [_vm.$t("contacts"), _vm.$t("import_organizations")],
          icon: "users",
          "page-title": _vm.$t("import_organizations"),
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card border-0 card-with-shadow" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0",
            attrs: {
              "data-url": _vm.route("organization.import-store"),
              enctype: "multipart/form-data",
            },
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "note-title d-flex" },
                    [
                      _c("app-icon", { attrs: { name: "book-open" } }),
                      _vm._v(" "),
                      _c("h6", { staticClass: "card-title pl-2" }, [
                        _vm._v(_vm._s(_vm.$t("csv_format_guideline"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "note note-warning p-4 mb-5" }, [
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_1"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_2"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_3"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_4"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_5"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "my-1" }, [
                      _vm._v("- " + _vm._s(_vm.$t("csv_guideline_7"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-sm-row align-items-sm-center mb-3",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("csv_download_label")) +
                          "\n              "
                      ),
                      _c("app-input", {
                        staticClass: "mx-sm-3",
                        attrs: {
                          "label-class": "mb-0",
                          list: [
                            { id: 1, value: _vm.$t("include_custom_fields") },
                          ],
                          type: "checkbox",
                        },
                        model: {
                          value: _vm.sampleFileType,
                          callback: function ($$v) {
                            _vm.sampleFileType = $$v
                          },
                          expression: "sampleFileType",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.sampleDownload($event)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("download")))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("app-input", {
                        attrs: { maxFiles: 1, type: "dropzone" },
                        model: {
                          value: _vm.files,
                          callback: function ($$v) {
                            _vm.files = $$v
                          },
                          expression: "files",
                        },
                      }),
                      _vm._v(" "),
                      _vm.stat.errors > 0
                        ? _c("div", { staticClass: "mt-2" }, [
                            _c(
                              "span",
                              { staticClass: "text-secondary" },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    name: "check-circle",
                                    "stroke-width": "1",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-success" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.stat.successfull) + " "
                                  ),
                                ]),
                                _vm._v(
                                  "\n                  successfull rows\n                "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "progress mt-1 mb-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "progress-bar progress-bar-striped bg-success",
                                  style: "width: " + _vm.stat.successRate + "%",
                                  attrs: {
                                    "aria-valuenow": "" + _vm.stat.successRate,
                                    "aria-valuemax": "100",
                                    "aria-valuemin": "0",
                                    role: "progressbar",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.stat.successRate) +
                                      "\n                    %\n                  "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "text-secondary" },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    name: "x-circle",
                                    "stroke-width": "1",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-warning" }, [
                                  _vm._v(_vm._s(_vm.stat.unsuccessfull)),
                                ]),
                                _vm._v(
                                  "\n                  unsuccessfull rows "
                                ),
                                _c("br"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "progress mt-1 mb-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "progress-bar progress-bar-striped bg-danger",
                                  style:
                                    "width: " + _vm.stat.unsuccessRate + "%",
                                  attrs: {
                                    "aria-valuenow":
                                      "" + _vm.stat.unsuccessRate,
                                    "aria-valuemax": "100",
                                    "aria-valuemin": "0",
                                    role: "progressbar",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.stat.unsuccessRate) +
                                      "\n                    %\n                  "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "text-secondary" },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-2",
                                  attrs: { name: "info", "stroke-width": "1" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.stat.errors)),
                                ]),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("errors_found")) +
                                    "\n                "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "progress mt-1 mb-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "progress-bar progress-bar-striped bg-secondary",
                                  style: "width: " + _vm.stat.errorRate + "%",
                                  attrs: {
                                    "aria-valuenow": "" + _vm.stat.errorRate,
                                    "aria-valuemax": "100",
                                    "aria-valuemin": "0",
                                    role: "progressbar",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.stat.errorRate) +
                                      "\n                    %\n                  "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errors.import_file
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.import_file[0]) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errors.length > 0
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-danger alert-dismissible fade show mt-3",
                                attrs: { role: "alert" },
                              },
                              [
                                _c("h5", { staticClass: "alert-heading" }, [
                                  _vm._v("Error!"),
                                ]),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.errors.length) +
                                    " required fields are missing\n                  "
                                ),
                                _c("hr"),
                                _vm._v(
                                  "\n                  Missing field :\n                  "
                                ),
                                _c("em", [
                                  _c("code", [
                                    _vm._v(_vm._s(_vm.errors.join(","))),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm._m(0),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer p-primary bg-transparent" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.submitData($event)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "w-100" },
                [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                1
              ),
              _vm._v(" "),
              !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary mr-4",
              attrs: { "data-dismiss": "modal", type: "button" },
              on: { click: _vm.cancel },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
          ),
          _vm._v(" "),
          _vm.stat.errors > 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-warning btn-sm",
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.importErrorOpen($event)
                    },
                  },
                },
                [
                  _c("app-icon", {
                    staticClass: "mr-2",
                    attrs: { name: "download", "stroke-width": "1" },
                  }),
                  _vm._v(_vm._s(_vm.$t("importReport.xlsx")) + "\n      "),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "alert",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }