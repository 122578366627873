var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.$t("lead_groups"),
                directory: [_vm.$t("contact"), _vm.$t("contact_type")],
                icon: "clipboard",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.$can("create_types")
          ? _c("div", { staticClass: "col-sm-12 col-md-6" }, [
              _c(
                "div",
                { staticClass: "float-md-right mb-3 mb-sm-3 mb-md-0" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-with-shadow",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openModal($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("add_leads_group")) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("app-table", {
        attrs: { id: "contact-type-table", options: _vm.options },
        on: { action: _vm.getAction },
      }),
      _vm._v(" "),
      _vm.isModalActive
        ? _c("app-contact-type-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "selected-url": _vm.selectedUrlContact,
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "delete-modal" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }