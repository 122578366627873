var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "form",
        staticClass: "mb-0",
        attrs: { "data-url": this.props.noteSyncUrl },
      },
      [
        _c("app-input", {
          attrs: { type: "text-editor", required: true },
          model: {
            value: _vm.formData.note,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "note", $$v)
            },
            expression: "formData.note",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pt-primary px-primary border-top mx-minus-primary" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitData($event)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "w-100" },
                  [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                  1
                ),
                _vm._v(" "),
                !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("cancel")))]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }