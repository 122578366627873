var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-body-class": "quick-view-modal",
        "modal-id": "detailsViewModal",
        "modal-size": "extra-large",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-between w-100",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("app-avatar", {
                  attrs: {
                    "avatar-class": "avatars-w-50",
                    title: _vm.selectedDeal.owner.full_name,
                    img: _vm.selectedDeal.owner.profile_picture
                      ? _vm.urlGenerator(
                          _vm.selectedDeal.owner.profile_picture.path
                        )
                      : _vm.urlGenerator("/images/profile.png"),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ml-3" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.selectedDeal.owner.full_name))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "font-size-90 text-muted" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("deal_owner") +
                            " - " +
                            _vm.formatDateToLocal(_vm.selectedDeal.created_at)
                        ) +
                        "\n            -\n            " +
                        _vm._s(
                          _vm.$t("age") +
                            " - " +
                            _vm.dealAgeHumanize(
                              this.formData.created_at,
                              this.formData.updated_at,
                              this.formData.status
                            )
                        ) +
                        "\n            " +
                        _vm._s(
                          _vm.formData.discussions.length > 0
                            ? " - " +
                                _vm.formData.discussions.length +
                                " " +
                                _vm.$t("comments")
                            : ""
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              !(
                !this.$can("manage_public_access") && this.$can("client_access")
              )
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-primary btn-with-shadow btn-sm mr-2",
                      attrs: { type: "button", "data-toggle": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.showDealDetails()
                        },
                      },
                    },
                    [
                      _c("app-icon", {
                        staticClass: "mb-1",
                        attrs: { name: "external-link" },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("view_details")) +
                          "\n        "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close outline-none m-0 p-0",
                  attrs: { "aria-label": "Close", type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeModal($event)
                    },
                  },
                },
                [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.dataLoaded
        ? _c("template", { slot: "body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-xl-9 mb-4 mb-xl-0" },
                [
                  _c("h4", { staticClass: "mb-4" }, [
                    _c("b", [_vm._v("#" + _vm._s(_vm.selectedDeal.id) + " ")]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.selectedDeal.title) +
                        "\n          "
                    ),
                    _c(
                      "span",
                      {
                        class:
                          "badge badge-pill badge-" +
                          _vm.selectedDeal.status.class,
                      },
                      [_vm._v(_vm._s(_vm.selectedDeal.status.translated_name))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card border-0 mb-3" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("b", [_vm._v(_vm._s(_vm.$t("description")))]),
                      _vm._v(" "),
                      _vm.isEditDescription &&
                      _vm.checkDealStatus &&
                      _vm.clientRoleAccess
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "\n                d-flex\n                align-items-center\n                justify-content-start\n                header-actions\n                float-right\n              ",
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.editDescription()
                                    },
                                  },
                                },
                                [
                                  _c("app-icon", {
                                    staticClass: "size-18",
                                    attrs: { name: "edit" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body comment-card" },
                      [
                        _vm.isEditDescription
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-between mb-4",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.formData.description
                                              ? _vm.formData.description
                                              : _vm.$t("no_description")
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : [
                              _c("app-input", {
                                attrs: {
                                  type: "textarea",
                                  "text-area-rows": 5,
                                  placeholder: _vm.$t("enter_description"),
                                },
                                model: {
                                  value: _vm.description,
                                  callback: function ($$v) {
                                    _vm.description = $$v
                                  },
                                  expression: "description",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mt-2",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary mr-2",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancelEditDescription(
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("cancel")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateDescription(
                                            _vm.selectedDeal.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "w-100" },
                                        [
                                          _vm.buttonLoading
                                            ? _c("app-submit-button-loader")
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm.buttonLoading
                                        ? [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formData.description
                                                  ? _vm.$t("update")
                                                  : _vm.$t("add")
                                              )
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.computedTimeLine, function (event, index) {
                    return [
                      event["changed_key"] && _vm.clientRoleAccess
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex justify-content-start mb-3",
                            },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  "avatar-class": "avatars-w-30",
                                  title: "John Doe",
                                  img: event.responsible_user.profile_picture
                                    ? _vm.urlGenerator(
                                        event.responsible_user.profile_picture
                                          .path
                                      )
                                    : null,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-muted ml-3 mt-1 mb-0" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            event.responsible_user.full_name
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  event.changed_key == "expired_at" &&
                                  event.old_value == null
                                    ? [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "expecting_closing_date_added"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "changed_the_pipe_or_stage",
                                                {
                                                  key: _vm.$t(
                                                    event.changed_key
                                                  ),
                                                }
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ],
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          event.old_value == null
                                            ? event.old_value
                                            : event.old_value.name
                                            ? event.old_value.name
                                            : event.changed_key == "description"
                                            ? _vm.textTruncate(
                                                event.old_value,
                                                22,
                                                "..."
                                              )
                                            : event.changed_key == "expired_at"
                                            ? _vm.formatDateToLocal(
                                                event.old_value
                                              )
                                            : event.old_value
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v("\n              to\n              "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          event.new_value == null
                                            ? event.new_value
                                            : event.new_value.name
                                            ? event.new_value.name
                                            : event.changed_key == "description"
                                            ? _vm.textTruncate(
                                                event.new_value,
                                                22,
                                                "..."
                                              )
                                            : event.changed_key == "expired_at"
                                            ? _vm.formatDateToLocal(
                                                event.new_value
                                              )
                                            : event.new_value
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "font-italic" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.createdInfoShowAsHumanize(
                                            event.created_at
                                          )
                                        )
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : event.comment_body
                        ? _c(
                            "div",
                            { key: index, staticClass: "card border-0 mb-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "card-body comment-card" },
                                [
                                  _vm.deleteableCommentId !== event.id
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "\n                    d-flex\n                    align-items-center\n                    justify-content-between\n                    mb-4\n                  ",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c("app-avatar", {
                                                  attrs: {
                                                    "avatar-class":
                                                      "avatars-w-40",
                                                    title:
                                                      event.responsible_user
                                                        .full_name,
                                                    img: event.responsible_user
                                                      .profile_picture
                                                      ? _vm.urlGenerator(
                                                          event.responsible_user
                                                            .profile_picture
                                                            .path
                                                        )
                                                      : null,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _c(
                                                      "h6",
                                                      { staticClass: "mb-1" },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              event
                                                                .responsible_user
                                                                .full_name
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-muted font-size-90 mb-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.formatDateTimeToLocal(
                                                                event.created_at
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            event.responsible_user.id ==
                                              _vm.authUser.id &&
                                            _vm.editableId !== event.id
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "\n                      d-flex\n                      align-items-center\n                      justify-content-start\n                      comments-actions\n                    ",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.editComment(
                                                              event
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("app-icon", {
                                                          staticClass:
                                                            "size-18",
                                                          attrs: {
                                                            name: "edit",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.deleteComment(
                                                              event.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("app-icon", {
                                                          staticClass:
                                                            "size-18",
                                                          attrs: {
                                                            name: "trash",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.editableId == event.id
                                          ? [
                                              _c("app-input", {
                                                attrs: {
                                                  type: "text-editor",
                                                  id: event.id + "editor",
                                                  height: 200,
                                                  minimal: true,
                                                },
                                                model: {
                                                  value: _vm.comment,
                                                  callback: function ($$v) {
                                                    _vm.comment = $$v
                                                  },
                                                  expression: "comment",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-end mt-2",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-secondary mr-2",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.cancelEditComment(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t("cancel")
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.updateComment(
                                                            event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "w-100",
                                                        },
                                                        [
                                                          _vm.updateLoading
                                                            ? _c(
                                                                "app-submit-button-loader"
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      !_vm.updateLoading
                                                        ? [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("update")
                                                              )
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              _c("p", {
                                                staticClass: "text-muted mb-0",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    event.comment_body
                                                  ),
                                                },
                                              }),
                                            ],
                                      ]
                                    : _c("app-overlay-loader"),
                                ],
                                2
                              ),
                            ]
                          )
                        : event["tag_removed"] && _vm.clientRoleAccess
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex justify-content-start mb-3",
                            },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  "avatar-class": "avatars-w-30",
                                  title: "John Doe",
                                  img: event.responsible_user.profile_picture
                                    ? _vm.urlGenerator(
                                        event.responsible_user.profile_picture
                                          .path
                                      )
                                    : null,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-muted ml-3 mt-1 mb-0" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          event.responsible_user.full_name
                                        ) + "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("removed")) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                  badge badge-primary badge-sm\n                  rounded-pill\n                  font-weight-normal\n                ",
                                      style: {
                                        background: event.tag.color_code,
                                      },
                                    },
                                    [_vm._v(_vm._s(event.tag.name))]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("tag")) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-normal font-italic",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.createdInfoShowAsHumanize(
                                            event.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : event["tag_added"] && _vm.clientRoleAccess
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex justify-content-start mb-3",
                            },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  "avatar-class": "avatars-w-30",
                                  title: "John Doe",
                                  img: event.responsible_user.profile_picture
                                    ? _vm.urlGenerator(
                                        event.responsible_user.profile_picture
                                          .path
                                      )
                                    : null,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-muted ml-3 mt-1 mb-0" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          event.responsible_user.full_name
                                        ) + "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("added")) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                  badge badge-primary badge-sm\n                  rounded-pill\n                  font-weight-normal\n                ",
                                      style: {
                                        background: event.tag.color_code,
                                      },
                                    },
                                    [_vm._v(_vm._s(event.tag.name))]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("tag")) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-normal font-italic",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.createdInfoShowAsHumanize(
                                            event.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : event["status_change"]
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex justify-content-start mb-3",
                            },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  "avatar-class": "avatars-w-30",
                                  title: "John Doe",
                                  img: event.responsible_user.profile_picture
                                    ? _vm.urlGenerator(
                                        event.responsible_user.profile_picture
                                          .path
                                      )
                                    : null,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-muted ml-3 mt-1 mb-0" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          event.responsible_user.full_name
                                        ) + "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  event.new_value.name == "status_open"
                                    ? [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.$t("reopen_this_deal")) +
                                            "\n                    "
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  event.new_value.name == "status_won"
                                    ? [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.$t("won_this_deal")) +
                                            "\n                    "
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  event.new_value.name == "status_lost"
                                    ? [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.$t("lost_this_deal")) +
                                            "\n                    "
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-normal font-italic",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDateToLocal(
                                            event.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : event["lost_reason_add"]
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex justify-content-start mb-3",
                            },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  "avatar-class": "avatars-w-30",
                                  title: "John Doe",
                                  img: event.responsible_user.profile_picture
                                    ? _vm.urlGenerator(
                                        event.responsible_user.profile_picture
                                          .path
                                      )
                                    : null,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-muted ml-3 mt-1 mb-0" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          event.responsible_user.full_name
                                        ) + "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("add_lost_reason")) +
                                      "\n                    "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(event.new_value))]
                                  ),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("for_this_deal")) +
                                      "\n                    "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-normal font-italic",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDateToLocal(
                                            event.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : event["contact_person_removed"]
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex justify-content-start mb-3",
                            },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  "avatar-class": "avatars-w-30",
                                  title: "John Doe",
                                  img: event.responsible_user.profile_picture
                                    ? _vm.urlGenerator(
                                        event.responsible_user.profile_picture
                                          .path
                                      )
                                    : null,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-muted ml-3 mt-1 mb-0" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          event.responsible_user.full_name
                                        ) + "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("removed")) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                  badge badge-primary badge-sm\n                  rounded-pill\n                  font-weight-normal\n                ",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(event.contact_person_remove.name)
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("from_contact_person")) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-normal font-italic",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.createdInfoShowAsHumanize(
                                            event.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : event["contact_person_added"]
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex justify-content-start mb-3",
                            },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  "avatar-class": "avatars-w-30",
                                  title: "John Doe",
                                  img: event.responsible_user.profile_picture
                                    ? _vm.urlGenerator(
                                        event.responsible_user.profile_picture
                                          .path
                                      )
                                    : null,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-muted ml-3 mt-1 mb-0" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          event.responsible_user.full_name
                                        ) + "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("added")) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                  badge badge-primary badge-sm\n                  rounded-pill\n                  font-weight-normal\n                ",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(event.contact_person_add.name)
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("as_a_contact_person")) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-normal font-italic",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.createdInfoShowAsHumanize(
                                            event.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "card border-0" }, [
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center mb-4" },
                          [
                            _c("app-avatar", {
                              attrs: {
                                "avatar-class": "avatars-w-40",
                                title: "John Doe",
                                img: _vm.authUser.profile_picture
                                  ? _vm.urlGenerator(
                                      _vm.authUser.profile_picture.path
                                    )
                                  : null,
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-3" }, [
                              _c("h6", { staticClass: "mb-1" }, [
                                _vm._v(_vm._s(_vm.authUser.full_name)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("app-input", {
                          attrs: {
                            type: "text-editor",
                            height: 200,
                            minimal: true,
                          },
                          model: {
                            value: _vm.comment,
                            callback: function ($$v) {
                              _vm.comment = $$v
                            },
                            expression: "comment",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end mt-2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addComment($event)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "w-100" },
                                  [
                                    _vm.btnLoading
                                      ? _c("app-submit-button-loader")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !_vm.btnLoading
                                  ? [_vm._v(_vm._s(_vm.$t("comment")))]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-xl-3" },
                [
                  _c("deal-quick-view-stage", {
                    attrs: {
                      "deal-status": _vm.checkDealStatus,
                      "form-data": _vm.formData,
                      stages: _vm.stages,
                      "client-access": _vm.clientRoleAccess,
                    },
                    on: { "update-request": _vm.afterDealUpdated },
                  }),
                  _vm._v(" "),
                  _c("deal-quick-view-deal-value", {
                    attrs: {
                      "deal-status": _vm.checkDealStatus,
                      "form-data": _vm.formData,
                      stages: _vm.stages,
                      "client-access": _vm.clientRoleAccess,
                    },
                    on: { "update-request": _vm.afterDealUpdated },
                  }),
                  _vm._v(" "),
                  _c("deal-quick-view-lead-info", {
                    attrs: {
                      "deal-status": _vm.checkDealStatus,
                      "form-data": _vm.formData,
                      stages: _vm.stages,
                      "client-access": _vm.clientRoleAccess,
                    },
                    on: { "update-request": _vm.afterDealUpdated },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "border-bottom mb-4 pb-4" },
                    [
                      _c("p", { staticClass: "mb-2 font-weight-bold" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("Proposal")) +
                            " (" +
                            _vm._s(_vm.selectedDeal.proposals_count) +
                            ")\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.selectedDeal.proposals, function (proposal) {
                        return _c(
                          "div",
                          {
                            key: proposal.id,
                            staticClass:
                              "\n              d-flex\n              align-items-center\n              justify-content-between\n              font-size-90\n              mb-2\n            ",
                          },
                          [
                            _c("a", [
                              _vm._v(
                                _vm._s(
                                  _vm.textTruncate(proposal.subject, 22, "...")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class:
                                  "badge badge-pill badge-" +
                                  proposal.status.class,
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(proposal.status.translated_name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.clientRoleAccess
                    ? [
                        _c("deal-quick-view-tags", {
                          staticClass: "mb-primary",
                          attrs: {
                            "post-url": _vm.route("deal.attach-tag", {
                              id: _vm.formData.id,
                            }),
                            "taggable-id": _vm.formData.id,
                            tagData: _vm.formData.tags,
                          },
                          on: { "update-request": _vm.afterDealUpdated },
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("deal-quick-view-expire-date", {
                    attrs: {
                      "deal-status": _vm.checkDealStatus,
                      "form-data": _vm.formData,
                      stages: _vm.stages,
                      "client-access": _vm.clientRoleAccess,
                    },
                    on: { "update-request": _vm.afterDealUpdated },
                  }),
                  _vm._v(" "),
                  _vm.clientRoleAccess
                    ? [
                        _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c("deal-quick-view-followers", {
                              staticClass: "mb-primary",
                              attrs: {
                                "deal-status": _vm.checkDealStatus,
                                "follower-data": _vm.formData,
                                "follower-sync-url": _vm.route(
                                  "deal.sync-followers",
                                  { id: _vm.formData.id }
                                ),
                                "get-follower-url": _vm.route(
                                  "deal.get-followers",
                                  { id: _vm.formData.id }
                                ),
                                "people-list": _vm.personList,
                                "permission-check": _vm.syncPermission,
                                "status-check": true,
                              },
                              on: { "update-request": _vm.afterDealUpdated },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        : _c("app-overlay-loader"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }