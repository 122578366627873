var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    { attrs: { "modal-id": _vm.modalId, modalSize: "fullscreen" } },
    [
      _c("template", { slot: "header" }, [
        _c(
          "h5",
          { staticClass: "modal-title" },
          [
            _c("app-icon", { attrs: { name: "cpu" } }),
            _vm._v(" " + _vm._s(_vm.title) + "\n        "),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "close-btn", attrs: { "aria-hidden": "true" } },
              [_vm._v("×")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c("span", {
            staticClass: "v-html-wrapper",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }