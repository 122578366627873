var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "diV",
    {
      staticClass: "content-wrapper",
      staticStyle: { height: "350px !important" },
    },
    [
      _c("app-pagination", {
        attrs: { "total-row": _vm.totalRow, "row-limit": _vm.rowLimit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }