import { render, staticRenderFns } from "./TestTagManager.vue?vue&type=template&id=35b30543&"
import script from "./TestTagManager.vue?vue&type=script&lang=js&"
export * from "./TestTagManager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\sites\\tm-crm.ru\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35b30543')) {
      api.createRecord('35b30543', component.options)
    } else {
      api.reload('35b30543', component.options)
    }
    module.hot.accept("./TestTagManager.vue?vue&type=template&id=35b30543&", function () {
      api.rerender('35b30543', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/js/core/examples/tag/TestTagManager.vue"
export default component.exports