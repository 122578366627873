var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper", staticStyle: { height: "100vh" } },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": "Page title",
          directory: ["Directory", "Sub-directory"],
          icon: "grid",
          button: { label: "Form", url: "/form" },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("app-avatar", {
            attrs: {
              title: "avatar",
              "avatar-class": _vm.avatarClass,
              status: "success",
              shadow: true,
              border: true,
              img: _vm.img,
              "alt-text": _vm.altText,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }