var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-primary" }, [
    _c("div", { staticClass: "card template-preview-card border-0 mb-2" }, [
      _c(
        "div",
        {
          staticClass: "template-preview-wrapper p-3 rounded",
          style: [
            _vm.previewType === "image"
              ? {
                  backgroundImage: "url(" + _vm.generateBackgroundImage() + ")",
                }
              : null,
          ],
          on: { click: _vm.selectedTemplateCard },
        },
        [
          _vm.previewType === "html"
            ? _c("span", {
                staticClass: "v-html-wrapper",
                domProps: {
                  innerHTML: _vm._s(
                    !_vm.card[_vm.customContentKey]
                      ? _vm.card[_vm.defaultContentKey]
                      : _vm.card[_vm.customContentKey]
                  ),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showAction
            ? _c("div", { staticClass: "card-overlay-options" }, [
                _c(
                  "ul",
                  { staticClass: "list-group h-100" },
                  _vm._l(_vm.actions, function (action, index) {
                    return _vm.checkModifier(action)
                      ? _c(
                          "li",
                          { key: index, staticClass: "list-group-item" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "d-block",
                                attrs: { href: "#", target: "_blank" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.callAction(action)
                                  },
                                },
                              },
                              [
                                _c("app-icon", {
                                  attrs: { name: action.icon },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml-3" }, [
                                  _vm._v(_vm._s(action.title)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "text-muted card-subject" }, [
      _vm._v(_vm._s(_vm.card[_vm.subjectKey])),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }