var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          _c("app-note", {
            attrs: {
              title: _vm.$t("update"),
              notes: [
                Object.keys(_vm.no_update_message).length
                  ? _vm.no_update_message.message_1 +
                    "\n        " +
                    (_vm.no_update_message.message_2 ? "\n" : "") +
                    " " +
                    _vm.no_update_message.message_2
                  : _vm.$t("update_warning"),
              ],
            },
          }),
          _vm._v(" "),
          _vm.afterUpdateInstruction
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between mb-3 mt-3",
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.redirectHomePage()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.afterUpdateInstruction))]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.updates.result, function (update) {
            return _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-between mb-3",
              },
              [
                _vm._v("\n    " + _vm._s(update.version) + "\n    "),
                _c(
                  "div",
                  { staticClass: "btn-group btn-group-action d-inline-block" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://pipex.gainhq.com/documentation/change-log.html",
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("change_logs")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        attrs: {
                          type: "button",
                          "data-toggle": "tooltip",
                          title: _vm.$t("update"),
                          "data-placement": "top",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updateApp(update.version)
                          },
                        },
                      },
                      [_c("app-icon", { attrs: { name: "download" } })],
                      1
                    ),
                  ]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm.confirmationModalActive
            ? _c("app-confirmation-modal", {
                attrs: {
                  "modal-id": "app-confirmation-modal",
                  message: _vm.$t("this_will_update_entire_app"),
                },
                on: {
                  confirmed: function ($event) {
                    return _vm.confirmed()
                  },
                  cancelled: _vm.cancelled,
                },
              })
            : _vm._e(),
        ],
        2
      )
    : _c("app-overlay-loader")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }