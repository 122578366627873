var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper calendar-position-modified" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.currentPipeline,
                directory: [
                  _vm.$t("deals"),
                  _vm.$t("all_deals"),
                  _vm.currentPipeline,
                ],
                icon: "clipboard",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c(
            "div",
            { staticClass: "float-md-right mb-primary mb-sm-0 mb-md-0" },
            [
              _vm.$can("import_deal") ||
              _vm.$can("deal_reports") ||
              _vm.$can("create_lost_reasons") ||
              _vm.$can("view_lost_reasons")
                ? _c(
                    "div",
                    { staticClass: "dropdown d-inline-block btn-dropdown" },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-success dropdown-toggle ml-0 mr-2",
                          attrs: {
                            type: "button",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-menu" }, [
                        _vm.$can("import_deal")
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "dropdown-item d-flex align-items-center p-3",
                                attrs: { href: _vm.route("deal.import") },
                              },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    "stroke-width": "1",
                                    name: "download",
                                    width: "16",
                                    height: "16",
                                  },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("import_deals")) +
                                    "\n            "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$can("deal_reports")
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "dropdown-item d-flex align-items-center p-3",
                                attrs: { href: _vm.route("deal.export") },
                              },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    "stroke-width": "1",
                                    name: "download",
                                    width: "16",
                                    height: "16",
                                  },
                                }),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("export_deal")) +
                                    "\n              "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$can("create_lost_reasons")
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "dropdown-item d-flex align-items-center p-3",
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openLostreasonModal($event)
                                  },
                                },
                              },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    "stroke-width": "1",
                                    name: "plus-square",
                                    width: "16",
                                    height: "16",
                                  },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("add_lost_reasons")) +
                                    "\n            "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$can("view_lost_reasons")
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "dropdown-item d-flex align-items-center p-3",
                                attrs: {
                                  href: _vm.route("lost_reasons.lists"),
                                },
                              },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    "stroke-width": "1",
                                    name: "list",
                                    width: "16",
                                    height: "16",
                                  },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("manage_lost_reasons")) +
                                    "\n            "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("create_deals")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-with-shadow",
                      attrs: { type: "button", "data-toggle": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("add_deal")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("app-table", {
        attrs: { id: _vm.tableId, "card-view": true, options: _vm.options },
        on: { getFilteredValues: _vm.filteredValues, action: _vm.getAction },
      }),
      _vm._v(" "),
      _vm.isDealModalActive
        ? _c("app-deal-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "selected-url": _vm.selectedUrl,
              "pipeline-id": _vm.pipelineId,
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "deal-delete-modal" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isModalActive
        ? _c("app-lost-reason-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "selected-url": _vm.selectedUrlReason,
            },
            on: { "close-modal": _vm.closeLostReasonModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isDetailsViewActive
        ? _c("deal-details-modal", {
            attrs: {
              "selected-deal": _vm.selectedDeal,
              pipelines: _vm.pipelineList,
              stages: _vm.stageList,
            },
            on: {
              "deal-update": _vm.dealUpdated,
              "close-modal": _vm.closeDetailsViewModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }