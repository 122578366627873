var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dropdown d-inline-block btn-dropdown" }, [
      _c(
        "button",
        {
          staticClass: "btn dropdown-toggle ml-0 mr-2",
          class: _vm.btnClass,
          attrs: {
            type: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown-menu" },
        [
          _vm._l(_vm.list, function (option, index) {
            return [
              option.type === "link"
                ? _c(
                    "a",
                    {
                      staticClass:
                        "dropdown-item d-flex align-items-center p-3 cursor-pointer",
                      attrs: { href: option.url },
                    },
                    [
                      option.icon
                        ? _c("app-icon", {
                            staticClass: "mr-3",
                            attrs: {
                              "stroke-width": "1",
                              name: option.icon,
                              width: "16",
                              height: "16",
                            },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n                    " +
                          _vm._s(option.name) +
                          "\n                "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              option.type === "button" || option.type === "modal"
                ? _c(
                    "a",
                    {
                      staticClass:
                        "dropdown-item d-flex align-items-center p-3 cursor-pointer",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.triggerAction(option)
                        },
                      },
                    },
                    [
                      option.icon
                        ? _c("app-icon", {
                            staticClass: "mr-3",
                            attrs: {
                              "stroke-width": "1",
                              name: option.icon,
                              width: "16",
                              height: "16",
                            },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n                    " +
                          _vm._s(option.name) +
                          "\n                "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }