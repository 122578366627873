var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-type-column" },
    [
      this.rowData.tags.length > 0
        ? _vm._l(this.rowData.tags, function (tag, index) {
            return _c(
              "span",
              {
                key: index,
                staticClass:
                  "badge badge-text-truncate badge-sm badge-pill text-capitalize mr-1 mb-1",
                style: "background-color:" + tag.color_code,
              },
              [_vm._v(_vm._s(tag.name) + "\n                  ")]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }