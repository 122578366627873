var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-id": "person-org-modal",
        "modal-size": "default",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("view_all"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              "aria-label": "Close",
              "data-dismiss": "modal",
              type: "button",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        _vm._l(_vm.rowData, function (item, index) {
          return _c("div", [
            _c("div", [
              _c(
                "span",
                { staticClass: "mb-1 badge badge-round badge-light" },
                [_vm._v(_vm._s(item.value))]
              ),
              _vm._v(" "),
              item.type
                ? _c(
                    "span",
                    {
                      class:
                        "ml-1 py-1 px-2 badge badge-round badge-" +
                        item.type.class,
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.type.name) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { "data-dismiss": "modal", type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("close")) + "\n        ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }