var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between mb-2" },
      [
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isFollowersActive,
                expression: "isFollowersActive",
              },
            ],
            staticClass: "mb-2 font-weight-bold",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("follower")) +
                " (" +
                _vm._s(_vm.followerData.followers.length) +
                ")\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.permissionCheck != false
          ? [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFollowersActive,
                      expression: "!isFollowersActive",
                    },
                  ],
                  staticClass: "mb-2 font-weight-bold",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("edit_follower")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.statusCheck != false
                ? _c("div", [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isFollowersActive,
                            expression: "!isFollowersActive",
                          },
                        ],
                        staticClass: "text-muted",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.followerClose($event)
                          },
                        },
                      },
                      [
                        _c("app-icon", {
                          staticClass: "size-20",
                          attrs: { name: "x-square", "stroke-width": "1" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isFollowersActive,
                            expression: "!isFollowersActive",
                          },
                        ],
                        staticClass: "text-muted",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.followerSync($event)
                          },
                        },
                      },
                      [
                        _c("app-icon", {
                          staticClass: "size-20",
                          attrs: { name: "check-square", "stroke-width": "1" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isFollowersActive && _vm.dealStatus,
                            expression: "isFollowersActive && dealStatus",
                          },
                        ],
                        staticClass: "text-muted",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.followerEdit($event)
                          },
                        },
                      },
                      [
                        _c("app-icon", {
                          staticClass: "size-20",
                          attrs: { name: "edit", "stroke-width": "1" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.followerData.followers
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isFollowersActive,
                    expression: "isFollowersActive",
                  },
                ],
              },
              [
                _vm.followerData.followers.length
                  ? _vm._l(
                      _vm.followerData.followers,
                      function (follower, index) {
                        return index < 3
                          ? _c(
                              "div",
                              {
                                key: index,
                                staticClass: "media d-flex align-items-center",
                                class: {
                                  "mb-2":
                                    _vm.followerData.followers.length > 1 &&
                                    index !==
                                      _vm.followerData.followers.length - 1,
                                },
                              },
                              [
                                follower.person
                                  ? _c("app-avatar", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        img: follower.person.profile_picture
                                          ? _vm.urlGenerator(
                                              follower.person.profile_picture
                                                .path
                                            )
                                          : follower.person.profile_picture,
                                        title: follower.person.name,
                                        "avatar-class": "avatars-w-40",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                follower.person
                                  ? _c("div", { staticClass: "media-body" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(follower.person.name) +
                                          "\n                        "
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-muted font-size-90 mb-0",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                follower.person.email.value
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e()
                      }
                    )
                  : [
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("no_person_linked_yet"))),
                      ]),
                      _vm._v(" "),
                      _vm.dealStatus
                        ? _c(
                            "a",
                            {
                              staticClass: "font-size-90",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.followerEdit($event)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("link_as_person")) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                _vm._v(" "),
                _vm.followerData.followers.length > 3
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.viewAll()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("view_all")) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isFollowersActive,
                expression: "!isFollowersActive",
              },
            ],
          },
          [
            _vm._l(_vm.followerData.followers, function (follower, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "form-group",
                  class: {
                    "mb-3":
                      _vm.followerData.followers.length > 1 &&
                      index !== _vm.followerData.followers.length - 1,
                  },
                },
                [
                  _c("div", { staticClass: "form-row align-items-center" }, [
                    _c(
                      "div",
                      { staticClass: "col-11" },
                      [
                        _c("app-input", {
                          attrs: {
                            list: _vm.peopleList,
                            placeholder: _vm.$t("choose_one"),
                            "list-value-field": "name",
                            type: "search-select",
                          },
                          model: {
                            value: follower.person_id,
                            callback: function ($$v) {
                              _vm.$set(follower, "person_id", $$v)
                            },
                            expression: "follower.person_id",
                          },
                        }),
                        _vm._v(" "),
                        Object.keys(_vm.errors).length
                          ? _c(
                              "small",
                              { staticClass: "text-danger" },
                              [
                                _vm._l(
                                  Object.keys(_vm.errors),
                                  function (validate) {
                                    return [
                                      validate.slice(10) == index
                                        ? [
                                            _vm._l(
                                              _vm.errors[validate],
                                              function (messageData) {
                                                return [
                                                  _vm._v(_vm._s(messageData)),
                                                ]
                                              }
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-1" }, [
                      _c(
                        "a",
                        {
                          staticClass: "text-muted",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteFollower(index)
                            },
                          },
                        },
                        [
                          _c("app-icon", {
                            attrs: {
                              name: "trash",
                              "stroke-width": "1",
                              width: "20",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addMoreFollower($event)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("add_more")) +
                    "\n            "
                ),
              ]
            ),
          ],
          2
        ),
        _vm._v(" "),
        _vm.viewAllModal
          ? _c("app-common-all-follower", {
              attrs: { "follower-data": _vm.followerUrl },
              on: { "close-modal": _vm.closedViewModal },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }