var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card border-0 card-with-shadow" }, [
    _c("div", { staticClass: "card-header bg-transparent p-primary" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-8" }, [
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isJobTitleActive,
                  expression: "isJobTitleActive",
                },
              ],
              staticClass: "card-title text-muted m-0",
            },
            [
              _vm._v(
                "\n\t\t\t\t\t\t" +
                  _vm._s(
                    _vm.componentType == "organization"
                      ? _vm.$t("people_and_job_title")
                      : _vm.$t("organizations_and_job_title")
                  ) +
                  "\n\t\t\t\t\t"
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "h5",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isJobTitleActive,
                  expression: "!isJobTitleActive",
                },
              ],
              staticClass: "card-title text-muted m-0",
            },
            [
              _vm._v(
                "\n\t\t\t\t\t\t" +
                  _vm._s(
                    _vm.componentType == "organization"
                      ? _vm.$t("edit_people_and_job_title")
                      : _vm.$t("edit_organizations_and_job_title")
                  ) +
                  "\n\t\t\t\t\t"
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-end h-100",
            },
            [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isJobTitleActive,
                      expression: "!isJobTitleActive",
                    },
                  ],
                  staticClass: "text-muted",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.jobTitleClose($event)
                    },
                  },
                },
                [
                  _c("app-icon", {
                    attrs: { name: "x-square", "stroke-width": "1" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isJobTitleActive,
                      expression: "!isJobTitleActive",
                    },
                  ],
                  staticClass: "text-muted",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.jobTitleSync($event)
                    },
                  },
                },
                [
                  _c("app-icon", {
                    attrs: { name: "check-square", "stroke-width": "1" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isJobTitleActive,
                      expression: "isJobTitleActive",
                    },
                  ],
                  staticClass: "text-muted",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.jobTitleEdit($event)
                    },
                  },
                },
                [
                  _c("app-icon", {
                    attrs: { name: "edit", "stroke-width": "1" },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isJobTitleActive,
              expression: "isJobTitleActive",
            },
          ],
        },
        [
          _vm.jobTitleData.length
            ? _vm._l(_vm.jobTitleData, function (jobTitle, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                    class: {
                      "mb-3":
                        jobTitle.length > 1 && index !== jobTitle.length - 1,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "media d-flex align-items-center mt-2" },
                      [
                        _c("app-avatar", {
                          staticClass: "mr-2",
                          attrs: {
                            img: jobTitle.profile_picture
                              ? _vm.urlGenerator(jobTitle.profile_picture.path)
                              : jobTitle.profile_picture,
                            title: jobTitle.name,
                            "avatar-class": "avatars-w-40",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "media-body" },
                          [
                            jobTitle.id
                              ? [
                                  _vm.componentType == "organization"
                                    ? [
                                        _vm.adminAccess(jobTitle)
                                          ? [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.route(
                                                      "persons.edit",
                                                      { id: jobTitle.id }
                                                    ),
                                                  },
                                                },
                                                [_vm._v(_vm._s(jobTitle.name))]
                                              ),
                                            ]
                                          : [
                                              _c("a", [
                                                _vm._v(_vm._s(jobTitle.name)),
                                              ]),
                                            ],
                                      ]
                                    : [
                                        _vm.adminAccess(jobTitle)
                                          ? [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.route(
                                                      "organizations.edit",
                                                      { id: jobTitle.id }
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(jobTitle.name)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              _c("a", [
                                                _vm._v(
                                                  " " + _vm._s(jobTitle.name)
                                                ),
                                              ]),
                                            ],
                                      ],
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "text-muted font-size-90 mb-0" },
                              [
                                jobTitle.pivot !== ""
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(jobTitle.pivot.job_title) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("no_job_title_here")) +
                                          " "
                                      ),
                                    ]),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    jobTitle.contact_type
                      ? [
                          _c("app-badge", {
                            attrs: {
                              className:
                                "badge-sm badge-pill badge-" +
                                jobTitle.contact_type.class,
                              label: jobTitle.contact_type.name,
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              })
            : [
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("no_organization_linked_yet"))),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "font-size-90",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.jobTitleEdit($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(
                          _vm.componentType == "organization"
                            ? _vm.$t("link_a_person")
                            : _vm.$t("link_an_organization")
                        ) +
                        "\n\t\t\t\t\t"
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isJobTitleActive,
              expression: "!isJobTitleActive",
            },
          ],
        },
        [
          _vm._l(_vm.jobTitleData, function (jobTitle, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "form-group",
                class: {
                  "mb-3": jobTitle.length > 1 && index !== jobTitle.length - 1,
                },
              },
              [
                _c("div", { staticClass: "form-row align-items-center" }, [
                  _vm.componentType == "organization"
                    ? _c(
                        "div",
                        { staticClass: "col-12 mb-1" },
                        [
                          _c("app-input", {
                            attrs: {
                              list: _vm.dataList,
                              placeholder: _vm.$t("choose_one"),
                              "list-value-field": "name",
                              type: "search-select",
                            },
                            model: {
                              value: jobTitle.pivot.person_id,
                              callback: function ($$v) {
                                _vm.$set(jobTitle.pivot, "person_id", $$v)
                              },
                              expression: "jobTitle.pivot.person_id",
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors[index + ".person_id"]
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.errors[index + ".person_id"][0]
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "col-12 mb-1" },
                        [
                          _c("app-input", {
                            attrs: {
                              list: _vm.dataList,
                              placeholder: _vm.$t("choose_one"),
                              "list-value-field": "name",
                              type: "search-select",
                            },
                            model: {
                              value: jobTitle.pivot.organization_id,
                              callback: function ($$v) {
                                _vm.$set(jobTitle.pivot, "organization_id", $$v)
                              },
                              expression: "jobTitle.pivot.organization_id",
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors[index + ".organization_id"]
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.errors[index + ".organization_id"][0]
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("app-input", {
                        attrs: {
                          placeholder: _vm.$t("type_job_title"),
                          type: "text",
                        },
                        model: {
                          value: jobTitle.pivot.job_title,
                          callback: function ($$v) {
                            _vm.$set(jobTitle.pivot, "job_title", $$v)
                          },
                          expression: "jobTitle.pivot.job_title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-2 d-flex justify-content-center" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "text-muted",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeJobTitle(index)
                            },
                          },
                        },
                        [
                          _c("app-icon", {
                            attrs: {
                              name: "trash",
                              "stroke-width": "1",
                              width: "20",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addMoreJobTitle($event)
                },
              },
            },
            [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.$t("add_more")) + "\n\t\t\t\t")]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }