var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-checkbox-group",
      class: _vm.data.radioCheckboxWrapper ? _vm.data.radioCheckboxWrapper : "",
    },
    _vm._l(_vm.data.list, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "customized-checkbox",
          class: _vm.data.customCheckboxType,
        },
        [
          _c("input", {
            ref: "checkbox",
            refInFor: true,
            attrs: {
              type: _vm.data.type,
              name: _vm.name,
              id: _vm.name + "-" + item.id,
              required: _vm.data.required,
              disabled: item.disabled || _vm.data.disabled,
              placeholder: _vm.data.placeholder,
            },
            domProps: { value: item.id, checked: _vm.isInclude(item.id) },
            on: {
              input: function ($event) {
                return _vm.input($event)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              class: _vm.data.labelClass,
              attrs: { for: _vm.name + "-" + item.id },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(item[_vm.data.listValueField]) +
                  "\n        "
              ),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }