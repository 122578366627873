var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-primary" }, [
    _c("h4", { staticClass: "text-success" }, [
      _vm._v(" This is expandable column component"),
    ]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.data.expandable_data))]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-danger",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.consoleSomething($event)
          },
        },
      },
      [_vm._v("HI")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }