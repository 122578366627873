import { render, staticRenderFns } from "./SocialLinks.vue?vue&type=template&id=37ac24bf&"
import script from "./SocialLinks.vue?vue&type=script&lang=js&"
export * from "./SocialLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\sites\\tm-crm.ru\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37ac24bf')) {
      api.createRecord('37ac24bf', component.options)
    } else {
      api.reload('37ac24bf', component.options)
    }
    module.hot.accept("./SocialLinks.vue?vue&type=template&id=37ac24bf&", function () {
      api.rerender('37ac24bf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/js/crm/Component/Views/Profile/SocialLinks.vue"
export default component.exports