import { render, staticRenderFns } from "./TestMultiSelect.vue?vue&type=template&id=528551a5&"
import script from "./TestMultiSelect.vue?vue&type=script&lang=js&"
export * from "./TestMultiSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\sites\\tm-crm.ru\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('528551a5')) {
      api.createRecord('528551a5', component.options)
    } else {
      api.reload('528551a5', component.options)
    }
    module.hot.accept("./TestMultiSelect.vue?vue&type=template&id=528551a5&", function () {
      api.rerender('528551a5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/js/core/examples/input/TestMultiSelect.vue"
export default component.exports