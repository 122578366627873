var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-5" }, [
    _c("h2", { staticClass: "text-center text-capitalize mb-primary" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.$t("install")) +
          " " +
          _vm._s(_vm.appName) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card card-with-shadow border-0 mb-primary" }, [
      _c("div", { staticClass: "card-header bg-transparent p-primary" }, [
        _c(
          "h5",
          { staticClass: "card-title mb-0 d-flex align-items-center " },
          [
            _c("app-icon", {
              staticClass: "primary-text-color mr-2",
              attrs: { name: "mail" },
            }),
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("email_setup")) +
                "\n            "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group row align-items-center" }, [
          _c(
            "label",
            {
              staticClass: "col-lg-3 col-xl-3 mb-lg-0",
              attrs: { for: "emailSettingsProvider" },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("supported_mail_services")) +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-8 col-xl-8" },
            [
              _c("app-input", {
                attrs: {
                  id: "emailSettingsProvider",
                  type: "select",
                  list: _vm.providerList,
                  required: true,
                },
                model: {
                  value: _vm.environment.provider,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "provider", $$v)
                  },
                  expression: "environment.provider",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row align-items-center" }, [
          _c(
            "label",
            {
              staticClass: "col-lg-3 col-xl-3 mb-lg-0",
              attrs: { for: "emailSettingsFromName" },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("email_sent_from_name")) +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-8 col-xl-8" },
            [
              _c("app-input", {
                attrs: {
                  id: "emailSettingsFromName",
                  type: "text",
                  placeholder: _vm.$t("type_email_sent_from_name"),
                  required: true,
                },
                model: {
                  value: _vm.environment.from_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "from_name", $$v)
                  },
                  expression: "environment.from_name",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row align-items-center" }, [
          _c(
            "label",
            {
              staticClass: "col-lg-3 col-xl-3 mb-lg-0",
              attrs: { for: "emailSettingsFromEmail" },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("email_sent_from_email")) +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-8 col-xl-8" },
            [
              _c("app-input", {
                attrs: {
                  id: "emailSettingsFromEmail",
                  type: "email",
                  placeholder: _vm.$t("type_email_sent_from_email"),
                  required: true,
                },
                model: {
                  value: _vm.environment.from_email,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "from_email", $$v)
                  },
                  expression: "environment.from_email",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.environment.provider === "amazon_ses"
          ? _c("fieldset", [
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsHostname" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("hostname")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsHostname",
                        type: "text",
                        placeholder: _vm.$t("type_host_name"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.hostname,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "hostname", $$v)
                        },
                        expression: "environment.hostname",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsAccessKeyId" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("access_key_id")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsAccessKeyId",
                        type: "text",
                        placeholder: _vm.$t("type_access_key_id"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.access_key_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "access_key_id", $$v)
                        },
                        expression: "environment.access_key_id",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsSecretAccessKey" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("secret_access_key")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsSecretAccessKey",
                        type: "text",
                        placeholder: _vm.$t("type_secret_access_key"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.secret_access_key,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "secret_access_key", $$v)
                        },
                        expression: "environment.secret_access_key",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsApiRegion" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("region")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsApiRegion",
                        type: "text",
                        placeholder: _vm.$t("region"),
                      },
                      model: {
                        value: _vm.environment.region,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "region", $$v)
                        },
                        expression: "environment.region",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm.environment.provider === "mailgun"
          ? _c("fieldset", [
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsDomainName" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("domain_name")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsDomainName",
                        type: "text",
                        placeholder: _vm.$t("type_domain_name"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.domain_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "domain_name", $$v)
                        },
                        expression: "environment.domain_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsApiKey" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("api_key")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsApiKey",
                        type: "text",
                        placeholder: _vm.$t("type_api_key"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.api_key,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "api_key", $$v)
                        },
                        expression: "environment.api_key",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm.environment.provider === "smtp"
          ? _c("fieldset", [
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "user_name" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("user_name")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "user_name",
                        type: "text",
                        placeholder: _vm.$t("user_name"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.smtp_user_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "smtp_user_name", $$v)
                        },
                        expression: "environment.smtp_user_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsSmtpHost" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("smtp_host")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsSmtpHost",
                        type: "text",
                        placeholder: _vm.$t("type_smtp_host"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.smtp_host,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "smtp_host", $$v)
                        },
                        expression: "environment.smtp_host",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsSmtpPort" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("port")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsSmtpPort",
                        type: "number",
                        placeholder: _vm.$t("type_port_number"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.smtp_port,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "smtp_port", $$v)
                        },
                        expression: "environment.smtp_port",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsEmailPassword" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("password_to_access")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsEmailPassword",
                        type: "password",
                        placeholder: _vm.$t("type_password_to_access"),
                        required: true,
                      },
                      model: {
                        value: _vm.environment.email_password,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "email_password", $$v)
                        },
                        expression: "environment.email_password",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "emailSettingsEncryptionType" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("encryption_type")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "emailSettingsEncryptionType",
                        type: "select",
                        placeholder: _vm.$t("type_encryption_type"),
                        list: _vm.encryptionType,
                        required: true,
                      },
                      model: {
                        value: _vm.environment.encryption_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "encryption_type", $$v)
                        },
                        expression: "environment.encryption_type",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group mt-5" },
      [
        _c("app-submit-button", {
          attrs: {
            loading: _vm.loading,
            "btn-class": "btn-block",
            label: _vm.$t("save_&_next"),
          },
          on: { click: _vm.submitData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }