var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { ref: "form" }, [
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v(_vm._s(_vm.$t("contents")))]),
        _vm._v(" "),
        _c("app-input", {
          attrs: {
            id: "database-template-title",
            name: "custom_content",
            required: true,
          },
          model: {
            value: _vm.template.custom_content,
            callback: function ($$v) {
              _vm.$set(_vm.template, "custom_content", $$v)
            },
            expression: "template.custom_content",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group text-center" },
      _vm._l(Object.keys(_vm.all_tags), function (t) {
        return _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary px-3 py-1 margin-left-2 mt-2",
            attrs: {
              type: "button",
              "data-toggle": "tooltip",
              "data-placement": "top",
              title: _vm.tags[t],
            },
            on: {
              click: function ($event) {
                return _vm.insertAtCaret("database-template-title", t)
              },
            },
          },
          [_vm._v(_vm._s(t) + "\n        ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "float-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary mr-2",
          attrs: { type: "button", "data-dismiss": "modal" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeModal($event)
            },
          },
        },
        [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.submitData($event)
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "w-100" },
            [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
            1
          ),
          _vm._v(" "),
          !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }