var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper p-4" }, [
    _c("div", { staticClass: "card p-4 card-with-shadow border-0" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xl-6" },
          [
            _c("app-avatars-group", {
              attrs: {
                "avatar-group-class": _vm.avatarsGroupClass,
                avatars: _vm.avatars,
                shadow: true,
                border: true,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xl-6" },
          [_c("app-avatars-group", { attrs: { avatars: _vm.avatars } })],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }