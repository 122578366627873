var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper", staticStyle: { height: "100vh" } },
    [
      _c("app-input", {
        attrs: {
          type: "text-editor",
          name: "text-editor",
          id: "text-editor",
          placeholder: _vm.placeholder,
          initialValue: _vm.value,
          "field-props": _vm.fieldStatus,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }