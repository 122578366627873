var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-card-view", {
        attrs: { id: "test-card-view", properties: _vm.properties },
        on: { action: _vm.getActions, selectedTemplateCard: _vm.getCard },
      }),
      _vm._v(" "),
      _vm.addEditModal
        ? _c(
            "app-modal",
            {
              attrs: { "modal-id": "example", "modal-size": "default" },
              on: { "close-modal": _vm.closeAddEditModal },
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "exampleModalLabel" },
                  },
                  [_vm._v("Modal title")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close outline-none",
                    attrs: {
                      type: "button",
                      "aria-label": "Close",
                      "data-dismiss": "modal",
                    },
                  },
                  [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
                ),
              ]),
              _vm._v(" "),
              _c("template", { slot: "body" }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.selectedCard["custom_content"]
                        ? _vm.selectedCard["custom_content"]
                        : _vm.selectedCard["default_content"]
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-4",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("Close\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.save },
                  },
                  [_vm._v("Save changes")]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDeleteModal
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "delete-example" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }