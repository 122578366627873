var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.data, function (deal, rowIndex) {
      return _vm.data.length > 0
        ? _c(
            "div",
            {
              key: "row" + rowIndex,
              staticClass: "col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-primary",
            },
            [
              _c("app-deal-card", {
                attrs: {
                  "table-id": _vm.id,
                  deal: deal,
                  pipelines: _vm.pipelineList,
                  stages: _vm.stageList,
                  actions: _vm.actions,
                },
                on: { "action-deal-modal": _vm.getAction },
              }),
            ],
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }