var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c(
      "form",
      {
        ref: "form",
        attrs: { "data-url": "test-component", enctype: "multipart/form-data" },
      },
      [
        _c(
          "div",
          { staticClass: "card card-with-shadow border-0 mb-primary" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "text" },
                    on: {
                      focusin: _vm.focusInShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.testFields.textValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.testFields, "textValue", $$v)
                      },
                      expression: "testFields.textValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "email" },
                    on: {
                      focusin: _vm.focusInShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.testFields.emailValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.testFields, "emailValue", $$v)
                      },
                      expression: "testFields.emailValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "number" },
                    on: {
                      focusin: _vm.focusInShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.testFields.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.testFields, "phone", $$v)
                      },
                      expression: "testFields.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "password" },
                    on: {
                      focusin: _vm.focusInShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.testFields.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.testFields, "password", $$v)
                      },
                      expression: "testFields.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "currency" },
                    on: {
                      focusin: _vm.focusInShow,
                      mousemove: _vm.mouseMoveShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.currency,
                      callback: function ($$v) {
                        _vm.currency = $$v
                      },
                      expression: "currency",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "decimal" },
                    on: {
                      focusin: _vm.focusInShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.decimalNumber,
                      callback: function ($$v) {
                        _vm.decimalNumber = $$v
                      },
                      expression: "decimalNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "tel-input",
                      placeholder: "Enter phone number",
                      required: true,
                    },
                    model: {
                      value: _vm.testNumber,
                      callback: function ($$v) {
                        _vm.testNumber = $$v
                      },
                      expression: "testNumber",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card card-with-shadow border-0 mb-primary" },
          [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "switch", label: _vm.$t("Switch") },
                    model: {
                      value: _vm.switchValue,
                      callback: function ($$v) {
                        _vm.switchValue = $$v
                      },
                      expression: "switchValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "checkbox",
                      list: _vm.multiSelectOptionList,
                    },
                    model: {
                      value: _vm.checkboxValue,
                      callback: function ($$v) {
                        _vm.checkboxValue = $$v
                      },
                      expression: "checkboxValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "radio", "radio-checkbox-name": "test" },
                    on: {
                      focusin: _vm.focusInShow,
                      mousemove: _vm.mouseMoveShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.radioBtn,
                      callback: function ($$v) {
                        _vm.radioBtn = $$v
                      },
                      expression: "radioBtn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "radio-buttons" },
                    on: {
                      focusin: _vm.focusInShow,
                      mousemove: _vm.mouseMoveShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.radioButtons,
                      callback: function ($$v) {
                        _vm.radioButtons = $$v
                      },
                      expression: "radioButtons",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card card-with-shadow border-0 mb-primary" },
          [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "select" },
                    on: {
                      focusin: _vm.focusInShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.selectValue,
                      callback: function ($$v) {
                        _vm.selectValue = $$v
                      },
                      expression: "selectValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "search-select",
                      list: _vm.multiSelectOptionList,
                      placeholder: "Search and select",
                      required: true,
                    },
                    model: {
                      value: _vm.searchSelectValue,
                      callback: function ($$v) {
                        _vm.searchSelectValue = $$v
                      },
                      expression: "searchSelectValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "multi-select",
                      list: _vm.multiSelectOptionList,
                    },
                    model: {
                      value: _vm.multiSelect,
                      callback: function ($$v) {
                        _vm.multiSelect = $$v
                      },
                      expression: "multiSelect",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "multi-create",
                      list: _vm.multiCreateOptionList,
                      "list-value-field": "name",
                      placeholder: "Add variation here",
                      "multi-create-preloader": _vm.multiPreloader,
                    },
                    on: { storeData: _vm.testStoreData },
                    model: {
                      value: _vm.multiCreate,
                      callback: function ($$v) {
                        _vm.multiCreate = $$v
                      },
                      expression: "multiCreate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: {
                      label: "smart-select",
                      type: "smart-select",
                      list: _vm.multiSelectOptionList,
                    },
                    model: {
                      value: _vm.smartSelect,
                      callback: function ($$v) {
                        _vm.smartSelect = $$v
                      },
                      expression: "smartSelect",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card card-with-shadow border-0 mb-primary" },
          [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "time" },
                    model: {
                      value: _vm.testFields.timeValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.testFields, "timeValue", $$v)
                      },
                      expression: "testFields.timeValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "date" },
                    model: {
                      value: _vm.dateValue,
                      callback: function ($$v) {
                        _vm.dateValue = $$v
                      },
                      expression: "dateValue",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card card-with-shadow border-0 mb-primary" },
          [
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "custom-file-upload",
                      label: _vm.$t("change_image"),
                    },
                    model: {
                      value: _vm.customFile,
                      callback: function ($$v) {
                        _vm.customFile = $$v
                      },
                      expression: "customFile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "file", label: "Choose file" },
                    model: {
                      value: _vm.fileValue,
                      callback: function ($$v) {
                        _vm.fileValue = $$v
                      },
                      expression: "fileValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "dropzone" },
                    model: {
                      value: _vm.dropzoneValue,
                      callback: function ($$v) {
                        _vm.dropzoneValue = $$v
                      },
                      expression: "dropzoneValue",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card card-with-shadow border-0 mb-primary" },
          [
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "textarea" },
                    on: {
                      focusin: _vm.focusInShow,
                      focusout: _vm.focusOutShow,
                      click: _vm.showClickEvent,
                      input: _vm.showInputEventValue,
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function ($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-input", {
                    attrs: { type: "text-editor", minimal: true },
                    model: {
                      value: _vm.textEditor,
                      callback: function ($$v) {
                        _vm.textEditor = $$v
                      },
                      expression: "textEditor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [_vm._v("Submit")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.changeData($event)
              },
            },
          },
          [_vm._v("Change Value(s)")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header primary-card-color p-primary" },
      [_c("h4", [_vm._v("General Inputs")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header primary-card-color p-primary" },
      [_c("h4", [_vm._v("Checkbox, Radios & Switches")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header primary-card-color p-primary" },
      [_c("h4", [_vm._v("Selections")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header primary-card-color p-primary" },
      [_c("h4", [_vm._v("Pickers")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header primary-card-color p-primary" },
      [_c("h4", [_vm._v("File Uploads")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header primary-card-color p-primary" },
      [_c("h4", [_vm._v("Text area & Editors")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }