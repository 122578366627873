var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.$t("people"),
                directory: [_vm.$t("contacts"), _vm.$t("people")],
                icon: "users",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c(
            "div",
            { staticClass: "text-sm-right mb-primary mb-sm-0 mb-md-0" },
            [
              _c(
                "div",
                { staticClass: "dropdown d-inline-block btn-dropdown" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success dropdown-toggle ml-0 mr-2",
                      attrs: {
                        type: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("actions")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown-menu" }, [
                    _vm.$can("import_persons")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: { href: _vm.route("persons.import") },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "download",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t("import_people")) +
                                "\n                        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("export_person")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: { href: _vm.route("person.export") },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "download",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t("export_people")) +
                                "\n                        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("create_types")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openContactModal()
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "plus-circle",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t("add_leads_group")) +
                                "\n                        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("view_types")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: { href: _vm.route("contact_type.list") },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "settings",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t("manage_lead_group")) +
                                "\n                        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.$can("create_persons")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-with-shadow",
                      attrs: { type: "button", "data-toggle": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("add_person")) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("app-table", {
        attrs: { id: _vm.tableId, options: _vm.options },
        on: { action: _vm.getAction },
      }),
      _vm._v(" "),
      _vm.isPersonModalActive
        ? _c("app-person-modal", {
            attrs: { "selected-url": _vm.selectedUrl, "table-id": _vm.tableId },
            on: {
              openOrgModal: _vm.openOrganizationModal,
              "close-modal": _vm.closeModal,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSendModalActive
        ? _c("app-person-send-invitation", {
            attrs: { "selected-url": _vm.selectedUrl, "table-id": _vm.tableId },
            on: { "close-modal": _vm.sendInvitationCloseModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isContactModalActive
        ? _c("app-contact-type-modal", {
            attrs: { "table-id": _vm.contacttableId },
            on: { "close-modal": _vm.closeContactModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "person-delete-modal" },
            on: { cancelled: _vm.cancelled, confirmed: _vm.confirmed },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.organizationModal
        ? _c("app-organization-modal", {
            attrs: { "table-id": "organization-modal" },
            on: { "close-modal": _vm.closeOrgModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.personActivitiesModal
        ? _c("app-common-activity-modal", {
            attrs: { activity: _vm.rowData, "selected-url": _vm.editedUrl },
            on: { "close-modal": _vm.closeActivityModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.viewAllDeal
        ? _c("app-common-all-deals", {
            attrs: {
              id: _vm.personId,
              "context-type": "person",
              "table-id": "details-view-modal",
            },
            on: { "close-modal": _vm.closeViewAllModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.viewAllFollower
        ? _c("app-common-all-follower", {
            attrs: { "follower-data": _vm.followerUrl },
            on: { "close-modal": _vm.closedFollowerModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isNoteModal
        ? _c("app-common-note-modal", {
            attrs: { note: _vm.noteRowData },
            on: { "close-modal": _vm.closeNoteModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }