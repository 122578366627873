var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _vm.showFilter
      ? _c(
          "div",
          {
            staticClass:
              "filters-wrapper d-flex justify-content-start flex-wrap",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn d-block d-sm-none btn-toggle-filters",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleFilters($event)
                  },
                },
              },
              [
                _c("app-icon", { attrs: { name: "filter" } }),
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("filters")) + "\n        "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.filters.length > 0
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFiltersOpen,
                        expression: "isFiltersOpen",
                      },
                    ],
                    staticClass: "mobile-filters-wrapper",
                  },
                  [
                    _c("app-filter", {
                      attrs: { "table-id": "demo-table", filters: _vm.filters },
                      on: { "get-values": _vm.getFilterValues },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary btn-with-shadow d-sm-none btn-close-filter-wrapper d-flex justify-content-center align-items-center",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleFilters },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("close")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }