import { render, staticRenderFns } from "./SwitchInput.vue?vue&type=template&id=a1d40f76&"
import script from "./SwitchInput.vue?vue&type=script&lang=js&"
export * from "./SwitchInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\sites\\tm-crm.ru\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a1d40f76')) {
      api.createRecord('a1d40f76', component.options)
    } else {
      api.reload('a1d40f76', component.options)
    }
    module.hot.accept("./SwitchInput.vue?vue&type=template&id=a1d40f76&", function () {
      api.rerender('a1d40f76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/js/core/components/input/SwitchInput.vue"
export default component.exports