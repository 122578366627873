var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "details-view-modal",
        "modal-size": "full-width",
        "modal-alignment": "top",
        "modal-body-class": "p-0",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h4", { staticClass: "ml-4" }, [
          _vm._v(_vm._s(_vm.$t("all_followers"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "p-3" },
          [
            _c("app-table", {
              attrs: { id: "follower-common-table", options: _vm.options },
              on: { action: _vm.getAction },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("close")) + "\n    ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }