var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-radio-group",
      class: _vm.data.radioCheckboxWrapper ? _vm.data.radioCheckboxWrapper : "",
    },
    _vm._l(_vm.data.list, function (item, index) {
      return _c(
        "label",
        {
          key: index,
          class: [
            "customized-radio radio-default",
            _vm.data.customRadioType,
            _vm.data.disabled || item.disabled ? "disabled" : "",
          ],
        },
        [
          _c(
            "input",
            _vm._g(
              {
                ref: "radio",
                refInFor: true,
                staticClass: "radio-inline",
                attrs: {
                  type: _vm.data.type,
                  name: _vm.name,
                  id: _vm.name + "-" + index,
                  required: _vm.data.required,
                  disabled: item.disabled || _vm.data.disabled,
                },
                domProps: { checked: item.id == _vm.value, value: item.id },
              },
              _vm.listeners
            )
          ),
          _vm._v(" "),
          _vm._m(0, true),
          _vm._v(
            "\n        " + _vm._s(item[_vm.data.listValueField]) + "\n    "
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "outside" }, [
      _c("span", { staticClass: "inside" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }