var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: { "modal-scroll": false, "modal-id": "tag-modal" },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("manage_tag"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _c("app-tag-manager", {
            attrs: {
              tags: _vm.tags,
              list: _vm.allTags,
              "list-value-field": "name",
              "tag-preloader": _vm.tagPreloader,
            },
            on: {
              storeTag: _vm.storeAndAttachTag,
              attachTag: _vm.attachTag,
              detachTag: _vm.detachTag,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("close")) + "\n        ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }