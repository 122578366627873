var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-id": "custom-field-modal",
        "modal-size": "default",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.selectedUrl ? _vm.$t("edit") : _vm.$t("add"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              "aria-label": "Close",
              "data-dismiss": "modal",
              type: "button",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c(
          "form",
          {
            ref: "form",
            attrs: {
              "data-url": _vm.selectedUrl
                ? _vm.selectedUrl
                : _vm.route("core.custom-fields.store"),
            },
          },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("custom_field_type")))]),
                _vm._v(" "),
                _c("app-input", {
                  attrs: {
                    list: _vm.customFieldTypeList,
                    "list-value-field": "translated_name",
                    type: "select",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.setCustomFieldType($event)
                    },
                  },
                  model: {
                    value: _vm.formData.custom_field_type_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "custom_field_type_id", $$v)
                    },
                    expression: "formData.custom_field_type_id",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.custom_field_type.choose
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "d-block" }, [
                      _vm._v(_vm._s(_vm.$t("options"))),
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            "" +
                              _vm.$t(
                                "use_comma_separated_values_as_options_of_the_field"
                              )
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("app-input", {
                      attrs: {
                        placeholder: _vm.$t("custom_field_options"),
                        type: "textarea",
                      },
                      model: {
                        value: _vm.formData.meta,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "meta", $$v)
                        },
                        expression: "formData.meta",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("name")))]),
                _vm._v(" "),
                _c("app-input", {
                  attrs: {
                    placeholder: _vm.$t("enter_custom_field_name"),
                    required: true,
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name",
                  },
                }),
                _vm._v(" "),
                _vm.customErrorMessage
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.customErrorMessage)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.errors.name
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.name[0])),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("context")))]),
                _vm._v(" "),
                _c("app-input", {
                  attrs: {
                    list: [
                      { id: "person", value: _vm.$t("person") },
                      { id: "organization", value: _vm.$t("organization") },
                      { id: "deal", value: _vm.$t("deal") },
                    ],
                    type: "select",
                  },
                  model: {
                    value: _vm.formData.context,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "context", $$v)
                    },
                    expression: "formData.context",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("app-input", {
                  attrs: {
                    list: [{ id: 1, value: _vm.$t("show_in_the_data_table") }],
                    type: "checkbox",
                  },
                  model: {
                    value: _vm.formData.in_list,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "in_list", $$v)
                    },
                    expression: "formData.in_list",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { "data-dismiss": "modal", type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cancel")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }