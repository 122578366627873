var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "mb-0",
      attrs: { "data-url": _vm.props.activitySyncUrl },
    },
    [
      _c("div", { staticClass: "mb-primary" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "label",
              { staticClass: "mb-0 col-sm-2 d-flex align-items-center" },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("activity")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("app-input", {
                  attrs: { type: "radio-buttons", list: _vm.activityTypeList },
                  model: {
                    value: _vm.activity_type_id,
                    callback: function ($$v) {
                      _vm.activity_type_id = $$v
                    },
                    expression: "activity_type_id",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "label",
              { staticClass: "mb-0 col-sm-2 d-flex align-items-center" },
              [_vm._v("\n          " + _vm._s(_vm.$t("title")) + "\n        ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("app-input", {
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("enter_title"),
                    required: true,
                  },
                  model: {
                    value: _vm.formData.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "title", $$v)
                    },
                    expression: "formData.title",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("label", { staticClass: "mb-0 col-sm-2 d-flex mt-2" }, [
              _vm._v(_vm._s(_vm.$t("description"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("app-input", {
                  attrs: {
                    type: "textarea",
                    "text-area-rows": 5,
                    placeholder: _vm.$t("description_here"),
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "label",
              { staticClass: "mb-0 col-sm-2 d-flex align-items-center" },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("set_schedule")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-10" }, [
              _c("div", { staticClass: "row mb-1" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-7 pr-lg-0" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "date",
                        placeholder: _vm.$t("start_date"),
                      },
                      on: { input: _vm.setEndDateAsStartDate },
                      model: {
                        value: _vm.formData.started_at,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "started_at", $$v)
                        },
                        expression: "formData.started_at",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-5 pl-lg-0 time-picker-dd-pos" },
                  [
                    _c("app-input", {
                      attrs: { type: "time" },
                      on: {
                        input: function ($event) {
                          return _vm.setStart($event)
                        },
                      },
                      model: {
                        value: _vm.startTime,
                        callback: function ($$v) {
                          _vm.startTime = $$v
                        },
                        expression: "startTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-7 pr-lg-0" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "date",
                        "min-date": _vm.formData.started_at,
                        placeholder: _vm.$t("end_date"),
                      },
                      model: {
                        value: _vm.formData.ended_at,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "ended_at", $$v)
                        },
                        expression: "formData.ended_at",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-5 pl-lg-0 time-picker-dd-pos" },
                  [
                    _c("app-input", {
                      attrs: { type: "time" },
                      model: {
                        value: _vm.endTime,
                        callback: function ($$v) {
                          _vm.endTime = $$v
                        },
                        expression: "endTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "label",
              { staticClass: "mb-0 col-sm-2 d-flex align-items-center" },
              [_vm._v(_vm._s(_vm.$t("participants")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("app-input", {
                  attrs: {
                    type: "multi-select",
                    list: _vm.personList,
                    "list-value-field": "name",
                    "is-animated-dropdown": true,
                  },
                  model: {
                    value: _vm.formData.person_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "person_id", $$v)
                    },
                    expression: "formData.person_id",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "label",
              {
                staticClass:
                  "mb-0 col-sm-2 d-flex align-items-center text-break",
              },
              [_vm._v(_vm._s(_vm.$t("collaborators")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("app-input", {
                  attrs: {
                    type: "multi-select",
                    list: _vm.ownerList,
                    "list-value-field": "full_name",
                    "is-animated-dropdown": true,
                  },
                  model: {
                    value: _vm.formData.owner_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "owner_id", $$v)
                    },
                    expression: "formData.owner_id",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "label",
              { staticClass: "mb-0 col-sm-2 d-flex align-items-center" },
              [_vm._v(_vm._s(_vm.$t("save_as_done")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-2 col-sm-10" },
              [
                _c("app-input", {
                  attrs: {
                    type: "single-checkbox",
                    "list-value-field": _vm.$t(" "),
                  },
                  model: {
                    value: _vm.formData.activity_done,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "activity_done", $$v)
                    },
                    expression: "formData.activity_done",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-primary px-primary border-top mx-minus-primary" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.submitData($event)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "w-100" },
                [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                1
              ),
              _vm._v(" "),
              !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }