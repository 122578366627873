var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "card card-with-shadow border-0" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("app-chart", {
            attrs: {
              type: "bubble-chart",
              title: "Bubble Chart",
              "data-sets": _vm.dataSets,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }