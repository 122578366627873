var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      staticClass: "reason-modal-size",
      attrs: {
        "modal-id": "lost-reason-modal",
        "modal-size": "default",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(_vm.selectedUrl ? _vm.$t("edit") : _vm.$t("add")) +
              " " +
              _vm._s(_vm.$t("lost_reason_lowercase"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c(
          "form",
          {
            ref: "form",
            attrs: {
              "data-url": _vm.selectedUrl
                ? _vm.selectedUrl
                : _vm.route("lost-reasons.store"),
            },
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "mb-0 col-sm-3 d-flex align-items-center" },
                  [_c("label", [_vm._v(_vm._s(_vm.$t("name")))])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-9" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("enter_name"),
                        name: "lost_reason",
                        required: true,
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "lost_reason"
                        ),
                      },
                      model: {
                        value: _vm.formData.lost_reason,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "lost_reason", $$v)
                        },
                        expression: "formData.lost_reason",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }