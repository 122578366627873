import { render, staticRenderFns } from "./Activity.vue?vue&type=template&id=27cff82c&scoped=true&"
import script from "./Activity.vue?vue&type=script&lang=js&"
export * from "./Activity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27cff82c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\sites\\tm-crm.ru\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27cff82c')) {
      api.createRecord('27cff82c', component.options)
    } else {
      api.reload('27cff82c', component.options)
    }
    module.hot.accept("./Activity.vue?vue&type=template&id=27cff82c&scoped=true&", function () {
      api.rerender('27cff82c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/js/crm/Component/Views/Profile/Activity.vue"
export default component.exports