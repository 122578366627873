var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card border-0 card-with-shadow" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
      },
      [
        _c(
          "h5",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDetailsActive,
                expression: "isDetailsActive",
              },
            ],
            staticClass: "card-title text-muted mb-0",
          },
          [_vm._v(_vm._s(_vm.$t("details")))]
        ),
        _vm._v(" "),
        _c(
          "h5",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isDetailsActive,
                expression: "!isDetailsActive",
              },
            ],
            staticClass: "card-title text-muted mb-0",
          },
          [_vm._v(_vm._s(_vm.$t("edit_details")))]
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isDetailsActive,
                  expression: "!isDetailsActive",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.detailsInfoClose($event)
                },
              },
            },
            [
              _c("app-icon", {
                attrs: { name: "x-square", "stroke-width": "1" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isDetailsActive,
                  expression: "!isDetailsActive",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.detailsInfoSync($event)
                },
              },
            },
            [
              _c("app-icon", {
                attrs: { name: "check-square", "stroke-width": "1" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isDetailsActive,
                  expression: "isDetailsActive",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.detailsInfoEdit($event)
                },
              },
            },
            [_c("app-icon", { attrs: { name: "edit", "stroke-width": "1" } })],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isDetailsActive,
              expression: "isDetailsActive",
            },
          ],
        },
        [
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "label",
                {
                  staticClass:
                    "mb-0 text-muted col-3 d-flex align-items-center",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.$t("name")) +
                      "\n\t\t\t\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-9" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\t" +
                    _vm._s(_vm.detailsData.name) +
                    "\n\t\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "label",
                {
                  staticClass:
                    "mb-0 text-muted col-3 d-flex align-items-center",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.$t("lead_group")) +
                      "\n\t\t\t\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-9" }, [
                _vm.detailsData.contact_type
                  ? _c(
                      "span",
                      {
                        class:
                          "badge badge-sm badge-pill badge-" +
                          _vm.detailsData.contact_type.class,
                      },
                      [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.detailsData.contact_type.name) +
                            "\n                                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-0" }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "label",
                {
                  staticClass:
                    "mb-0 text-muted col-3 d-flex align-items-center",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.$t("owner")) +
                      "\n\t\t\t\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-9" }, [
                _vm.detailsData.owner
                  ? _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("app-avatar", {
                          staticClass: "mr-2",
                          attrs: {
                            "avatar-class": "avatars-w-30",
                            img: _vm.detailsData.owner.profile_picture
                              ? _vm.urlGenerator(
                                  _vm.detailsData.owner.profile_picture.path
                                )
                              : _vm.detailsData.owner.profile_picture,
                            title: _vm.detailsData.owner.full_name,
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailsData.owner.full_name)),
                        ]),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        { staticClass: "font-size-90 text-secondary" },
                        [_vm._v(" " + _vm._s(_vm.$t("user_deleted")) + " ")]
                      ),
                    ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isDetailsActive,
              expression: "!isDetailsActive",
            },
          ],
        },
        [
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "label",
                { staticClass: "col-3 mb-0 d-flex align-items-center" },
                [_vm._v(_vm._s(_vm.$t("name")))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-9" },
                [
                  _c("app-input", {
                    attrs: {
                      "error-message": _vm.$errorMessage(_vm.errors, "name"),
                      type: "text",
                    },
                    model: {
                      value: _vm.detailsData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailsData, "name", $$v)
                      },
                      expression: "detailsData.name",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-3" }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "label",
                { staticClass: "col-3 mb-0 d-flex align-items-center" },
                [_vm._v(_vm._s(_vm.$t("lead_group")))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-9" },
                [
                  _c("app-input", {
                    attrs: {
                      "error-message": _vm.$errorMessage(
                        _vm.errors,
                        "contact_type_id"
                      ),
                      list: _vm.contentTypeList,
                      "list-value-field": "name",
                      type: "select",
                    },
                    model: {
                      value: _vm.detailsData.contact_type_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailsData, "contact_type_id", $$v)
                      },
                      expression: "detailsData.contact_type_id",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.$can("manage_public_access")
            ? _c("div", { staticClass: "form-group mb-0" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "label",
                    { staticClass: "col-3 mb-0 d-flex align-items-center" },
                    [_vm._v(_vm._s(_vm.$t("owner")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-9" },
                    [
                      _c("app-input", {
                        attrs: {
                          "error-message": _vm.$errorMessage(
                            _vm.errors,
                            "owner_id"
                          ),
                          list: _vm.ownerList,
                          "list-value-field": "full_name",
                          type: "select",
                        },
                        model: {
                          value: _vm.detailsData.owner_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailsData, "owner_id", $$v)
                          },
                          expression: "detailsData.owner_id",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }