var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "position-absolute overlay-loader-wrapper" },
      [
        _c(
          "span",
          {
            staticClass:
              "spinner-bounce d-flex align-items-center justify-content-center h-100",
          },
          [
            _c("span", { staticClass: "bounce1 mr-1" }),
            _vm._v(" "),
            _c("span", { staticClass: "bounce2 mr-1" }),
            _vm._v(" "),
            _c("span", { staticClass: "bounce3 mr-1" }),
            _vm._v(" "),
            _c("span", { staticClass: "bounce4" }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }