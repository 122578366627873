var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "card card-with-shadow border-0",
        staticStyle: { "min-height": "400px" },
      },
      [
        _c(
          "div",
          { staticClass: "card-body p-primary" },
          [
            _c("app-calendar", {
              attrs: { preloader: _vm.preloader, options: _vm.options },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }