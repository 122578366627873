var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-breadcrumb", {
    attrs: {
      "page-title": _vm.pageTitle,
      directory: _vm.directory,
      icon: _vm.icon,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }