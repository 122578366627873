var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "send-proposal-modal",
        "modal-size": "default",
        "modal-scroll": false,
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("send_proposal"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.dataLoaded
            ? _c(
                "form",
                { ref: "form", attrs: { "data-url": _vm.submitUrl } },
                [
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c("h4", { staticClass: "great-text" }, [
                            _vm._v(_vm._s(_vm.$t("great"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c("h5", [
                            _vm._v(
                              _vm._s(_vm.$t("you_are_going_to_send_a_proposal"))
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c("label", [_vm._v(_vm._s(_vm.$t("deal_name")))]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-12" },
                          [
                            _c(
                              "div",
                              { staticClass: "mb-3" },
                              [
                                _c("app-input", {
                                  attrs: {
                                    type: "search-select",
                                    "list-value-field": "title",
                                    list: _vm.dealList,
                                  },
                                  model: {
                                    value: _vm.formData.deal_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "deal_id", $$v)
                                    },
                                    expression: "formData.deal_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.deal
                              ? [
                                  _vm.deal.contact_person.length > 0
                                    ? [
                                        !_vm.checkContactPersonEmail
                                          ? [
                                              _c("app-note", {
                                                attrs: {
                                                  notes: _vm.noteMessage,
                                                  "show-title": false,
                                                  "content-type": "html",
                                                  "padding-class": "p-2",
                                                  title: "'send'",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ]
                                    : [
                                        _c("app-note", {
                                          attrs: {
                                            notes: [
                                              _vm.$t(
                                                "the_deal_has_no_contact_person_with_email"
                                              ),
                                            ],
                                            "show-title": false,
                                            "padding-class": "p-2",
                                            title: "'send'",
                                          },
                                        }),
                                      ],
                                ]
                              : [
                                  _c("app-note", {
                                    attrs: {
                                      notes: [
                                        _vm.$t("no_deal", "There is no deal"),
                                      ],
                                      "show-title": false,
                                      "content-type": "html",
                                      "padding-class": "p-2",
                                      title: "'send'",
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.emailList.length > 1
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("choose_an_email"))),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c(
                                "div",
                                { staticClass: "mb-3" },
                                [
                                  _c("app-input", {
                                    attrs: {
                                      type: "select",
                                      "list-value-field": "value",
                                      placeholder: _vm.$t("choose_an_email"),
                                      list: _vm.emailList,
                                      required: true,
                                    },
                                    model: {
                                      value: _vm.formData.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "email", $$v)
                                      },
                                      expression: "formData.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                ],
                2
              )
            : _c("app-overlay-loader"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dataLoaded
        ? _c("template", { slot: "footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-2",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeModal($event)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("cancel")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  disabled:
                    !_vm.deal ||
                    !_vm.deal.contact_person.length ||
                    !_vm.checkContactPersonEmail
                      ? true
                      : false,
                  type: "button",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.confirm($event)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "w-100" },
                  [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
                  1
                ),
                _vm._v(" "),
                !_vm.loading ? [_vm._v(_vm._s(_vm.$t("confirm")))] : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }