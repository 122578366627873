var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "role-modal",
        "modal-size": "default",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _vm.modalTitle
          ? _c("h5", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.modalTitle)),
            ])
          : _c("h5", { staticClass: "modal-title" }, [
              _vm._v(
                _vm._s(_vm.selectedUrl ? _vm.$t("edit") : _vm.$t("add")) +
                  " " +
                  _vm._s(_vm.$t("role_lowercase"))
              ),
            ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader
            ? _c("app-overlay-loader")
            : _c(
                "form",
                {
                  ref: "form",
                  attrs: {
                    "data-url": _vm.selectedUrl
                      ? _vm.selectedUrl
                      : _vm.route("core.roles.store"),
                  },
                },
                [
                  _vm.modalTitle === ""
                    ? [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.$t("role_name")))]),
                            _vm._v(" "),
                            _c("app-input", {
                              attrs: {
                                placeholder: _vm.$t("role_name"),
                                required: true,
                              },
                              model: {
                                value: _vm.formData.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "name", $$v)
                                },
                                expression: "formData.name",
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.name
                              ? _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.errors.name[0])),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group text-center" }, [
                          _c("small", [
                            _vm._v(
                              _vm._s(_vm.$t("click_on_the_group_to_expand"))
                            ),
                          ]),
                        ]),
                      ]
                    : [
                        _c("app-input", {
                          attrs: {
                            placeholder: _vm.$t("role_name"),
                            required: true,
                            hidden: _vm.modalTitle ? true : false,
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "name", $$v)
                            },
                            expression: "formData.name",
                          },
                        }),
                        _vm._v(" "),
                        _c("label", [_vm._v(_vm._s(_vm.$t("permission")))]),
                      ],
                  _vm._v(" "),
                  Object.keys(_vm.data.permissions).length
                    ? _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          {
                            staticClass: "accordion",
                            attrs: { id: "accordionExample" },
                          },
                          _vm._l(
                            Object.keys(_vm.data.permissions),
                            function (permission, index) {
                              return _c("div", { staticClass: "card" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-header border-0" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer",
                                        attrs: {
                                          "data-toggle": "collapse",
                                          "data-target": "#" + permission,
                                          "aria-expanded": "false",
                                          "aria-expanded":
                                            "" +
                                            (_vm.checkForVisibility(
                                              index,
                                              permission
                                            )
                                              ? true
                                              : false),
                                          "aria-controls": "permission",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "customized-checkbox checkbox-default",
                                          },
                                          [
                                            _vm.loadChecked
                                              ? _c("input", {
                                                  ref: "checkbox",
                                                  refInFor: true,
                                                  attrs: {
                                                    type: "checkbox",
                                                    name:
                                                      "single-checkbox-" +
                                                      permission,
                                                    id:
                                                      "single-checkbox-" +
                                                      permission,
                                                    disabled: _vm.formData
                                                      .type_id
                                                      ? true
                                                      : false,
                                                  },
                                                  domProps: {
                                                    value: permission,
                                                    checked:
                                                      _vm.ifChecked(permission),
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.checkGroup(
                                                        $event,
                                                        permission
                                                      )
                                                    },
                                                    click: function ($event) {
                                                      return $event.stopPropagation()
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  for:
                                                    "single-checkbox-" +
                                                    permission,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(_vm.$t(permission)) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class:
                                      "collapse " +
                                      (_vm.checkForVisibility(index, permission)
                                        ? "show"
                                        : ""),
                                    attrs: {
                                      id: permission,
                                      "data-parent": "#accordionExample",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card-body p-primary" },
                                      [
                                        _vm.loaded
                                          ? _c("app-input", {
                                              attrs: {
                                                type: "checkbox",
                                                list: _vm.data.permissions[
                                                  permission
                                                ],
                                                disabled: _vm.formData.type_id
                                                  ? true
                                                  : false,
                                                "list-value-field":
                                                  "translated_name",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkPermissions(
                                                    permission
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.checkedPermissions[
                                                    permission
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkedPermissions,
                                                    permission,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkedPermissions[permission]",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }