var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.rowData.contextable_type === "App\\Models\\CRM\\Deal\\Deal"
      ? _c(
          "a",
          {
            attrs: {
              href: _vm.route("deal_details.page", {
                id: _vm.rowData.contextable_id,
              }),
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.rowData.title) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.rowData.contextable_type === "App\\Models\\CRM\\Person\\Person"
      ? _c(
          "a",
          {
            attrs: {
              href: _vm.route("persons.edit", {
                id: _vm.rowData.contextable_id,
              }),
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.rowData.title) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.rowData.contextable_type ===
    "App\\Models\\CRM\\Organization\\Organization"
      ? _c(
          "a",
          {
            attrs: {
              href: _vm.route("organizations.edit", {
                id: _vm.rowData.contextable_id,
              }),
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.rowData.title) + "\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }