var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media d-flex align-items-center" }, [
    _c(
      "div",
      { staticClass: "avatars-w-50" },
      [
        _c("app-avatar", {
          attrs: {
            title: _vm.fullName,
            status: "success",
            shadow: true,
            img: _vm.$optional(_vm.value, "path"),
            "alt-text": _vm.fullName,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "media-body ml-3" }, [
      _vm._v("\n        " + _vm._s(_vm.fullName) + "\n        "),
      _c("p", { staticClass: "text-muted font-size-90 mb-0" }, [
        _vm._v(_vm._s(_vm.rowData.email)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }