var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper reports" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                directory: [
                  _vm.$t("reports"),
                  _vm.$t("proposal"),
                  _vm.breadcrumb,
                ],
                icon: "bar-chart",
                "page-title": _vm.breadcrumb,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isActivePrimaryFilters
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6 col-sm-8 col-md-9 col-lg-10 col-xl-10" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "filters-wrapper d-flex justify-content-start flex-wrap",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn d-block d-sm-none btn-toggle-filters",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleFilters($event)
                          },
                        },
                      },
                      [
                        _c("app-icon", { attrs: { name: "filter" } }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("filters")) +
                            "\n                "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isFiltersOpen
                      ? _c(
                          "span",
                          { staticClass: "mobile-filters-wrapper" },
                          [
                            _c("app-filter", {
                              attrs: {
                                filters: _vm.filters,
                                "table-id": _vm.tableId,
                              },
                              on: { "get-values": _vm.getFilterValues },
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-primary btn-with-shadow d-sm-none btn-close-filter-wrapper d-flex justify-content-center align-items-center",
                                attrs: { type: "button" },
                                on: { click: _vm.toggleFilters },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("close")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.dataLoaded
        ? _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "col-12 mb-primary" }, [
                _c(
                  "div",
                  { staticClass: "card card-with-shadow border-0 h-100" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-body pt-5" },
                      [
                        _c("app-chart", {
                          attrs: {
                            "data-sets": _vm.dataSetReportsChart,
                            height: 380,
                            labels: _vm.labelsReportsChart,
                            type: "horizontal-line-chart",
                          },
                        }),
                        _vm._v(" "),
                        _c("hr", { staticClass: "my-5 reports-table-divider" }),
                        _vm._v(" "),
                        _c("app-table", {
                          attrs: {
                            id: _vm.tableId,
                            "filtered-data": _vm.filterValues,
                            options: _vm.options,
                            search: _vm.searchValue,
                          },
                          on: { action: _vm.triggerActions },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.detailsViewModal
                ? _c("proposal-details", {
                    attrs: {
                      id: _vm.detailsViewId,
                      filterValues: _vm.filterValues,
                      fullName: _vm.fullName,
                      "modal-id": "details-view-modal",
                      proposalSentStatusId: _vm.proposalSentStatusId,
                      "table-id": "details-view-modal",
                    },
                    on: { "close-modal": _vm.closedDetailsViewModal },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("app-overlay-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }