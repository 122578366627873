var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "mb-0" }, [
    _c("div", { staticClass: "mb-primary" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "form-row" }, [
          _c("label", { staticClass: "col-3 mb-0 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("call_delivery_time")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-9" },
            [
              _c("app-input", {
                attrs: {
                  type: "time",
                  placeholder: _vm.$t("choose_your_call_delivery_time"),
                },
                model: {
                  value: _vm.callDeliveryTime,
                  callback: function ($$v) {
                    _vm.callDeliveryTime = $$v
                  },
                  expression: "callDeliveryTime",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "form-row" }, [
          _c("label", { staticClass: "col-3 mb-0 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("duration")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-9" },
            [
              _c("app-input", {
                attrs: { type: "select", list: _vm.demoList },
                model: {
                  value: _vm.callDuration,
                  callback: function ($$v) {
                    _vm.callDuration = $$v
                  },
                  expression: "callDuration",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0" }, [
        _c("div", { staticClass: "form-row" }, [
          _c("label", { staticClass: "col-3 mb-0 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("contact_person")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-9" },
            [
              _c("app-input", {
                attrs: { type: "select", list: _vm.demoList },
                model: {
                  value: _vm.contactPerson,
                  callback: function ($$v) {
                    _vm.contactPerson = $$v
                  },
                  expression: "contactPerson",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-primary px-primary border-top mx-minus-primary" },
      [
        _c(
          "button",
          { staticClass: "btn btn-primary mr-1", attrs: { type: "button" } },
          [_vm._v(_vm._s(_vm.$t("save")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-secondary", attrs: { type: "button" } },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }