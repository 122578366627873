var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-navbar",
    {
      attrs: {
        "logo-url": _vm.logoUrl,
        user: _vm.user,
        "selected-language": "EN",
        "language-data": _vm.languageData,
        notificationData: _vm.notificationData,
        "all-notification-url": _vm.allNotificationUrl,
        "profile-data": _vm.profileData,
      },
      on: { clicked: _vm.clickedNotification },
    },
    [
      _c("template", { slot: "center-option" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("button", { staticClass: "btn btn-outline-primary" }, [
            _vm._v("Center"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "left-option" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("button", { staticClass: "btn btn-outline-primary" }, [
            _vm._v("Left"),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }