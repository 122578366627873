var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": "notification-setting-modal",
        "modal-size": "default",
        "modal-alignment": "top",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(_vm.$t("settings")) +
              " : " +
              _vm._s(_vm.eventInformation.translated_name)
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.dataLoaded
            ? _c("app-overlay-loader")
            : _c(
                "form",
                { ref: "form", attrs: { "data-url": "" + _vm.selectedUrl } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("h6", [
                            _vm._v(_vm._s(_vm.$t("notification_channel"))),
                          ]),
                          _vm._v(" "),
                          _c("app-input", {
                            attrs: {
                              type: "multi-select",
                              list: _vm.makeChannelArr,
                              "list-value-field": "name",
                              required: true,
                              isAnimatedDropdown: true,
                            },
                            model: {
                              value: _vm.settings.notify_by,
                              callback: function ($$v) {
                                _vm.$set(_vm.settings, "notify_by", $$v)
                              },
                              expression: "settings.notify_by",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12" }, [
                      _c("h6", [
                        _vm._v(_vm._s(_vm.$t("notification_audiences"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("roles")))]),
                        _c("br"),
                        _vm._v(" "),
                        _c("app-input", {
                          attrs: {
                            type: "multi-select",
                            list: _vm.roleList,
                            "list-value-field": "name",
                            required: true,
                            isAnimatedDropdown: true,
                          },
                          model: {
                            value: _vm.settings.roles,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "roles", $$v)
                            },
                            expression: "settings.roles",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("users")))]),
                        _c("br"),
                        _vm._v(" "),
                        _c("app-input", {
                          attrs: {
                            type: "multi-select",
                            list: _vm.userList,
                            "list-value-field": "full_name",
                            isAnimatedDropdown: true,
                          },
                          model: {
                            value: _vm.settings.users,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "users", $$v)
                            },
                            expression: "settings.users",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { type: "button", "data-dismiss": "modal" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal($event)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submitData($event)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "w-100" },
              [_vm.loading ? _c("app-submit-button-loader") : _vm._e()],
              1
            ),
            _vm._v(" "),
            !_vm.loading ? [_vm._v(_vm._s(_vm.$t("save")))] : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }