var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6 col-sm-8 col-md-9 col-lg-9 col-xl-9" }, [
        _vm.options.showFilter
          ? _c(
              "div",
              {
                staticClass:
                  "filters-wrapper d-flex justify-content-start flex-wrap",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn d-block d-sm-none btn-toggle-filters",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleFilters($event)
                      },
                    },
                  },
                  [
                    _c("app-icon", { attrs: { name: "filter" } }),
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("filters")) + "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFiltersOpen,
                        expression: "isFiltersOpen",
                      },
                    ],
                    staticClass: "mobile-filters-wrapper",
                  },
                  [
                    _c("app-filter", {
                      attrs: {
                        filters: _vm.options.filters,
                        "table-id": "activity-calendar",
                      },
                      on: { "get-values": _vm.getFilterValues },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary btn-with-shadow d-sm-none btn-close-filter-wrapper d-flex justify-content-center align-items-center",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleFilters },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("close")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-items-center single-filter ml-3",
                  },
                  [
                    _vm.visibleClearFilter
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.clearAllFilter($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("clear_all_filters")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" }, [
        _vm.options.showSearch
          ? _c(
              "div",
              { staticClass: "mr-0 single-filter single-search-wrapper" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group form-group-with-search d-flex align-items-center justify-content-end",
                  },
                  [_c("app-search", { on: { input: _vm.getSearchValue } })],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "card card-with-shadow border-0",
        staticStyle: { "min-height": "400px" },
      },
      [
        _c(
          "div",
          { staticClass: "card-body p-primary" },
          [
            _c("app-calendar", {
              attrs: {
                preloader: !_vm.dataLoaded,
                options: _vm.prepareCalendarOptions,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }