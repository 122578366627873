var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-avatars-group", {
    attrs: {
      "avatars-group-class": _vm.avatarsGroupClass,
      name: _vm.avatarsGroupName + _vm.index,
      shadow: true,
      border: true,
      avatars: _vm.avatars,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }