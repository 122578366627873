var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card shadow deal-preview-card border-0 min-height-300 h-100",
    },
    [
      _c("div", { staticClass: "card-body position-relative h-100" }, [
        _vm.showAction && _vm.visibleActions.length > 0
          ? _c(
              "div",
              { staticClass: "dropdown options-dropdown position-absolute" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn-option btn d-flex align-items-center justify-content-center action-button-width",
                    attrs: { type: "button", "data-toggle": "dropdown" },
                  },
                  [
                    _c("app-icon", {
                      staticClass: "size-16",
                      attrs: { name: "more-horizontal" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu dropdown-menu-right py-2 mt-1",
                  },
                  _vm._l(_vm.visibleActions, function (action, index) {
                    return _c(
                      "a",
                      {
                        key: index,
                        staticClass: "dropdown-item px-4 py-2",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.callAction(action)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(action.title) + "\n        "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-column justify-content-between h-100" },
          [
            _c(
              "div",
              { staticClass: "card-heading d-flex justify-content-start" },
              [
                _c("app-avatar", {
                  attrs: {
                    "avatar-class": "avatars-w-20",
                    title: "John Doe",
                    img: "/images/profile.png",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ml-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "d-block mb-2",
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openDealDetailsModal($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.deal.title) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      class:
                        "badge badge-" +
                        (_vm.deal.status.class
                          ? _vm.deal.status.class
                          : "secondary") +
                        "\n                       badge-sm rounded-pill font-weight-normal",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.deal.status.translated_name) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center mt-3",
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("app-avatar", {
                        attrs: {
                          "avatar-class": "avatars-w-20",
                          title: _vm.deal.owner.full_name,
                          img: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted mb-0 ml-2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.deal.owner.full_name) +
                            "\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0 text-primary text-nowrap" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.numberWithCurrencySymbol(_vm.deal.value)) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mt-3" },
                [
                  _c("app-icon", {
                    staticClass: "size-20 text-muted",
                    attrs: { name: "briefcase" },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0 text-muted ml-2" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.deal.contextable_type ===
                            "App\\Models\\CRM\\Organization\\Organization"
                            ? _vm.deal.contextable
                              ? _vm.deal.contextable.name
                              : _vm.$t("not_added_yet")
                            : _vm.$t("not_added_yet")
                        ) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mt-3" },
                [
                  _c("app-icon", {
                    staticClass: "size-20 text-muted",
                    attrs: { name: "calendar" },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0 text-muted ml-2" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.formatDateTimeToLocal(_vm.deal.created_at)) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "mx-minus-primary my-3" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-start flex-wrap" },
                [
                  _c("div", { staticClass: "d-flex align-items-center mr-2" }, [
                    _c("p", { staticClass: "text-muted mb-0" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("comment")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "badge badge-deal-card font-weight-normal ml-2",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.deal.discussions.length
                                ? _vm.deal.discussions.length
                                : 0
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("p", { staticClass: "text-muted mb-0" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("proposal")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "badge badge-deal-card font-weight-normal ml-2",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.deal.proposals_count) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }