var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper", staticStyle: { "min-height": "200px" } },
    [
      _c(
        "div",
        { staticClass: "pb-4" },
        [
          _c("app-tab", {
            attrs: { type: "horizontal", tabs: _vm.horizontalTabs },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [_c("app-tab", { attrs: { tabs: _vm.tabs, icon: _vm.icon } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }