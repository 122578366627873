var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-5" }, [
    _c("h2", { staticClass: "text-center text-capitalize mb-primary" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.$t("install")) +
          " " +
          _vm._s(_vm.appName) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card card-with-shadow border-0 mb-primary" }, [
      _c("div", { staticClass: "card-header bg-transparent p-primary" }, [
        _c(
          "h5",
          { staticClass: "card-title mb-0 d-flex align-items-center " },
          [
            _c("app-icon", {
              staticClass: "primary-text-color mr-2",
              attrs: { name: "user" },
            }),
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("broadcasting_configuration")) +
                "\n            "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group row" }, [
          _c("label", { staticClass: "col-md-3 d-flex align-items-center" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("broadcast_driver")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-9" },
            [
              _c("app-input", {
                attrs: {
                  id: "provider",
                  type: "select",
                  list: _vm.providerList,
                  required: true,
                },
                model: {
                  value: _vm.environment.broadcast_driver,
                  callback: function ($$v) {
                    _vm.$set(_vm.environment, "broadcast_driver", $$v)
                  },
                  expression: "environment.broadcast_driver",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.environment.broadcast_driver === "pusher"
          ? _c("fieldset", [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-md-3 d-flex align-items-center" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("pusher_app_id")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-9" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("pusher_app_id"),
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "pusher_app_id"
                        ),
                      },
                      model: {
                        value: _vm.environment.pusher_app_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "pusher_app_id", $$v)
                        },
                        expression: "environment.pusher_app_id",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-md-3 d-flex align-items-center" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("pusher_app_key")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-9" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("pusher_app_key"),
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "pusher_app_key"
                        ),
                      },
                      model: {
                        value: _vm.environment.pusher_app_key,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "pusher_app_key", $$v)
                        },
                        expression: "environment.pusher_app_key",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-md-3 d-flex align-items-center" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("pusher_app_secret")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-9" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("pusher_app_secret"),
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "pusher_app_secret"
                        ),
                      },
                      model: {
                        value: _vm.environment.pusher_app_secret,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "pusher_app_secret", $$v)
                        },
                        expression: "environment.pusher_app_secret",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-md-3 d-flex align-items-center" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("pusher_app_cluster")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-9" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("pusher_app_cluster"),
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "pusher_app_cluster"
                        ),
                      },
                      model: {
                        value: _vm.environment.pusher_app_cluster,
                        callback: function ($$v) {
                          _vm.$set(_vm.environment, "pusher_app_cluster", $$v)
                        },
                        expression: "environment.pusher_app_cluster",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group mt-5" },
      [
        _c("app-submit-button", {
          attrs: {
            loading: _vm.loading,
            "btn-class": "btn-block",
            label: _vm.$t("save_&_next"),
          },
          on: { click: _vm.submitData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }