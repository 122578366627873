var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border-bottom mb-4 pb-4" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between" },
      [
        _c("p", { staticClass: "mb-2 font-weight-bold" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("expected_closing_date")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEditExpireDate,
                  expression: "!isEditExpireDate",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closeDateEDit($event)
                },
              },
            },
            [
              _c("app-icon", {
                staticClass: "size-20",
                attrs: { name: "x-square", "stroke-width": "1" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEditExpireDate,
                  expression: "!isEditExpireDate",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.updateExpireDate($event)
                },
              },
            },
            [
              _c("app-icon", {
                staticClass: "size-20",
                attrs: { name: "check-square", "stroke-width": "1" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isEditExpireDate && _vm.clientAccess && _vm.dealStatus,
                expression: "isEditExpireDate && clientAccess && dealStatus",
              },
            ],
            staticClass: "text-muted",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.editExpireDate($event)
              },
            },
          },
          [
            _c("app-icon", {
              staticClass: "size-20",
              attrs: { name: "edit", "stroke-width": "1" },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEditExpireDate,
            expression: "isEditExpireDate",
          },
        ],
        staticClass: "mb-0 font-size-90 text-muted",
      },
      [
        _c("app-icon", {
          staticClass: "text-primary size-15",
          attrs: { name: "calendar" },
        }),
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.formData.expired_at
                ? _vm.formatDateToLocal(_vm.formData.expired_at)
                : _vm.$t("not_added_yet")
            ) +
            "\n    "
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEditExpireDate,
            expression: "!isEditExpireDate",
          },
        ],
        staticClass: "col-sm-12 p-0",
      },
      [
        _c("app-input", {
          attrs: {
            type: "date",
            placeholder: _vm.$t("choose_a_date"),
            "popover-position": "top-start",
            "error-message": _vm.$errorMessage(_vm.errors, "expired_at"),
          },
          model: {
            value: _vm.formData.expired_at,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "expired_at", $$v)
            },
            expression: "formData.expired_at",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }