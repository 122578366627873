var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-table", {
        attrs: { id: _vm.tableId, options: _vm.options },
        on: { action: _vm.getAction },
      }),
      _vm._v(" "),
      _vm.isCheckMailModalActive
        ? _c("app-check-email-deliver", {
            attrs: { "header-title": _vm.$t("send_proposal") },
            on: { "close-modal": _vm.closeModalCheckMail },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSendProposalModalActive
        ? _c("app-send-proposal-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "selected-url": _vm.selectUrl,
              "selected-deal": _vm.proposalDeal,
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "proposal-delete-modal" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isPreviewModalActive
        ? _c("app-proposal-preview-modal", {
            attrs: {
              content: _vm.selectedProposalContent,
              title: _vm.selectProposalTitle,
              modalId: "proposal-preview",
            },
            on: {
              close: function ($event) {
                _vm.isPreviewModalActive = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }