var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border-bottom mb-4 pb-4" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between" },
      [
        _c("p", { staticClass: "mb-2 font-weight-bold" }, [
          _vm._v("Deal Value"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEditDealValue,
                  expression: "!isEditDealValue",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closeValueEDit($event)
                },
              },
            },
            [
              _c("app-icon", {
                staticClass: "size-20",
                attrs: { name: "x-square", "stroke-width": "1" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEditDealValue,
                  expression: "!isEditDealValue",
                },
              ],
              staticClass: "text-muted",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.updateDealValue($event)
                },
              },
            },
            [
              _c("app-icon", {
                staticClass: "size-20",
                attrs: { name: "check-square", "stroke-width": "1" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isEditDealValue && _vm.clientAccess && _vm.dealStatus,
                expression: "isEditDealValue && clientAccess && dealStatus",
              },
            ],
            staticClass: "text-muted",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.editDealValue($event)
              },
            },
          },
          [
            _c("app-icon", {
              staticClass: "size-20",
              attrs: { name: "edit", "stroke-width": "1" },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEditDealValue,
            expression: "isEditDealValue",
          },
        ],
        staticClass: "mb-0 font-size-90 text-muted",
      },
      [
        _c("app-icon", {
          staticClass: "text-primary size-15",
          attrs: { name: "dollar-sign" },
        }),
        _vm._v("\n        " + _vm._s(_vm.formData.value) + "\n    "),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEditDealValue,
            expression: "!isEditDealValue",
          },
        ],
        staticClass: "col-sm-12 p-0",
      },
      [
        _c("app-input", {
          attrs: {
            type: "text",
            placeholder: _vm.$t("enter_deal_value"),
            required: true,
          },
          model: {
            value: _vm.formData.value,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "value", $$v)
            },
            expression: "formData.value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }