var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.personOrgData, function (item, index) {
        return index < 2
          ? _c(
              "div",
              [
                _vm.$can("update_persons")
                  ? [
                      _vm.rowData.persons
                        ? [
                            _vm.adminAccess(item)
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "mb-1 d-flex",
                                    attrs: {
                                      href: _vm.route("persons.edit", {
                                        person: item.id,
                                      }),
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "link-list" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(0, true),
                                  ]
                                )
                              : _c("a", { staticClass: "mb-1 d-flex" }, [
                                  _c("span", { staticClass: "link-list" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ]),
                          ]
                        : [
                            _vm.adminAccess(item)
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "mb-1 d-flex",
                                    attrs: {
                                      href: _vm.route("organizations.edit", {
                                        organization: item.id,
                                      }),
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "link-list" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(1, true),
                                  ]
                                )
                              : _c("a", { staticClass: "mb-1 d-flex" }, [
                                  _c("span", { staticClass: "link-list" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ]),
                          ],
                    ]
                  : [_c("span", [_vm._v(_vm._s(item.name))])],
                _vm._v(" "),
                _vm.personOrgData.length > 2 && index == 1
                  ? _c(
                      "small",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.viewAll($event)
                          },
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            "+" + _vm._s(_vm.personOrgData.length - 2) + " more"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.isViewModalOpen
        ? _c("all-person-org", {
            attrs: {
              "row-data": _vm.personOrgData,
              "component-type": _vm.rowData.persons ? "person" : "organization",
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-muted mt-1" }, [
      _c("i", { attrs: { "data-feather": "external-link" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-muted mt-1" }, [
      _c("i", { attrs: { "data-feather": "external-link" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }