var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("button", { on: { click: _vm.clickMe } }, [_vm._v("click")]),
      _vm._v(" "),
      _vm.load
        ? _c("app-chart", {
            attrs: {
              type: "bar-chart",
              title: "Bar Chart",
              labels: _vm.labels,
              "data-sets": _vm.dataSets,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }