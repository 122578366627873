var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.preLoader
    ? _c("app-overlay-loader")
    : _c("div", { staticClass: "content py-primary" }, [
        _c(
          "form",
          {
            ref: "form",
            attrs: {
              "data-url":
                "admin/auth/users/" + _vm.formData.id + "/password/change",
            },
          },
          [
            _c("div", { staticClass: "form-group mb-primary" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xl-3 d-flex align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                        attrs: { for: "input-text-old-password" },
                      },
                      [_vm._v(_vm._s(_vm.$t("old_password")))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "password",
                        id: "input-text-old-password",
                        placeholder: _vm.$t("old_password"),
                        required: true,
                      },
                      model: {
                        value: _vm.formData.old_password,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "old_password", $$v)
                        },
                        expression: "formData.old_password",
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.old_password
                      ? _c("small", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.old_password[0])),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-primary" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-3 d-flex py-2" }, [
                  _c(
                    "label",
                    {
                      staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                      attrs: { for: "input-text-new-password" },
                    },
                    [_vm._v(_vm._s(_vm.$t("new_password")))]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "password",
                        id: "input-text-new-password",
                        placeholder: _vm.$t("new_password"),
                        required: true,
                      },
                      model: {
                        value: _vm.formData.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "password", $$v)
                        },
                        expression: "formData.password",
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.password
                      ? _c("small", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.password[0])),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "note note-warning p-4 mt-2" }, [
                      _c("p", { staticClass: "m-1" }, [
                        _vm._v(
                          "The password should contain one upper case, one lower case, one special character, and numbers. It should be a minimum of 8 characters."
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-primary" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xl-3 d-flex align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                        attrs: { for: "input-text-confirm-password" },
                      },
                      [_vm._v(_vm._s(_vm.$t("confirm_password")))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "password",
                        id: "input-text-confirm-password",
                        placeholder: _vm.$t("confirm_password"),
                        required: true,
                      },
                      model: {
                        value: _vm.formData.password_confirmation,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "password_confirmation", $$v)
                        },
                        expression: "formData.password_confirmation",
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.password_confirmation
                      ? _c("small", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.password_confirmation[0])),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mt-5" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mr-3",
                      attrs: { type: "button" },
                      on: { click: _vm.submitData },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "w-100" },
                        [
                          _vm.loading
                            ? _c("app-submit-button-loader")
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.loading
                        ? [_vm._v(_vm._s(_vm.$t("save")))]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: { click: _vm.cancelData },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }