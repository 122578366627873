var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper kanban-view" },
    [
      _c("div", { staticClass: "row" }, [
        _vm.isReloadButton
          ? _c("div", { staticClass: "col-sm-12 col-md-12" }, [
              _c(
                "div",
                { staticClass: "text-sm-right mb-primary mb-sm-0 mb-md-0" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-info position-fixed p-0 refresh-btn",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.refreshState()
                        },
                      },
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("span", { staticClass: "btn-text" }, [
                        _vm._v(_vm._s(_vm.$t("refresh"))),
                      ]),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.currentPipeline,
                directory: [
                  _vm.$t("deals"),
                  _vm.$t("pipeline_view"),
                  _vm.currentPipeline,
                ],
                icon: "clipboard",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c(
            "div",
            { staticClass: "text-sm-right mb-primary mb-sm-0 mb-md-0" },
            [
              _c(
                "div",
                { staticClass: "dropdown d-inline-block btn-dropdown" },
                [
                  _vm.$can("create_pipelines") ||
                  _vm.$can("update_pipelines") ||
                  _vm.$can("move_multiple_deals") ||
                  _vm.$can("delete_pipelines") ||
                  _vm.$can("update_deals") ||
                  _vm.$can("import_deal") ||
                  _vm.$can("create_lost_reasons") ||
                  _vm.$can("view_lost_reasons")
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-success dropdown-toggle ml-0 mr-2",
                          attrs: {
                            type: "button",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions")) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown-menu" }, [
                    _vm.$can("create_pipelines")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: {
                              href: _vm.urlGenerator("pipelines/create"),
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "plus-square",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("add_pipeline")) +
                                "\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("update_pipelines")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: {
                              href: _vm.urlGenerator(
                                "pipelines/" + _vm.localPipelineId + "/edit"
                              ),
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "edit",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("edit_pipeline")) +
                                "\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("delete_pipelines")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deletePipeline($event)
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "trash",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("delete_pipeline")) +
                                "\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("import_deal")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: { href: _vm.route("deal.import") },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "download",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("import_deals")) +
                                "\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("move_multiple_deals")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.moveAllDeal($event)
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "arrow-right-circle",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("move_all_deals")) +
                                "\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("create_lost_reasons")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openLostreasonModal($event)
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "plus-square",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("add_lost_reason")) +
                                "\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$can("view_lost_reasons")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center p-3",
                            attrs: {
                              href: _vm.urlGenerator("lost/reasons/list/view"),
                            },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-3",
                              attrs: {
                                "stroke-width": "1",
                                name: "list",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("manage_lost_reasons")) +
                                "\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.$can("create_deals")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-with-shadow",
                      attrs: { type: "button", "data-toggle": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("add_deal")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-9 col-sm-8 col-md-9 col-lg-10 col-xl-10" },
          [
            _c(
              "div",
              {
                staticClass:
                  "\n          filters-wrapper\n          calendar-position-modified\n          d-flex\n          justify-content-start\n          flex-wrap\n        ",
              },
              [
                _vm.filterDataLoaded
                  ? _c("deal-card-column-manager", {
                      attrs: {
                        "manage-option": _vm.cardColumns,
                        "init-option": _vm.initColumns,
                      },
                      on: { "get-columns-options": _vm.getColumnsOptions },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn d-block d-sm-none btn-toggle-filters",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleFilters($event)
                      },
                    },
                  },
                  [
                    _c("app-icon", { attrs: { name: "filter" } }),
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("filters")) + "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFiltersOpen,
                        expression: "isFiltersOpen",
                      },
                    ],
                    staticClass: "mobile-filters-wrapper",
                  },
                  [
                    _vm.filterDataLoaded
                      ? _c("app-filter", {
                          attrs: {
                            "table-id": "kanban-view",
                            filters: _vm.options.filters,
                          },
                          on: { "get-values": _vm.getFilterValues },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dataLoaded
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center ml-2 mb-2",
                            },
                            [
                              _c("p", { staticClass: "text-muted mb-0" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.filterDealCount) +
                                    " " +
                                    _vm._s(_vm.$t("deals", "Deals")) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.visibleClearFilter
                                ? _c(
                                    "p",
                                    { staticClass: "text-muted mb-0 mx-2" },
                                    [_vm._v("-")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.visibleClearFilter
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "text-primary",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.clearAllFilter($event)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("clear_all_filters"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "\n              btn btn-primary btn-with-shadow\n              d-sm-none\n              btn-close-filter-wrapper\n              d-flex\n              justify-content-center\n              align-items-center\n            ",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleFilters },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("close")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-3 col-sm-4 col-md-3 col-lg-2 col-xl-2" },
          [
            _c(
              "div",
              { staticClass: "mr-0 single-filter single-search-wrapper" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "\n            form-group form-group-with-search\n            d-flex\n            align-items-center\n            justify-content-end\n          ",
                  },
                  [_c("app-search", { on: { input: _vm.getSearchValue } })],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.computedHighlights.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "\n      d-flex\n      justify-content-center\n      note note-warning\n      shadow\n      animate__animated animate__fadeIn\n      p-1\n      mb-2\n    ",
            },
            [
              _c("span", { staticClass: "text-mute mr-2" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.computedHighlights.length) +
                    "\n      " +
                    _vm._s(_vm.$t("marked_deals")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              !_vm.markedPipeline.has(_vm.filterValues.pipeline)
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm text-primary p-0",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.MarkedAllDealsOfPipeline(true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      - " +
                          _vm._s(_vm.$t("mark_all_deals_in_this_pipeline")) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.markedPipeline.has(_vm.filterValues.pipeline)
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm text-primary p-0",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.MarkedAllDealsOfPipeline(false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      - " +
                          _vm._s(_vm.$t("unmark_all_deals_in_this_pipeline")) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.computedHighlights.length > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm text-primary p-0 ml-2",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clearMarkedDeals($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      - " +
                          _vm._s(_vm.$t("clear_over_all_marked")) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0" }, [
        _c(
          "div",
          { staticClass: "card-body", staticStyle: { padding: "0.5rem" } },
          [
            _vm.dataLoaded
              ? _c(
                  "div",
                  {
                    staticClass:
                      "kanban-wrapper custom-scrollbar overflow-auto pl-0",
                  },
                  [
                    _vm._l(_vm.stagesProperty, function (stage, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kanban-column position-relative",
                        },
                        [
                          _vm.stagesData[stage.id]
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "py-3 stage-header rounded-top row",
                                  },
                                  [
                                    _c("div", { staticClass: "col-10" }, [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "stage-name text-truncate",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(stage.name) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "\n                    text-muted\n                    d-flex\n                    flex-wrap\n                    align-items-center\n                    stage-information\n                  ",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.numberWithCurrencySymbol(
                                                    !_vm.isUndefined(
                                                      _vm
                                                        .collect(
                                                          _vm.totalDealsValues
                                                        )
                                                        .find(stage.id)
                                                    )
                                                      ? _vm
                                                          .collect(
                                                            _vm.totalDealsValues
                                                          )
                                                          .find(stage.id).value
                                                      : ""
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.stagesData[stage.id].length
                                              ) +
                                                " " +
                                                _vm._s(_vm.$t("deal"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(stage.probability) + "%"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-2 collapse-btn" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "collapse-icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.pipelineCollapse(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("app-icon", {
                                              staticClass: "size-16",
                                              attrs: {
                                                "stroke-width": "1",
                                                name: "chevrons-left",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.filterValues.highlights.length > 0 &&
                                    _vm.stagesData[stage.id].length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "select-icon mt-2 col-12",
                                            class: {
                                              selected: _vm.markedStage.has(
                                                stage.id
                                              ),
                                            },
                                            attrs: {
                                              title: _vm.markedStage.has(
                                                stage.id
                                              )
                                                ? _vm.$t("clear_all")
                                                : _vm.$t("mark_all"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.markAllDeal(
                                                  stage.id,
                                                  !_vm.markedStage.has(stage.id)
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-primary",
                                                attrs: { href: "#" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.markedStage.has(
                                                      stage.id
                                                    )
                                                      ? _vm.$t("clear_all")
                                                      : _vm.$t("mark_all")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "kanban-draggable-column",
                                    attrs: {
                                      options: {
                                        disabled: _vm.$can("update_deals")
                                          ? false
                                          : true,
                                      },
                                      "data-stage": stage.id,
                                      move: _vm.checkMove,
                                      ghostClass: "pipex-sortable-ghost",
                                      chosenClass: "pipex-sortable-chosen",
                                      dragClass: "pipex-sortable-drag",
                                      forceFallback: "true",
                                      animation: "150",
                                      easing: "cubic-bezier(1, 0, 0, 1)",
                                      list: _vm.stagesData[stage.id],
                                      group: "deals",
                                    },
                                    on: {
                                      end: _vm.dragEnd,
                                      change: _vm.posChanged,
                                    },
                                  },
                                  _vm._l(
                                    _vm.stagesData[stage.id],
                                    function (element, ix) {
                                      return _c(
                                        "div",
                                        {
                                          key: ix,
                                          staticClass:
                                            "\n                  card\n                  deal-card\n                  card-with-shadow\n                  border-0\n                  draggable-item\n                ",
                                          class: [
                                            ix ==
                                            _vm.stagesData[stage.id].length - 1
                                              ? ""
                                              : "mb-2",
                                            ix == 0 ? "mt-2" : "",
                                            _vm.computedHighlights.indexOf(
                                              element.id
                                            ) > -1
                                              ? "highlights"
                                              : "",
                                            stage.name ==
                                              _vm.$t("close_deals") ||
                                            !_vm.$can("update_deals")
                                              ? "cursor-default"
                                              : "",
                                          ],
                                          attrs: { "data-id": element.id },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-body font-size-90",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row py-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-10" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "media d-flex align-items-start mb-3",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              class: {
                                                                disabled:
                                                                  _vm.$can(
                                                                    "update_deals"
                                                                  )
                                                                    ? false
                                                                    : true,
                                                              },
                                                              attrs: {
                                                                href: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.selectDeal(
                                                                      element.id,
                                                                      stage.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("app-avatar", {
                                                                staticClass:
                                                                  "mr-2 profile-img",
                                                                attrs: {
                                                                  title:
                                                                    element.contextable
                                                                      ? element
                                                                          .contextable
                                                                          .name
                                                                      : "",
                                                                  img: element.contextable
                                                                    ? element
                                                                        .contextable
                                                                        .profile_picture
                                                                      ? _vm.urlGenerator(
                                                                          element
                                                                            .contextable
                                                                            .profile_picture
                                                                            .path
                                                                        )
                                                                      : element
                                                                          .contextable
                                                                          .profile_picture
                                                                    : "",
                                                                  "avatar-class":
                                                                    "avatars-w-30 " +
                                                                    element.title.substring(
                                                                      0,
                                                                      1
                                                                    ),
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "not_found"
                                                                    ),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.openDealDetailsModal(
                                                                      element
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "media-body",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-muted",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                " +
                                                                          _vm._s(
                                                                            _vm.currentPipeline
                                                                          ) +
                                                                          "\n                            "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "#" +
                                                                        _vm._s(
                                                                          element.id
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "\n                                " +
                                                                          _vm._s(
                                                                            element.title
                                                                          ) +
                                                                          "\n                              "
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-2" },
                                                    [
                                                      _vm.$can(
                                                        "create_activities"
                                                      ) ||
                                                      _vm.$can(
                                                        "update_deals"
                                                      ) ||
                                                      _vm.$can("delete_deals")
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown options-dropdown d-inline-block",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn-option btn",
                                                                  attrs: {
                                                                    type: "button",
                                                                    "data-toggle":
                                                                      "dropdown",
                                                                    title:
                                                                      _vm.$t(
                                                                        "actions"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "app-icon",
                                                                    {
                                                                      attrs: {
                                                                        name: "more-vertical",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-menu dropdown-menu-right py-2 mt-1",
                                                                },
                                                                [
                                                                  _vm.$can(
                                                                    "create_activities"
                                                                  )
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item px-4 py-2 font-size-90",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.openActivityModal(
                                                                                  element
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "add_activity"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.$can(
                                                                    "update_deals"
                                                                  )
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item px-4 py-2 font-size-90",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.openTagModal(
                                                                                  element
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "manage_tag"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  element.status
                                                                    .name ==
                                                                  "status_open"
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item px-4 py-2 font-size-90",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.dealWon(
                                                                                  element
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "won_the_deal"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  element.status
                                                                    .name ==
                                                                  "status_open"
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item px-4 py-2 font-size-90",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.dealLost(
                                                                                  element
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "make_it_lost"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.$can(
                                                                    "update_deals"
                                                                  )
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item px-4 py-2 font-size-90",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.selectDeal(
                                                                                  element.id,
                                                                                  stage.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                " +
                                                                              _vm._s(
                                                                                _vm.computedHighlights.indexOf(
                                                                                  element.id
                                                                                ) >
                                                                                  -1
                                                                                  ? _vm.$t(
                                                                                      "unmark_deal"
                                                                                    )
                                                                                  : _vm.$t(
                                                                                      "mark_deal"
                                                                                    )
                                                                              ) +
                                                                              "\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.$can(
                                                                    "update_deals"
                                                                  )
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item px-4 py-2 font-size-90",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.openDealModal(
                                                                                  element.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "edit"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.$can(
                                                                    "delete_deals"
                                                                  )
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-item px-4 py-2 font-size-90",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.deleteDeal(
                                                                                  element.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "delete"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              element.contextable &&
                                              _vm.cardDataManager.lead
                                                ? _c(
                                                    "div",
                                                    {
                                                      key:
                                                        element.contextable_type ===
                                                        "App\\Models\\CRM\\Organization\\Organization"
                                                          ? "briefcase"
                                                          : "user",
                                                      staticClass:
                                                        "\n                      text-muted\n                      d-flex-inline\n                      justify-content-between\n                      py-2\n                      px-1\n                    ",
                                                    },
                                                    [
                                                      [
                                                        _c("app-icon", {
                                                          staticClass:
                                                            "size-16 mb-1 mr-1",
                                                          attrs: {
                                                            name:
                                                              element.contextable_type ===
                                                              "App\\Models\\CRM\\Organization\\Organization"
                                                                ? "briefcase"
                                                                : "user",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              href: _vm.urlGenerator(
                                                                (element.contextable_type ==
                                                                "App\\Models\\CRM\\Organization\\Organization"
                                                                  ? "organizations"
                                                                  : "persons") +
                                                                  "/" +
                                                                  element
                                                                    .contextable
                                                                    .id +
                                                                  "/edit"
                                                              ),
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              " +
                                                                _vm._s(
                                                                  element
                                                                    .contextable
                                                                    .name
                                                                ) +
                                                                "\n                          "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      _vm._v(" "),
                                                      element.contextable_type ===
                                                      "App\\Models\\CRM\\Organization\\Organization"
                                                        ? [
                                                            element[
                                                              "contact_person"
                                                            ][0]
                                                              ? [
                                                                  _c(
                                                                    "app-icon",
                                                                    {
                                                                      staticClass:
                                                                        "size-16 mb-1",
                                                                      attrs: {
                                                                        name: "user",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href: _vm.urlGenerator(
                                                                          "persons/" +
                                                                            element[
                                                                              "contact_person"
                                                                            ][0][
                                                                              "id"
                                                                            ] +
                                                                            "/edit"
                                                                        ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            element[
                                                                              "contact_person"
                                                                            ][0][
                                                                              "name"
                                                                            ]
                                                                          ) +
                                                                          "\n                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              : [
                                                                  _c(
                                                                    "app-icon",
                                                                    {
                                                                      staticClass:
                                                                        "size-16 mb-1",
                                                                      attrs: {
                                                                        name: "user",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "no_contact"
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ],
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "\n                      text-muted\n                      d-flex-inline\n                      justify-content-between\n                      py-2\n                      px-1\n                    ",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-muted font-size-90 mb-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm
                                                                  .cardDataManager
                                                                  .lead
                                                                  ? _vm.$t(
                                                                      "no_lead_added"
                                                                    )
                                                                  : ""
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                              _vm._v(" "),
                                              element.owner
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media d-flex align-items-start mb-1 p-1",
                                                    },
                                                    [
                                                      _vm.cardDataManager.owner
                                                        ? [
                                                            _c("app-avatar", {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                title:
                                                                  element.owner
                                                                    .full_name,
                                                                "avatar-class":
                                                                  "avatars-w-20",
                                                                img: element
                                                                  .owner
                                                                  .profile_picture
                                                                  ? _vm.urlGenerator(
                                                                      element
                                                                        .owner
                                                                        .profile_picture
                                                                        .path
                                                                    )
                                                                  : element
                                                                      .owner
                                                                      .profile_picture,
                                                                "alt-text":
                                                                  _vm.$t(
                                                                    "not_found"
                                                                  ),
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "media-body",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Owner",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      element
                                                                        .owner
                                                                        .full_name
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-size-90 deal-value",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm
                                                                  .cardDataManager
                                                                  .value
                                                                  ? _vm.numberWithCurrencySymbol(
                                                                      element.value
                                                                    )
                                                                  : ""
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              [
                                                (element.tags.length &&
                                                  _vm.cardDataManager.tags) ||
                                                (element.done_activity_count &&
                                                  _vm.cardDataManager
                                                    .todoActivity) ||
                                                (element.to_do_activity_count &&
                                                  _vm.cardDataManager
                                                    .doneActivity) ||
                                                (element.proposals_count &&
                                                  _vm.cardDataManager
                                                    .proposal) ||
                                                (element.discussions.length &&
                                                  _vm.cardDataManager
                                                    .discussion)
                                                  ? _c("hr", {
                                                      staticClass: "mt-0",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    element.tags.length > 0 &&
                                                    _vm.cardDataManager.tags
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "\n                          col-12\n                          d-flex\n                          tags-background\n                          flex-wrap\n                          justify-content-start\n                        ",
                                                          },
                                                          _vm._l(
                                                            element.tags,
                                                            function (
                                                              tag,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "span",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "\n                            badge badge-sm badge-pill\n                            text-capitalize\n                            mr-1\n                            mb-1\n                            badge-text-truncate\n                          ",
                                                                  style: {
                                                                    "background-color":
                                                                      tag.color_code,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      tag.name
                                                                    ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 d-flex",
                                                      },
                                                      [
                                                        [
                                                          element.to_do_activity_count >
                                                            0 &&
                                                          _vm.cardDataManager
                                                            .todoActivity
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "badge badge-pill deal-activity py-1 mr-2",
                                                                  attrs: {
                                                                    "data-toggle":
                                                                      "tooltip",
                                                                    title:
                                                                      "Total Todo Activity",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "app-icon",
                                                                    {
                                                                      staticClass:
                                                                        "size-16",
                                                                      attrs: {
                                                                        name: "activity",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "badge badge-round ml-2 activity",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  " +
                                                                          _vm._s(
                                                                            element.to_do_activity_count
                                                                          ) +
                                                                          "\n                              "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          element.done_activity_count >
                                                            0 &&
                                                          _vm.cardDataManager
                                                            .doneActivity
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "badge badge-pill deal-activity py-1 mr-2",
                                                                  attrs: {
                                                                    "data-toggle":
                                                                      "tooltip",
                                                                    title:
                                                                      "Total Done Activity",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "app-icon",
                                                                    {
                                                                      staticClass:
                                                                        "size-16",
                                                                      attrs: {
                                                                        name: "activity",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "badge badge-round activity",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  " +
                                                                          _vm._s(
                                                                            element.done_activity_count
                                                                          ) +
                                                                          "\n                              "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        _vm._v(" "),
                                                        element.proposals_count >
                                                          0 &&
                                                        _vm.cardDataManager
                                                          .proposal
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "badge badge-pill deal-activity py-1 mr-2",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Total proposal",
                                                                },
                                                              },
                                                              [
                                                                _c("app-icon", {
                                                                  staticClass:
                                                                    "size-16",
                                                                  attrs: {
                                                                    name: "hexagon",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "badge badge-round ml-2 deal-activity",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                " +
                                                                        _vm._s(
                                                                          element.proposals_count
                                                                        ) +
                                                                        "\n                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        element.discussions
                                                          .length > 0 &&
                                                        _vm.cardDataManager
                                                          .discussion
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "badge badge-pill deal-activity py-1 mr-2",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "comments",
                                                                },
                                                              },
                                                              [
                                                                _c("app-icon", {
                                                                  staticClass:
                                                                    "size-16",
                                                                  attrs: {
                                                                    name: "message-square",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "badge badge-round ml-2 deal-activity",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                " +
                                                                        _vm._s(
                                                                          element
                                                                            .discussions
                                                                            .length
                                                                        ) +
                                                                        "\n                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              _vm._v(" "),
                                              _vm.cardDataManager.createdDate
                                                ? _c("hr", {
                                                    staticClass: "mt-0",
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.cardDataManager.createdDate
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 p-0 mt-1",
                                                    },
                                                    [
                                                      _c(
                                                        "em",
                                                        {
                                                          staticClass:
                                                            "float-right font-size-90",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "created_at"
                                                              )
                                                            ) +
                                                              " :\n                      "
                                                          ),
                                                          _c("b", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.createdInfoShowAsHumanize(
                                                                    element.created_at
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _vm.$can("create_deals")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "draggable-action-wrapper position-absolute w-100",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-stage-action shadow",
                                            attrs: {
                                              slot: "footer",
                                              "data-toggle": "modal",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openModal(stage.id)
                                              },
                                            },
                                            slot: "footer",
                                          },
                                          [
                                            _c("app-icon", {
                                              staticClass: "mr-1",
                                              attrs: { name: "plus" },
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("add")) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-3 mb-2 stage-header rounded-top",
                                  },
                                  [
                                    _c("h6", { staticClass: "text-truncate" }, [
                                      _vm._v(_vm._s(stage.name)),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "kanban-draggable-column",
                                    attrs: {
                                      "data-stage": stage.id,
                                      move: _vm.checkMove,
                                      list: _vm.stagesData[stage.id],
                                      group: "deals",
                                    },
                                    on: {
                                      end: _vm.dragEnd,
                                      change: _vm.posChanged,
                                    },
                                  },
                                  _vm._l(
                                    _vm.stagesData[stage.id],
                                    function (element, ix) {
                                      return _c(
                                        "div",
                                        {
                                          key: ix,
                                          staticClass:
                                            "card card-with-shadow mb-2 border-0 draggable-item",
                                          class: [
                                            _vm.computedHighlights.indexOf(
                                              element.id
                                            ) > -1
                                              ? "highlights"
                                              : "",
                                          ],
                                          attrs: { "data-id": element.id },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.urlGenerator(
                                                  "deal/" +
                                                    element.id +
                                                    "/details"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card-body font-size-90",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media d-flex align-items-start mb-3",
                                                    },
                                                    [
                                                      _c("app-avatar", {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          title: element.title,
                                                          "avatar-class":
                                                            "avatars-w-20 " +
                                                            element.title.substring(
                                                              0,
                                                              1
                                                            ),
                                                          img: "",
                                                          "alt-text":
                                                            _vm.$t("not_found"),
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "media-body",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                element.title
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "\n                        text-muted\n                        d-flex\n                        align-items-center\n                        justify-content-between\n                      ",
                                                    },
                                                    [
                                                      element.owner
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "media d-flex align-items-center",
                                                            },
                                                            [
                                                              _c("app-avatar", {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  title:
                                                                    element
                                                                      .owner
                                                                      .full_name,
                                                                  "avatar-class":
                                                                    "avatars-w-20",
                                                                  img: element
                                                                    .owner
                                                                    .profile_picture
                                                                    ? element
                                                                        .owner
                                                                        .profile_picture
                                                                        .path
                                                                    : element
                                                                        .owner
                                                                        .profile_picture,
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "not_found"
                                                                    ),
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "media-body",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        element
                                                                          .owner
                                                                          .full_name
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-size-90 deal-value",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.numberWithCurrencySymbol(
                                                                element.value
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "kanban-column position-relative won-lost-bg-color",
                      },
                      [
                        _vm.stagesData[0]
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "py-3 stage-header rounded-top row",
                                },
                                [
                                  _c("div", { staticClass: "col-10" }, [
                                    _c(
                                      "h6",
                                      {
                                        staticClass: "stage-name text-truncate",
                                      },
                                      [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(
                                              _vm.$t("won_or_lost_deals")
                                            ) +
                                            "\n                          "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                    text-muted\n                    d-flex\n                    flex-wrap\n                    align-items-center\n                    stage-information\n                  ",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.numberWithCurrencySymbol(
                                                  !_vm.isUndefined(
                                                    _vm
                                                      .collect(
                                                        _vm.totalDealsValues
                                                      )
                                                      .find(0)
                                                  )
                                                    ? _vm
                                                        .collect(
                                                          _vm.totalDealsValues
                                                        )
                                                        .find(0).value
                                                    : ""
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.stagesData[0].length) +
                                              " " +
                                              _vm._s(_vm.$t("deal"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(100) + "%")]),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-2 collapse-btn" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "collapse-icon",
                                          on: {
                                            click: function ($event) {
                                              _vm.pipelineCollapse(
                                                Object.keys(_vm.stagesData)
                                                  .length - 1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("app-icon", {
                                            staticClass: "size-16",
                                            attrs: {
                                              "stroke-width": "1",
                                              name: "chevrons-left",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.filterValues.highlights.length > 0 &&
                                  _vm.stagesData[0].length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "select-icon mt-2 col-12",
                                          class: {
                                            selected: _vm.markedStage.has(0),
                                          },
                                          attrs: {
                                            title: _vm.markedStage.has(0)
                                              ? _vm.$t("clear_all")
                                              : _vm.$t("mark_all"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.markAllDeal(
                                                0,
                                                !_vm.markedStage.has(0)
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-primary",
                                              attrs: { href: "#" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.markedStage.has(0)
                                                    ? _vm.$t("clear_all")
                                                    : _vm.$t("mark_all")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  staticClass: "kanban-draggable-column",
                                  attrs: {
                                    options: { disabled: true },
                                    "data-stage": 0,
                                    move: _vm.checkMove,
                                    ghostClass: "pipex-sortable-ghost",
                                    chosenClass: "pipex-sortable-chosen",
                                    dragClass: "pipex-sortable-drag",
                                    forceFallback: "true",
                                    animation: "150",
                                    easing: "cubic-bezier(1, 0, 0, 1)",
                                    list: _vm.stagesData[0],
                                    group: "deals",
                                  },
                                  on: {
                                    end: _vm.dragEnd,
                                    change: _vm.posChanged,
                                  },
                                },
                                _vm._l(
                                  _vm.stagesData[0],
                                  function (element, ix) {
                                    return _c(
                                      "div",
                                      {
                                        key: ix,
                                        staticClass:
                                          "\n                  card\n                  deal-card\n                  card-with-shadow\n                  border-0\n                  draggable-item\n                ",
                                        class: [
                                          ix == _vm.stagesData[0].length - 1
                                            ? ""
                                            : "mb-2",
                                          ix == 0 ? "mt-2" : "",
                                          _vm.computedHighlights.indexOf(
                                            element.id
                                          ) > -1
                                            ? "highlights"
                                            : "",
                                          "cursor-default",
                                        ],
                                        attrs: { "data-id": element.id },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card-body font-size-90",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row py-2" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-10" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media d-flex align-items-start mb-3",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            class: {
                                                              disabled: true,
                                                            },
                                                            attrs: { href: "" },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.selectDeal(
                                                                  element.id,
                                                                  0
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("app-avatar", {
                                                              staticClass:
                                                                "mr-2 profile-img",
                                                              attrs: {
                                                                title:
                                                                  element.contextable
                                                                    ? element
                                                                        .contextable
                                                                        .name
                                                                    : "",
                                                                img: element.contextable
                                                                  ? element
                                                                      .contextable
                                                                      .profile_picture
                                                                    ? _vm.urlGenerator(
                                                                        element
                                                                          .contextable
                                                                          .profile_picture
                                                                          .path
                                                                      )
                                                                    : element
                                                                        .contextable
                                                                        .profile_picture
                                                                  : "",
                                                                "avatar-class":
                                                                  "avatars-w-30 " +
                                                                  element.title.substring(
                                                                    0,
                                                                    1
                                                                  ),
                                                                "alt-text":
                                                                  _vm.$t(
                                                                    "not_found"
                                                                  ),
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.openDealDetailsModal(
                                                                      element
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "media-body mb-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-muted",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        " +
                                                                          _vm._s(
                                                                            _vm.currentPipeline
                                                                          ) +
                                                                          "\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "\n                                                          #" +
                                                                        _vm._s(
                                                                          element.id
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "\n                                                            " +
                                                                          _vm._s(
                                                                            element.title
                                                                          ) +
                                                                          "\n                                                          "
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              class:
                                                                "badge badge-pill badge-" +
                                                                element.status
                                                                  .class,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                              " +
                                                                  _vm._s(
                                                                    element
                                                                      .status
                                                                      .translated_name
                                                                  ) +
                                                                  "\n                                          "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown options-dropdown d-inline-block",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-option btn",
                                                            attrs: {
                                                              type: "button",
                                                              "data-toggle":
                                                                "dropdown",
                                                              title:
                                                                _vm.$t(
                                                                  "actions"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c("app-icon", {
                                                              attrs: {
                                                                name: "more-vertical",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-menu dropdown-menu-right py-2 mt-1",
                                                          },
                                                          [
                                                            element.status
                                                              .name ==
                                                              "status_won" ||
                                                            element.status
                                                              .name ==
                                                              "status_lost"
                                                              ? _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "dropdown-item px-4 py-2 font-size-90",
                                                                    attrs: {
                                                                      href: "#",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.dealReopen(
                                                                            element
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                  " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "reopen"
                                                                          )
                                                                        ) +
                                                                        "\n                                              "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            element.contextable &&
                                            _vm.cardDataManager.lead
                                              ? _c(
                                                  "div",
                                                  {
                                                    key:
                                                      element.contextable_type ===
                                                      "App\\Models\\CRM\\Organization\\Organization"
                                                        ? "briefcase"
                                                        : "user",
                                                    staticClass:
                                                      "\n                                      text-muted\n                                      d-flex-inline\n                                      justify-content-between\n                                      py-2\n                                      px-1\n                                    ",
                                                  },
                                                  [
                                                    [
                                                      _c("app-icon", {
                                                        staticClass:
                                                          "size-16 mb-1 mr-1",
                                                        attrs: {
                                                          name:
                                                            element.contextable_type ===
                                                            "App\\Models\\CRM\\Organization\\Organization"
                                                              ? "briefcase"
                                                              : "user",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: {
                                                            href: _vm.urlGenerator(
                                                              (element.contextable_type ==
                                                              "App\\Models\\CRM\\Organization\\Organization"
                                                                ? "organizations"
                                                                : "persons") +
                                                                "/" +
                                                                element
                                                                  .contextable
                                                                  .id +
                                                                "/edit"
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                          " +
                                                              _vm._s(
                                                                element
                                                                  .contextable
                                                                  .name
                                                              ) +
                                                              "\n                                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    _vm._v(" "),
                                                    element.contextable_type ===
                                                    "App\\Models\\CRM\\Organization\\Organization"
                                                      ? [
                                                          element[
                                                            "contact_person"
                                                          ][0]
                                                            ? [
                                                                _c("app-icon", {
                                                                  staticClass:
                                                                    "size-16 mb-1",
                                                                  attrs: {
                                                                    name: "user",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href: _vm.urlGenerator(
                                                                        "persons/" +
                                                                          element[
                                                                            "contact_person"
                                                                          ][0][
                                                                            "id"
                                                                          ] +
                                                                          "/edit"
                                                                      ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                              " +
                                                                        _vm._s(
                                                                          element[
                                                                            "contact_person"
                                                                          ][0][
                                                                            "name"
                                                                          ]
                                                                        ) +
                                                                        "\n                                          "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : [
                                                                _c("app-icon", {
                                                                  staticClass:
                                                                    "size-16 mb-1",
                                                                  attrs: {
                                                                    name: "user",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "no_contact"
                                                                      )
                                                                    ) +
                                                                    "\n                                      "
                                                                ),
                                                              ],
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "\n                                  text-muted\n                                  d-flex-inline\n                                  justify-content-between\n                                  py-2\n                                  px-1\n                                ",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-muted font-size-90 mb-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                      " +
                                                            _vm._s(
                                                              _vm
                                                                .cardDataManager
                                                                .lead
                                                                ? _vm.$t(
                                                                    "no_lead_added"
                                                                  )
                                                                : ""
                                                            ) +
                                                            "\n                                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                            _vm._v(" "),
                                            element.owner
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media d-flex align-items-start mb-1 p-1",
                                                  },
                                                  [
                                                    _vm.cardDataManager.owner
                                                      ? [
                                                          _c("app-avatar", {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              title:
                                                                element.owner
                                                                  .full_name,
                                                              "avatar-class":
                                                                "avatars-w-20",
                                                              img: element.owner
                                                                .profile_picture
                                                                ? _vm.urlGenerator(
                                                                    element
                                                                      .owner
                                                                      .profile_picture
                                                                      .path
                                                                  )
                                                                : element.owner
                                                                    .profile_picture,
                                                              "alt-text":
                                                                _vm.$t(
                                                                  "not_found"
                                                                ),
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "media-body",
                                                              attrs: {
                                                                "data-toggle":
                                                                  "tooltip",
                                                                title: "Owner",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                          " +
                                                                  _vm._s(
                                                                    element
                                                                      .owner
                                                                      .full_name
                                                                  ) +
                                                                  "\n                                      "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-size-90 deal-value",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                      " +
                                                            _vm._s(
                                                              _vm
                                                                .cardDataManager
                                                                .value
                                                                ? _vm.numberWithCurrencySymbol(
                                                                    element.value
                                                                  )
                                                                : ""
                                                            ) +
                                                            "\n                                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            [
                                              (element.tags.length &&
                                                _vm.cardDataManager.tags) ||
                                              (element.done_activity_count &&
                                                _vm.cardDataManager
                                                  .todoActivity) ||
                                              (element.to_do_activity_count &&
                                                _vm.cardDataManager
                                                  .doneActivity) ||
                                              (element.proposals_count &&
                                                _vm.cardDataManager.proposal) ||
                                              (element.discussions.length &&
                                                _vm.cardDataManager.discussion)
                                                ? _c("hr", {
                                                    staticClass: "mt-0",
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  element.tags.length > 0 &&
                                                  _vm.cardDataManager.tags
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "\n                                              col-12\n                                              d-flex\n                                              tags-background\n                                              flex-wrap\n                                              justify-content-start\n                                            ",
                                                        },
                                                        _vm._l(
                                                          element.tags,
                                                          function (
                                                            tag,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "span",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "\n                                            badge badge-sm badge-pill\n                                            text-capitalize\n                                            mr-1\n                                            mb-1\n                                            badge-text-truncate\n                                          ",
                                                                style: {
                                                                  "background-color":
                                                                    tag.color_code,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    tag.name
                                                                  ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 d-flex",
                                                    },
                                                    [
                                                      [
                                                        element.to_do_activity_count >
                                                          0 &&
                                                        _vm.cardDataManager
                                                          .todoActivity
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "badge badge-pill deal-activity py-1 mr-2",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Total Todo Activity",
                                                                },
                                                              },
                                                              [
                                                                _c("app-icon", {
                                                                  staticClass:
                                                                    "size-16",
                                                                  attrs: {
                                                                    name: "activity",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "badge badge-round ml-2 activity",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        element.to_do_activity_count
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        element.done_activity_count >
                                                          0 &&
                                                        _vm.cardDataManager
                                                          .doneActivity
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "badge badge-pill deal-activity py-1 mr-2",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Total Done Activity",
                                                                },
                                                              },
                                                              [
                                                                _c("app-icon", {
                                                                  staticClass:
                                                                    "size-16",
                                                                  attrs: {
                                                                    name: "activity",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "badge badge-round activity",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        element.done_activity_count
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      _vm._v(" "),
                                                      element.proposals_count >
                                                        0 &&
                                                      _vm.cardDataManager
                                                        .proposal
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "badge badge-pill deal-activity py-1 mr-2",
                                                              attrs: {
                                                                "data-toggle":
                                                                  "tooltip",
                                                                title:
                                                                  "Total proposal",
                                                              },
                                                            },
                                                            [
                                                              _c("app-icon", {
                                                                staticClass:
                                                                  "size-16",
                                                                attrs: {
                                                                  name: "hexagon",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "badge badge-round ml-2 deal-activity",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      element.proposals_count
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      element.discussions
                                                        .length > 0 &&
                                                      _vm.cardDataManager
                                                        .discussion
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "badge badge-pill deal-activity py-1 mr-2",
                                                              attrs: {
                                                                "data-toggle":
                                                                  "tooltip",
                                                                title:
                                                                  "comments",
                                                              },
                                                            },
                                                            [
                                                              _c("app-icon", {
                                                                staticClass:
                                                                  "size-16",
                                                                attrs: {
                                                                  name: "message-square",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "badge badge-round ml-2 deal-activity",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      element
                                                                        .discussions
                                                                        .length
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ],
                                            _vm._v(" "),
                                            _vm.cardDataManager.createdDate
                                              ? _c("hr", {
                                                  staticClass: "mt-0",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.cardDataManager.createdDate
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-12 p-0 mt-1",
                                                  },
                                                  [
                                                    _c(
                                                      "em",
                                                      {
                                                        staticClass:
                                                          "float-right font-size-90",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("created_at")
                                                          ) +
                                                            " :\n                                      "
                                                        ),
                                                        _c("b", [
                                                          _vm._v(
                                                            "\n                                          " +
                                                              _vm._s(
                                                                _vm.createdInfoShowAsHumanize(
                                                                  element.created_at
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          : [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-3 mb-2 stage-header rounded-top",
                                },
                                [
                                  _c("h6", { staticClass: "text-truncate" }, [
                                    _vm._v(_vm._s(_vm.$t("close_deals"))),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  staticClass: "kanban-draggable-column",
                                  attrs: {
                                    "data-stage": 0,
                                    move: _vm.checkMove,
                                    list: _vm.stagesData[0],
                                    group: "deals",
                                  },
                                  on: {
                                    end: _vm.dragEnd,
                                    change: _vm.posChanged,
                                  },
                                },
                                _vm._l(
                                  _vm.stagesData[0],
                                  function (element, ix) {
                                    return _c(
                                      "div",
                                      {
                                        key: ix,
                                        staticClass:
                                          "card card-with-shadow mb-2 border-0 draggable-item",
                                        class: [
                                          _vm.computedHighlights.indexOf(
                                            element.id
                                          ) > -1
                                            ? "highlights"
                                            : "",
                                        ],
                                        attrs: { "data-id": element.id },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.urlGenerator(
                                                "deal/" +
                                                  element.id +
                                                  "/details"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card-body font-size-90",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media d-flex align-items-start mb-3",
                                                  },
                                                  [
                                                    _c("app-avatar", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        title: element.title,
                                                        "avatar-class":
                                                          "avatars-w-20 " +
                                                          element.title.substring(
                                                            0,
                                                            1
                                                          ),
                                                        img: "",
                                                        "alt-text":
                                                          _vm.$t("not_found"),
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                          " +
                                                            _vm._s(
                                                              element.title
                                                            ) +
                                                            "\n                                      "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "\n                        text-muted\n                        d-flex\n                        align-items-center\n                        justify-content-between\n                      ",
                                                  },
                                                  [
                                                    element.owner
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "media d-flex align-items-center",
                                                          },
                                                          [
                                                            _c("app-avatar", {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                title:
                                                                  element.owner
                                                                    .full_name,
                                                                "avatar-class":
                                                                  "avatars-w-20",
                                                                img: element
                                                                  .owner
                                                                  .profile_picture
                                                                  ? element
                                                                      .owner
                                                                      .profile_picture
                                                                      .path
                                                                  : element
                                                                      .owner
                                                                      .profile_picture,
                                                                "alt-text":
                                                                  _vm.$t(
                                                                    "not_found"
                                                                  ),
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "media-body",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                              " +
                                                                    _vm._s(
                                                                      element
                                                                        .owner
                                                                        .full_name
                                                                    ) +
                                                                    "\n                                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-size-90 deal-value",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.numberWithCurrencySymbol(
                                                              element.value
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  2
                )
              : _c(
                  "div",
                  { staticClass: "card border-0 min-height-400" },
                  [_c("app-overlay-loader")],
                  1
                ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.isActivityModalActive
        ? _c("app-activity-modal", {
            tag: "component",
            attrs: {
              "table-id": _vm.tableId,
              previousData: true,
              selectData: _vm.activityData,
            },
            on: {
              "close-modal": function ($event) {
                return _vm.closeActivityModal()
              },
              save: function ($event) {
                return _vm.refreshState()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isDealModalActive
        ? _c("app-deal-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "selected-url": _vm.dealUrl,
              "selected-stage-index": _vm.selectedStageId,
              "pipeline-id": _vm.localPipelineId,
            },
            on: {
              "close-modal": function ($event) {
                return _vm.closeModal()
              },
              saved: function ($event) {
                return _vm.refreshState()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isDeletePipelineModal
        ? _c("pipeline-delete-modal", {
            attrs: {
              modalId: "pipeline-delete-modal",
              pipelineId: _vm.localPipelineId,
              deletePipelineUrl: "pipelines/" + _vm.localPipelineId,
            },
            on: { "close-modal": _vm.closePipelineDeleteModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.dealDeleteModal
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "deal-delete-modal" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isModalActive
        ? _c("app-lost-reason-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "selected-url": _vm.selectedUrlReason,
            },
            on: { "close-modal": _vm.closeLostReasonModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isReopenModalActive
        ? _c("app-confirmation-modal", {
            attrs: {
              "modal-id": "deal-reopen-modal",
              message: _vm.$t("are_you_sure_want_to_reopen_this_deal"),
            },
            on: {
              confirmed: _vm.confirmedReopen,
              cancelled: _vm.cancelledReopenModal,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isMoveDealModalActive
        ? _c("app-move-all-deal-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "pipeline-name": _vm.currentPipeline,
              pipelines: _vm.pipelines,
              stages: _vm.stages,
              deals: _vm.stagesData,
              "pipeline-id": _vm.localPipelineId,
            },
            on: {
              "close-modal": _vm.closeMoveAllDeal,
              refresh: _vm.refreshState,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isTagModalActive
        ? _c("deal-tag-manage-modal", {
            attrs: {
              "taggable-id": _vm.taggableId,
              "tag-list": _vm.tagList,
              "post-url": "deal/tags/",
            },
            on: {
              "close-modal": _vm.closeTagModal,
              "changed-tag-list": _vm.refreshTagList,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.filterValues.highlights.length
        ? _c("app-bulk-action", {
            attrs: {
              "highlight-ids": _vm.filterValues.highlights,
              pipelines: _vm.pipelines,
              "pipeline-id": _vm.localPipelineId,
              "selected-deal": _vm.computedHighlights,
            },
            on: { clear: _vm.clearMarkedDeals, refresh: _vm.refreshState },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isDetailsViewActive
        ? _c("deal-details-modal", {
            attrs: {
              "selected-deal": _vm.selectedDeal,
              pipelines: _vm.pipelines,
              stages: _vm.stagesProperty,
            },
            on: {
              "deal-update": function ($event) {
                return _vm.dealUpdated(_vm.filterValues)
              },
              "close-modal": _vm.closeDetailsViewModal,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isWonModal
        ? _c("app-deal-won-confirm-modal", {
            attrs: {
              "modal-id": "won-deal-confirm-modal",
              dealWonData: _vm.dealWonData,
            },
            on: {
              success: _vm.afterUpdated,
              "won-close-modal": _vm.wonCloseModal,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isDealLostConfirmModal
        ? _c("app-deal-lost-confirm-modal", {
            attrs: {
              "modal-id": "lost-deal-confirm-modal",
              dealLostReasonData: _vm.dealLostData,
            },
            on: {
              success: _vm.afterUpdated,
              "close-modal": _vm.closeDealLostModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fas fa-sync" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }