var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.$t("templates"),
                directory: [_vm.$t("proposals"), _vm.$t("templates")],
                icon: "hexagon",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.$can("create_templates")
          ? _c("div", { staticClass: "col-sm-12 col-md-6" }, [
              _c(
                "div",
                { staticClass: "float-md-right mb-3 mb-sm-3 mb-md-0" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-with-shadow",
                      attrs: { type: "button", "data-toggle": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.addTemplate()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("add_template")) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("app-card-view", {
        attrs: { id: "template-card", properties: _vm.properties },
        on: { action: _vm.getActions },
      }),
      _vm._v(" "),
      _vm.isPreviewModalActive
        ? _c("app-template-preview-modal", {
            attrs: {
              content: _vm.selectedTemplateContent,
              "template-subject": _vm.templateSubject,
              modalId: "template-preview",
            },
            on: {
              close: function ($event) {
                _vm.isPreviewModalActive = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDeleteModal
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "delete-example" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }