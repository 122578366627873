var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-button", {
    attrs: {
      label: _vm.label,
      "class-name": _vm.className,
      "is-disabled": _vm.isDisabled,
    },
    on: { submit: _vm.submit },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }