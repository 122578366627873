var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: { "page-title": "Page Title", icon: "settings" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mb-primary" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.testCol($event)
              },
            },
          },
          [_vm._v("Changed")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.testColRev($event)
              },
            },
          },
          [_vm._v("Changed reverse")]
        ),
      ]),
      _vm._v(" "),
      _c("app-table", {
        attrs: { id: "test-table-core", options: _vm.options },
        on: {
          action: _vm.getAction,
          getRows: _vm.getSelectedRows,
          getFilteredValues: _vm.filteredValues,
        },
      }),
      _vm._v(" "),
      _vm.isShowTestModal
        ? _c("test-modal", { on: { "close-modal": _vm.closeModal } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowDeleteModal
        ? _c("app-confirmation-modal", {
            attrs: {
              icon: "check-circle",
              title: "Hand's Up",
              "sub-title": "This content will be deleted permanently",
              message: "Are you sure?",
              "modal-class": "success",
              "modal-id": "delete-modal",
            },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }