var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-primary" }, [
    _c(
      "div",
      { staticClass: "card deal-details card-with-shadow py-primary border-0" },
      [
        _vm.dataLoaded
          ? [
              _c("div", { staticClass: "row mb-primary" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "border-right first-border custom px-primary h-100 media pr-xl-5 pl-xl-0 user-header-media",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "media d-flex align-items-center mb-3",
                          },
                          [
                            [
                              _c("app-avatar", {
                                staticClass: "mr-2",
                                attrs: {
                                  title: _vm.dealPerson.contextable
                                    ? _vm.dealPerson.contextable.name
                                    : "",
                                  "avatar-class": "avatars-w-90",
                                  img: _vm.dealPerson.contextable
                                    ? _vm.dealPerson.contextable.profile_picture
                                      ? _vm.urlGenerator(
                                          _vm.dealPerson.contextable
                                            .profile_picture.path
                                        )
                                      : _vm.urlGenerator(
                                          "/images/" + _vm.leadType + ".png"
                                        )
                                    : "",
                                },
                              }),
                            ],
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "media-body ml-3" },
                              [
                                _vm.dealPerson.contextable
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.dealPerson.contextable.name
                                          ) +
                                          "\n                  "
                                      ),
                                    ])
                                  : _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-muted font-size-90 mb-2",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("no_lead_added")) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                _vm._v(" "),
                                _vm.dealPerson.lead_type == 2
                                  ? [
                                      _vm._l(
                                        _vm.dealPerson.contact_person,
                                        function (contactPerson) {
                                          return _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-muted font-size-90 mb-2",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(contactPerson.name) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      !_vm.dealPerson.contact_person.length
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-muted font-size-90 mb-2",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "no_contact_person_added"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _c("p", {
                                        staticClass:
                                          "text-muted font-size-90 mb-2",
                                      }),
                                    ],
                                _vm._v(" "),
                                _vm.dealStatus
                                  ? [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-success mr-1",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.dealWon(
                                                _vm.dealPerson.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.$t("won")) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm btn-danger",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.dealLostModal(
                                                _vm.dealPerson
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.$t("lost")) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm btn-primary",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.reopenDealModal(
                                                _vm.dealPerson.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.$t("reopen")) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "px-primary px-sm-5 px-md-5 px-lg-0 px-xl-0 mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 user-details",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-xl-0 mb-lg-4 mb-md-4 mb-sm-4",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-right second-border custom",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "media mb-primary" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "align-self-center mr-3",
                                        },
                                        [
                                          _c("app-icon", {
                                            staticClass: "primary-text-color",
                                            attrs: {
                                              "stroke-width": "1",
                                              name: "dollar-sign",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "media-body font-size-90",
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-muted mb-0" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("value")) + ":"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.numberWithCurrencySymbol(
                                                    _vm.dealPerson.value
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "media" }, [
                                    _c(
                                      "div",
                                      { staticClass: "align-self-center mr-3" },
                                      [
                                        _c("app-icon", {
                                          staticClass: "primary-text-color",
                                          attrs: {
                                            "stroke-width": "1",
                                            name: "disc",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "media-body font-size-90",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-muted mb-0" },
                                          [_vm._v(_vm._s(_vm.$t("age")) + ":")]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(_vm._s(_vm.dealAge)),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6",
                            },
                            [
                              _c("div", { staticClass: "media mb-primary" }, [
                                _c(
                                  "div",
                                  { staticClass: "align-self-center mr-3" },
                                  [
                                    _c("app-icon", {
                                      staticClass: "primary-text-color",
                                      attrs: {
                                        "stroke-width": "1",
                                        name: "user",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "media-body font-size-90" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "text-muted mb-0" },
                                      [_vm._v(_vm._s(_vm.$t("owner")) + ":")]
                                    ),
                                    _vm._v(" "),
                                    _vm.dealPerson.owner
                                      ? _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.dealPerson.owner.full_name
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "media mb-0" }, [
                                _c(
                                  "div",
                                  { staticClass: "align-self-center mr-3" },
                                  [
                                    _c("app-icon", {
                                      staticClass: "primary-text-color",
                                      attrs: {
                                        "stroke-width": "1",
                                        name: "calendar",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "media-body font-size-90" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "text-muted mb-0" },
                                      [_vm._v(_vm._s(_vm.$t("created")) + ":")]
                                    ),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.formatDateToLocal(
                                              _vm.dealPerson.created_at
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "px-primary" },
                [
                  _c("app-step-input-selector", {
                    staticClass: "mb-3",
                    attrs: {
                      "step-lists": _vm.stageListAsPipelineId,
                      "step-complete": _vm.stageIndexAsId,
                      "show-label": true,
                      disabled: !_vm.dealStatus,
                    },
                    on: { stepChanges: _vm.updateDealFromStepInput },
                    model: {
                      value: _vm.formData.stage_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "stage_id", $$v)
                      },
                      expression: "formData.stage_id",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-wrap align-items-center justify-content-between",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-size-90 text-muted d-flex align-items-center",
                        },
                        [
                          _vm.dealPerson.pipeline
                            ? [
                                _c("app-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    "stroke-width": "1",
                                    width: "16",
                                    height: "16",
                                    name: "trello",
                                  },
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.dealPerson.pipeline.name) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.dealPerson.stage
                            ? [
                                _c("app-icon", {
                                  attrs: {
                                    "stroke-width": "1",
                                    width: "12",
                                    height: "12",
                                    name: "chevron-right",
                                  },
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.stageListAsPipelineId[
                                        _vm.stageIndexAsId
                                      ].name
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-size-90 text-muted d-flex align-items-center",
                          attrs: {
                            "data-toggle": "tooltip",
                            title: "Expecting closing date",
                          },
                        },
                        [
                          _c("app-icon", {
                            staticClass: "mr-2",
                            attrs: {
                              "stroke-width": "1",
                              width: "16",
                              height: "16",
                              name: "calendar",
                            },
                          }),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.dealPerson.expired_at == null
                                  ? _vm.$t("not_added_yet")
                                  : _vm.formatDateToLocal(
                                      _vm.dealPerson.expired_at
                                    )
                              ) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isModalActive
                ? _c("app-confirmation-modal", {
                    attrs: {
                      "modal-id": "deal-reopen-modal",
                      message: _vm.$t("are_you_sure_want_to_reopen_this_deal"),
                    },
                    on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isDealOwnConfirmModal
                ? _c("app-deal-lost-confirm-modal", {
                    attrs: {
                      "modal-id": "lost-deal-confirm-modal",
                      dealLostReasonData: _vm.dealLost,
                    },
                    on: { "close-modal": _vm.closeModal },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isWonModal
                ? _c("app-deal-won-confirm-modal", {
                    attrs: {
                      "modal-id": "won-deal-confirm-modal",
                      dealWonData: _vm.dealPerson,
                    },
                    on: { "won-close-modal": _vm.wonCloseModal },
                  })
                : _vm._e(),
            ]
          : _c("app-overlay-loader"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }