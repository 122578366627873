var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.preloader
    ? _c("app-overlay-loader")
    : _c(
        "form",
        {
          ref: "form",
          staticClass: "mb-0",
          class: { "loading-opacity": _vm.preloader },
          attrs: { "data-url": _vm.route("broadcast.setting-update") },
        },
        [
          _c("div", { staticClass: "form-group row align-items-center" }, [
            _c(
              "label",
              {
                staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                attrs: { for: "provider" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("broadcast_driver")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-8 col-xl-8" },
              [
                _c("app-input", {
                  attrs: {
                    id: "provider",
                    type: "select",
                    list: _vm.providerList,
                    "error-message": _vm.$errorMessage(
                      _vm.errors,
                      "broadcast_driver"
                    ),
                  },
                  model: {
                    value: _vm.formData.broadcast_driver,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "broadcast_driver", $$v)
                    },
                    expression: "formData.broadcast_driver",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.formData.broadcast_driver === "pusher"
            ? [
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c("label", { staticClass: "col-lg-3 col-xl-3 mb-lg-0" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pusher_app_id")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("pusher_app_id"),
                            "error-message": _vm.$errorMessage(
                              _vm.errors,
                              "pusher_app_id"
                            ),
                          },
                          model: {
                            value: _vm.formData.pusher_app_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pusher_app_id", $$v)
                            },
                            expression: "formData.pusher_app_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c("label", { staticClass: "col-lg-3 col-xl-3 mb-lg-0" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pusher_app_key")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("pusher_app_key"),
                            "error-message": _vm.$errorMessage(
                              _vm.errors,
                              "pusher_app_key"
                            ),
                          },
                          model: {
                            value: _vm.formData.pusher_app_key,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pusher_app_key", $$v)
                            },
                            expression: "formData.pusher_app_key",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c("label", { staticClass: "col-lg-3 col-xl-3 mb-lg-0" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pusher_app_secret")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("pusher_app_secret"),
                            "error-message": _vm.$errorMessage(
                              _vm.errors,
                              "pusher_app_secret"
                            ),
                          },
                          model: {
                            value: _vm.formData.pusher_app_secret,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pusher_app_secret", $$v)
                            },
                            expression: "formData.pusher_app_secret",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c("label", { staticClass: "col-lg-3 col-xl-3 mb-lg-0" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pusher_app_cluster")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("pusher_app_cluster"),
                            "error-message": _vm.$errorMessage(
                              _vm.errors,
                              "pusher_app_cluster"
                            ),
                          },
                          model: {
                            value: _vm.formData.pusher_app_cluster,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pusher_app_cluster", $$v)
                            },
                            expression: "formData.pusher_app_cluster",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitData($event)
                  },
                },
              },
              [
                _vm.loading
                  ? _c(
                      "span",
                      { staticClass: "w-100" },
                      [_c("app-submit-button-loader")],
                      1
                    )
                  : [_vm._v(_vm._s(_vm.$t("save")))],
              ],
              2
            ),
          ]),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }